import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Button,
  CircularProgress,
  Box,
} from "@mui/material";
import customTheme from "../theme/customTheme";

interface ForgotPasswordDialogProps {
  open: boolean;
  onClose: () => void;
  onSend: (email: string) => Promise<void>;
}

export const ForgotPasswordDialog: React.FC<ForgotPasswordDialogProps> = ({
  open,
  onClose,
  onSend,
}) => {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [sent, setSent] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!open) {
      setEmail("");
      setEmailError("");
      setSent(false);
      setLoading(false);
    }
  }, [open]);

  const validateEmail = (email: string) =>
    /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(email.toLowerCase());

  const handleSend = async (event?: React.FormEvent) => {
    if (event) {
      event.preventDefault();
    }
    if (!validateEmail(email)) {
      setEmailError("Enter a valid email address.");
      return;
    }

    setEmailError("");
    setLoading(true);
    try {
      await onSend(email);
      setSent(true);
    } catch (error) {
      console.error(error);
      setEmailError("Please enter a registered email address.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="xs"
      fullWidth
      PaperProps={{
        style: {
          padding: "15px",
          borderRadius: "12px",
          boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
        },
      }}
    >
      <DialogTitle
        style={{
          textAlign: "center",
          fontWeight: "bold",
          fontSize: "20px",
          marginBottom: "8px",
        }}
      >
        Forgot Password
      </DialogTitle>
      <form onSubmit={handleSend}>
        <DialogContent style={{ textAlign: "center" }}>
          {sent ? (
            <DialogContentText style={{ fontSize: "14px", marginBottom: "8px" }}>
              A password reset link has been sent to your email. Please check your email and click
              on the link to reset your password.
            </DialogContentText>
          ) : (
            <>
              <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                mb: 1
              }}
              >
              <img
                src={require("../../Images/forgotpassword.png")}
                alt="Forgot Password"
                style={{
                width: "80%",
                marginBottom: "8px",
                }}
              />
              </Box>
              <TextField
              autoFocus
              margin="dense"
              id="forgotPasswordEmail"
              placeholder="Enter your email"
              type="email"
              fullWidth
              variant="outlined"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              error={!!emailError}
              helperText={emailError}
              sx={{
                "& .MuiInputBase-input:-webkit-autofill": {
                transition: "background-color 5000s ease-in-out 0s",
                },
              }}
              InputLabelProps={{
                style: { fontSize: "14px", color: "#555" },
              }}
              InputProps={{
                style: { borderRadius: "8px" },
              }}
              />
              <DialogContentText
              style={{ fontSize: "12px", marginTop: "8px", color: "#777" }}
              >
              Enter your registered email address to receive a password reset link.
              </DialogContentText>
            </>
          )}
        </DialogContent>
        <DialogActions style={{ justifyContent: "center", marginTop: "8px" }}>
          {sent ? (
            <Button
              onClick={onClose}
              style={{
                color: "#fff",
                backgroundColor: customTheme.palette.primary.main,
                borderRadius: "8px",
                padding: "8px 16px",
                textTransform: "none",
                fontWeight: "bold",
                fontSize: "14px",
              }}
            >
              Close
            </Button>
          ) : (
            <>
              <Button
                onClick={onClose}
                style={{
                  color: "#555",
                  backgroundColor: "#e0e0e0",
                  borderRadius: "8px",
                  padding: "8px 16px",
                  textTransform: "none",
                  fontSize: "14px",
                }}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                style={{
                  color: "#fff",
                  backgroundColor: customTheme.palette.primary.main,
                  borderRadius: "8px",
                  padding: "8px 16px",
                  textTransform: "none",
                  fontWeight: "bold",
                  fontSize: "14px",
                }}
                disabled={loading}
              >
                {loading ? <CircularProgress size={24} color="inherit" /> : "Send Link"}
              </Button>
            </>
          )}
        </DialogActions>
      </form>
    </Dialog>
  );
};