export const monthlySalesData = [
  { month: 'Jan', sales: 3000 },
  { month: 'Feb', sales: 2000 },
  { month: 'Mar', sales: 4000 },
  { month: 'Apr', sales: 2500 },
  { month: 'May', sales: 3500 },
  { month: 'June', sales: 4500 },
  { month: 'July', sales: 3000 },
  { month: 'Aug', sales: 2000 },
  { month: 'Sept', sales: 4000 },
  { month: 'Oct', sales: 2500 },
  { month: 'Nov', sales: 3500 },
  { month: 'Dec', sales: 4500 },
];

export const yearlySalesData = [
  { year: '2018', sales: 35000 },
  { year: '2019', sales: 40000 },
  { year: '2020', sales: 45000 },
  { year: '2021', sales: 30000 },
  { year: '2022', sales: 50000 },
  { year: '2023', sales: 55000 },
];

export const monthlyPromotionData = [
  { month: 'Jan', promotions: 15 },
  { month: 'Feb', promotions: 10 },
  { month: 'Mar', promotions: 20 },
  { month: 'Apr', promotions: 12 },
  { month: 'May', promotions: 18 },
  { month: 'June', promotions: 25 },
  { month: 'July', promotions: 16 },
  { month: 'Aug', promotions: 11 },
  { month: 'Sept', promotions: 22 },
  { month: 'Oct', promotions: 14 },
  { month: 'Nov', promotions: 19 },
  { month: 'Dec', promotions: 23 },
];

export const yearlyPromotionData = [
  { year: '2018', promotions: 180 },
  { year: '2019', promotions: 210 },
  { year: '2020', promotions: 250 },
  { year: '2021', promotions: 190 },
  { year: '2022', promotions: 230 },
  { year: '2023', promotions: 260 },
];

export const itemsSoldData = [
  { item: 'Item A', quantity: 120 },
  { item: 'Item B', quantity: 98 },
  { item: 'Item C', quantity: 86 },
  { item: 'Item D', quantity: 99 },
  { item: 'Item E', quantity: 85 },
  { item: 'Item F', quantity: 65 },
  { item: 'Item G', quantity: 130 },
  { item: 'Item H', quantity: 105 },
];

export const categoriesSoldData = [
  { category: 'Cat A', quantity: 150 },
  { category: 'Cat B', quantity: 130 },
  { category: 'Cat C', quantity: 110 },
  { category: 'Cat D', quantity: 100 },
  { category: 'Cat E', quantity: 90 },
];

export const impressionsData = [
  { item: 'Item A', impressions: 1500 },
  { item: 'Item B', impressions: 1200 },
  { item: 'Item C', impressions: 900 },
  { item: 'Item D', impressions: 1100 },
  { item: 'Item E', impressions: 950 },
];

export const costPerClickData = [
  { campaign: 'Campaign A', cost: 2.5 },
  { campaign: 'Campaign B', cost: 3.0 },
  { campaign: 'Campaign C', cost: 1.8 },
  { campaign: 'Campaign D', cost: 2.0 },
  { campaign: 'Campaign E', cost: 3.5 },
];

export const newUsersData = [
  { month: 'Jan', quantity: 2500 },
  { month: 'Feb', quantity: 3200 },
  { month: 'Mar', quantity: 4100 },
  { month: 'Apr', quantity: 3800 },
  { month: 'May', quantity: 4500 },
  { month: 'Jun', quantity: 3900 },
  { month: 'Jul', quantity: 3300 },
];

export const permanentUsersData = [
  { month: 'Jan', quantity: 1800 },
  { month: 'Feb', quantity: 2200 },
  { month: 'Mar', quantity: 2800 },
  { month: 'Apr', quantity: 3100 },
  { month: 'May', quantity: 3500 },
  { month: 'Jun', quantity: 3200 },
];