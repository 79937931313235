import React from 'react';
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Container,
} from '@mui/material';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

export const PrivacyPolicy = () => {
  return (
    <Container maxWidth="md">
      <Box
        sx={{
          position: 'absolute',
          top: '50px',
          left: '50%',
          transform: 'translateX(-50%)',
          marginTop: '50px',
          backgroundColor: '#FCAA4F',
          width: '180px',
          height: '40px',
          borderRadius: 30,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Typography
          sx={{ fontWeight: 'bold', fontSize: '16px', color: 'white' }}
        >
          Privacy Policy
        </Typography>
      </Box>
      <Box sx={{ padding: '2rem 0', marginTop: '100px' }}>
        <Typography variant="h3" align="center" gutterBottom>
          We are committed to protect your privacy
        </Typography>
        <Typography
          variant="body1"
          align="center"
          color="textSecondary"
          gutterBottom
        >
          Last updated: Sept 01, 2024
        </Typography>
        <Box mb={4}>
          <Typography variant="body1">
            Welcome to ShopPayGo! We value your privacy and are committed to
            protecting your personal information. This Privacy Policy outlines
            how we collect, use, disclose, and safeguard your information when
            you visit our website [www.shoppaygo.in] ("Site") and use our
            services.
          </Typography>
        </Box>

        <Box mb={4}>
          <Typography variant="body1">
            By accessing or using our Site and services, you agree to this
            Privacy Policy. If you do not agree with the terms, please do not
            use our Site.
          </Typography>
        </Box>

        <Box mb={4}>
          <Typography variant="h5" gutterBottom>
            1. Information We Collect
          </Typography>
          <Typography variant="body1">
            We collect information about you in various ways when you use our
            Site:
          </Typography>
          <List>
            <ListItem>
              <Typography variant="h6" gutterBottom>
                1.1. Information You Provide to Us:
                <ListItem>
                  <ListItemText primary="Personal Information: When you sign up for an account, make a purchase, or contact us, we may collect personal information, such as your name, email address, phone number, billing information, and shipping address." />
                </ListItem>
                <ListItem>
                  <ListItemText primary="Communication Data: We collect data from any communication you send to us, whether through email, our contact form, or any other method." />
                </ListItem>
              </Typography>
            </ListItem>
            <ListItem>
              <Typography variant="h6" gutterBottom>
                1.2. Information We Collect Automatically:
                <ListItem>
                  <ListItemText primary="Cookies and Tracking Technologies: We use cookies, web beacons, and similar tracking technologies to collect data about your browsing activity on our Site. This may include your IP address, browser type, operating system, pages viewed, and time spent on the Site." />
                </ListItem>
                <ListItem>
                  <ListItemText primary="Usage Data: We may collect data about your interactions with our services, such as the features you use, the time you spend using them, and other usage statistics." />
                </ListItem>
              </Typography>
            </ListItem>
            <ListItem>
              <Typography variant="h6" gutterBottom>
                1.3. Information from Third-Party:
                <ListItem>
                  <ListItemText primary="We may receive information about you from third-party services or partners, such as advertising networks, analytics providers, or payment processors." />
                </ListItem>
              </Typography>
            </ListItem>
          </List>
        </Box>

        <Box mb={4}>
          <Typography variant="h5" gutterBottom>
            2. How We Use Your Information
          </Typography>
          <Typography variant="body1">
            We use the information we collect for various purposes, including
            to:
            <List>
              <ListItem>
                <ListItemIcon>
                  <FiberManualRecordIcon
                    sx={{ color: 'black' }}
                    fontSize="inherit"
                  />
                </ListItemIcon>
                <Typography variant="body1" gutterBottom>
                  Provide and Manage Services: To create and manage your
                  account, process transactions, and deliver products or
                  services you request.
                </Typography>
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <FiberManualRecordIcon
                    sx={{ color: 'black' }}
                    fontSize="inherit"
                  />
                </ListItemIcon>
                <Typography variant="body1" gutterBottom>
                  Improve Our Site: To understand how users interact with our
                  Site, personalize your experience, and improve our offerings.
                </Typography>
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <FiberManualRecordIcon
                    sx={{ color: 'black' }}
                    fontSize="inherit"
                  />
                </ListItemIcon>
                <Typography variant="body1" gutterBottom>
                  Communicate with You: To send you updates, newsletters,
                  marketing communications, and other information that may be of
                  interest to you.
                </Typography>
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <FiberManualRecordIcon
                    sx={{ color: 'black' }}
                    fontSize="inherit"
                  />
                </ListItemIcon>
                <Typography variant="body1" gutterBottom>
                  Process Payments: To handle transactions and billing
                  information securely.
                </Typography>
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <FiberManualRecordIcon
                    sx={{ color: 'black' }}
                    fontSize="inherit"
                  />
                </ListItemIcon>
                <Typography variant="body1" gutterBottom>
                  Comply with Legal Obligations: To comply with legal
                  requirements, prevent fraud, and ensure the security of our
                  Site and services.
                </Typography>
              </ListItem>
            </List>
          </Typography>
        </Box>

        <Box mb={4}>
          <Typography variant="h5" gutterBottom>
            3. Sharing Your Information
          </Typography>
          <Typography variant="body1">
            We may share your information in the following circumstances:
            <List>
              <ListItem>
                <ListItemIcon>
                  <FiberManualRecordIcon
                    sx={{ color: 'black' }}
                    fontSize="inherit"
                  />
                </ListItemIcon>
                <Typography variant="body1" gutterBottom>
                  With Service Providers: We may share your information with
                  third-party service providers who perform services on our
                  behalf, such as payment processing, data analysis, and
                  marketing.
                </Typography>
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <FiberManualRecordIcon
                    sx={{ color: 'black' }}
                    fontSize="inherit"
                  />
                </ListItemIcon>
                <Typography variant="body1" gutterBottom>
                  With Business Partners: We may share your information with
                  business partners who offer products or services that may be
                  of interest to you.
                </Typography>
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <FiberManualRecordIcon
                    sx={{ color: 'black' }}
                    fontSize="inherit"
                  />
                </ListItemIcon>
                <Typography variant="body1" gutterBottom>
                  For Legal Reasons: We may disclose your information to comply
                  with legal obligations, protect our rights, and defend against
                  legal claims.
                </Typography>
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <FiberManualRecordIcon
                    sx={{ color: 'black' }}
                    fontSize="inherit"
                  />
                </ListItemIcon>
                <Typography variant="body1" gutterBottom>
                  In Business Transfers: If we undergo a merger, acquisition, or
                  sale of assets, your information may be transferred as part of
                  that transaction.
                </Typography>
              </ListItem>
            </List>
          </Typography>
        </Box>

        <Box mb={4}>
          <Typography variant="h5" gutterBottom>
            4. Your Rights and Choices
          </Typography>
          <Typography variant="body1">
            You have certain rights regarding your personal information,
            including:
            <List>
              <ListItem>
                <ListItemIcon>
                  <FiberManualRecordIcon
                    sx={{ color: 'black' }}
                    fontSize="inherit"
                  />
                </ListItemIcon>
                <Typography variant="body1" gutterBottom>
                  Access: You can request access to the personal information we
                  hold about you.
                </Typography>
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <FiberManualRecordIcon
                    sx={{ color: 'black' }}
                    fontSize="inherit"
                  />
                </ListItemIcon>
                <Typography variant="body1" gutterBottom>
                  Correction: You can request that we correct or update your
                  personal information if it is inaccurate.
                </Typography>
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <FiberManualRecordIcon
                    sx={{ color: 'black' }}
                    fontSize="inherit"
                  />
                </ListItemIcon>
                <Typography variant="body1" gutterBottom>
                  Deletion: You can request that we delete your personal
                  information, subject to certain legal exceptions.
                </Typography>
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <FiberManualRecordIcon
                    sx={{ color: 'black' }}
                    fontSize="inherit"
                  />
                </ListItemIcon>
                <Typography variant="body1" gutterBottom>
                  Opt-Out: You can opt out of receiving marketing communications
                  from us by following the unsubscribe instructions in our
                  emails or contacting us directly.
                </Typography>
              </ListItem>
            </List>
          </Typography>
        </Box>

        <Box mb={4}>
          <Typography variant="h5" gutterBottom>
            5. Data Security
          </Typography>
          <Typography variant="body1">
            We take reasonable measures to protect your personal information
            from unauthorized access, use, or disclosure. However, no method of
            transmission over the internet or method of electronic storage is
            100% secure, and we cannot guarantee absolute security.
          </Typography>
        </Box>

        <Box mb={4}>
          <Typography variant="h5" gutterBottom>
            6. International Data Transfer
          </Typography>
          <Typography variant="body1">
            Your information may be transferred to, stored, and processed in
            countries other than your own. By using our Site, you consent to the
            transfer of your information to countries outside your country of
            residence, including countries that may not provide the same level
            of data protection as your home country.
          </Typography>
        </Box>

        <Box mb={4}>
          <Typography variant="h5" gutterBottom>
            7. Third-Party Links
          </Typography>
          <Typography variant="body1">
            Our Site may contain links to third-party websites, services, or
            applications. We are not responsible for the privacy practices of
            these third parties. We encourage you to read the privacy policies
            of any third-party websites you visit.
          </Typography>
        </Box>

        <Box mb={4}>
          <Typography variant="h5" gutterBottom>
            8. Children's Privacy
          </Typography>
          <Typography variant="body1">
            Our Site and services are not directed to children under the age of
            13. We do not knowingly collect personal information from children
            under 13. If we become aware that we have inadvertently collected
            personal information from a child under 13, we will take steps to
            delete such information as soon as possible.
          </Typography>
        </Box>

        <Box mb={4}>
          <Typography variant="h5" gutterBottom>
            9. Changes to This Privacy Policy
          </Typography>
          <Typography variant="body1">
            We may update this Privacy Policy from time to time to reflect
            changes in our practices or for other operational, legal, or
            regulatory reasons. We will notify you of any significant changes by
            posting the new Privacy Policy on our Site and updating the
            effective date at the top of this page.
          </Typography>
        </Box>
      </Box>
    </Container>
  );
};
