export const dummyStories = [
    { avatar: null, background: "linear-gradient(45deg, #FF9A9E, #FAD0C4)" },
    { avatar: null, background: "linear-gradient(45deg, #FBC2EB, #A6C1EE)" },
    { avatar: null, background: "linear-gradient(45deg, #A1C4FD, #C2E9FB)" },
  ];
  
  export const dummyBanners = [
    { avatar: null, background: "linear-gradient(45deg, #84FAB0, #8FD3F4)" },
    { avatar: null, background: "linear-gradient(45deg, #FFDEE9, #B5FFFC)" },
    { avatar: null, background: "linear-gradient(45deg, #FCE38A, #F38181)" },
  ];
  
  export const dummyTiles = [
    { avatar: null, background: "linear-gradient(45deg, #FF9A9E, #FAD0C4)" },
    { avatar: null, background: "linear-gradient(45deg, #A1C4FD, #C2E9FB)" },
    { avatar: null, background: "linear-gradient(45deg, #D4FC79, #96E6A1)" },
    { avatar: null, background: "linear-gradient(45deg, #A18CD1, #FBC2EB)" },
    { avatar: null, background: "linear-gradient(45deg, #FDCB6E, #FF6E7F)" },
    { avatar: null, background: "linear-gradient(45deg, #30Cfd0, #330867)" },
    { avatar: null, background: "linear-gradient(45deg, #667EEA, #764BA2)" },
    { avatar: null, background: "linear-gradient(45deg, #FF9A8B, #FF6A88)" },
    { avatar: null, background: "linear-gradient(45deg, #43C6AC, #191654)" },
  ];