import React from "react";
import { FormValues } from "../../components/vendor-form/types";
import { Onboard } from "../../components/onboard/internal";
import FormContent from "../../components/vendor-form/FormContent";

const vendorSteps = [
  "Basic Info",
  "Contact Info",
  "Additional Info",
  "Account Info",
  // "API Info",
];

const vendorDefaultValues: FormValues = {
  logo: "",
  brandName: "",
  brandEmail: "",
  password: "",
  confirmPassword: "",
  gstin: "",
  streetAddress: "",
  city: "",
  state: "",
  postalCode: "",
  phoneNumber: "",
  numberOfStores: "",
  accountNumber: "",
  confirmAccountNumber: "",
  ifscCode: "",
  bankName: "",
  upi: "",
  // apiKeyToken: "",
  inboundApi: "",
  outboundApi: "",
};

export const VendorOnboard: React.FC = () => {
  return (
    <>
      
      <Onboard
        steps={vendorSteps}
        defaultValues={vendorDefaultValues}
        FormContentComponent={FormContent}
        type={'Vendor'}
      />
    </>
  );
};
