import { fontSize, fontWeight } from "@mui/system";

export const dialogHeaderStyles = {
    textAlign: 'center',
    color: 'white',
    fontSize: '18px',
    fontWeight: 'bold',
    backgroundColor: 'primary.main',
};

export const dialogContentStyles = {
    textAlign: 'center',
    backgroundColor: '#ECE5F4',
};

export const dialogActionsStyles = {
    color: '#281839',
    paddingBottom: 0,
    paddingRight: 0,
};

export const dialogCancelActionStyles = {
    color: "#281839", textTransform: 'none', cursor: 'pointer', backgroundColor: 'white', borderRadius: '8px',
}

export const dialogSubmitActionStyles = {
    color: "white", textTransform: 'none', backgroundColor: '#37CE1F', cursor: 'pointer', borderRadius: '8px',
    "&.Mui-disabled": {
        background: "#cccccc",
        border: '1px solid #cccccc',
    },
    
}