import { FC, ReactNode } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  Typography,
  Stack,
  Divider,
} from "@mui/material";
import {
  dialogActionsStyles,
  dialogCancelActionStyles,
  dialogContentStyles,
  dialogHeaderStyles,
  dialogSubmitActionStyles,
} from "../../styles/dialog";
import RecommendRoundedIcon from '@mui/icons-material/RecommendRounded';
import customTheme from "../theme/customTheme";

interface ConfirmDialogProps {
  open: boolean;
  onConfirm: () => void;
  onClose?: () => void;
  message: string;
  title?: string;
  icon?: ReactNode;
}

export const ConfirmDialog: FC<ConfirmDialogProps> = ({
  open = false,
  onClose,
  onConfirm,
  message,
  title = "Confirm",
  icon = <RecommendRoundedIcon sx={{fontSize:"32px"}} />,
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        style: {
          borderRadius: 10,
          background: customTheme.palette.background.paper,
          width: "400px",
          maxWidth: "90%",
        },
      }}
    >
      <DialogTitle sx={dialogHeaderStyles}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
          <span>{title}</span>
          {icon}
        </Stack>
      </DialogTitle>
      <Divider sx={{ borderBottomWidth: '2px', color: 'primary.main' }} />
      <Box>
        <DialogContent sx={dialogContentStyles}>
          <Box mb={2}>
            <Typography component="span" fontSize={16} >
              {message}
            </Typography>
          </Box>
          <DialogActions 
            sx={{ 
              ...dialogActionsStyles,
              justifyContent: 'center',
              gap: 2,
            }}
          > 
            <>
              {onClose && (
                <Button
                  onClick={onClose}
                  size="large"
                  sx={dialogCancelActionStyles}
                >
                  No
                </Button>
              )}
              <Button
                onClick={onConfirm}
                size="large"
                sx={dialogSubmitActionStyles}
              >
                <span>Yes</span>
              </Button>
            </>
          </DialogActions>
        </DialogContent>
      </Box>
    </Dialog>
  );
};