import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';

interface SpinnerProps {
  color?: 'primary' | 'secondary' | 'inherit' | 'error' | 'info' | 'success' | 'warning' | string;
  size?: number;
}

const Spinner: React.FC<SpinnerProps> = ({ color = 'primary', size = 40 }) => {
  const isPredefinedColor = (color: string): boolean => {
    return ['primary', 'secondary', 'inherit', 'error', 'info', 'success', 'warning'].includes(color);
  };

  return (
    <CircularProgress
      sx={{
        color: isPredefinedColor(color) ? undefined : color, // Use sx prop to apply custom color
      }}
      color={isPredefinedColor(color) ? (color as any) : undefined} // Cast predefined color to any for prop type compatibility
      size={size}
    />
  );
};

export default Spinner;
