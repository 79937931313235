import React from 'react';
import { OuterLayout } from '../../components/outer-layout';
import { ComingSoon } from '../../components/coming-soon';
import { useAuth } from '../../authprovider'; 

export const Reports: React.FC = () => {
  const { currentUser } = useAuth(); 

  const renderDashboardContent = () => {
    if (!currentUser) {
      return null;
    }

    if (currentUser.isManager) {
      return <ComingSoon />;
    }

    if (currentUser.isAdvertiser) {
      return <ComingSoon />;
    }

    if (currentUser.isAdmin) {
      return <ComingSoon />;
    }

    if (currentUser.isVendor) {
      return <ComingSoon />;
    }

    return null;
  };

  return (
    <OuterLayout>
      {renderDashboardContent()}
    </OuterLayout>
  );
};