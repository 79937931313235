import React from "react";
import { Box, Container, Typography } from "@mui/material";

export const CTASection: React.FC = () => {
  const appDownloadLink = "https://example.com/download-app"; 
  const qrCodeUrl = `https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=${encodeURIComponent(
    appDownloadLink
  )}`;

  return (
    <Box
      sx={{
        background: "linear-gradient(135deg, #e3f2fd 0%, #ffffff 100%)",
        py: 8,
        textAlign: "center",
        position: "relative",
        overflow: "hidden",
        mt: 4,
      }}
    >
      <Container maxWidth="md">
        <Typography variant="h4" sx={{ fontWeight: 600, mb: 2 }}>
          Ready to Revolutionize Your Shopping Experience?
        </Typography>
        <Typography variant="body1" sx={{ color: "#5a5a5a", mb: 4 }}>
          Download the ShopPayGo app today and start enjoying seamless, secure,
          and fast shopping.
        </Typography>

        <Box sx={{ mt: 4 }}>
        <img src={qrCodeUrl} alt="Download App QR Code" />
          <Typography variant="body2" sx={{ mb: 2, color: "#5a5a5a" }}>
            Or scan the QR code to download the app
          </Typography>
          
        </Box>
      </Container>
    </Box>
  );
};
