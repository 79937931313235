import React, { useState, useEffect } from "react";
import { Grid, Box, Card, CardContent, Typography, Avatar } from "@mui/material";
import StorefrontIcon from "@mui/icons-material/Storefront";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import InventoryIcon from "@mui/icons-material/Inventory";
import { useInView } from "react-intersection-observer";

interface FeatureProps {
  avatar: React.ReactNode;
  title: string;
  description: string;
  points: string[];
  images: string[];
  relatedIcons?: React.ReactNode[];
}

const featureData: FeatureProps[] = [
  {
    avatar: <StorefrontIcon fontSize="large" color="primary" />,
    title: "App for Shoppers",
    description: "A new shopping experience with ultimate convenience and speed.",
    points: ["Find Your Store", "Scan to Add Items", "Review Bag", "Secure Payment", "Easy Exit"],
    images: [require("../../Images/app1.png"), require("../../Images/app2.png"), require("../../Images/app3.png")],
    relatedIcons: [<ShoppingCartIcon fontSize="medium" color="action" />, <AnalyticsIcon fontSize="medium" color="action" />],
  },
  {
    avatar: <MonetizationOnIcon fontSize="large" color="primary" />,
    title: "Dashboard for Vendors",
    description: "Powerful insights and elevated sales.",
    points: ["Effortless Onboarding", "Targeted Advertising", "Sales Analytics", "Inventory Management", "Customer Insights"],
    images: [require("../../Images/dashboard1.png"), require("../../Images/dashboard1.png"), require("../../Images/dashboard1.png"), require("../../Images/dashboard1.png")],
    relatedIcons: [<InventoryIcon fontSize="medium" color="action" />, <AnalyticsIcon fontSize="medium" color="action" />],
  },
  {
    avatar: <DashboardIcon fontSize="large" color="primary" />,
    title: "Dashboard for Advertisements",
    description: "Manage and optimize your campaigns effectively.",
    points: ["Flexible Subscription Plans", "Multiple Advertisement Options", "Promotions Management", "Analytics & Reporting"],
    images: [require("../../Images/dashboard1.png"), require("../../Images/dashboard1.png"), require("../../Images/dashboard1.png"), require("../../Images/dashboard1.png")],
    relatedIcons: [<MonetizationOnIcon fontSize="medium" color="action" />, <ShoppingCartIcon fontSize="medium" color="action" />],
  },
];

const FeatureCard: React.FC<{ feature: FeatureProps; index: number }> = ({ feature, index }) => {
  const { ref } = useInView({ triggerOnce: true, threshold: 0.3 });
  const isMobile = window.innerWidth <= 768;
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [fade, setFade] = useState(true);
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    if (!isMobile && !isHovered) {
      const interval = setInterval(() => {
        setFade(false);
        setTimeout(() => {
          setCurrentImageIndex((prevIndex) => (prevIndex + 1) % feature.images.length);
          setTimeout(() => setFade(true), 700);
        }, 1000); 
      }, 6000); 
      return () => clearInterval(interval);
    }
  }, [feature.images.length, isMobile, isHovered]);

  return (
    <Grid
      container
      item
      xs={12}
      md={10}
      spacing={4}
      direction={isMobile ? "column" : index % 2 === 0 ? "row" : "row-reverse"}
      alignItems="center"
      ref={ref}
    >
      <Grid item xs={12} md={6}>
        <Card
          sx={{
            width: "100%",
            borderRadius: 4,
            boxShadow: "0px 10px 30px rgba(0, 0, 0, 0.15)",
            p: 3,
            background: "linear-gradient(145deg, #ffffff, #e0f7fa)",
            position: "relative",
            transition: "transform 0.4s ease, box-shadow 0.4s ease",
            "&:hover": {
              transform: "scale(1.05) translateY(-10px)",
              boxShadow: "0px 20px 40px rgba(0, 0, 0, 0.2)",
            },
          }}
        >
          <CardContent>
            <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
              <Avatar
                sx={{
                  background: "linear-gradient(135deg, #29B6F6, #81C784)",
                  color: "#fff",
                  boxShadow: "0px 4px 8px rgba(0,0,0,0.1)",
                  mr: 2,
                }}
              >
                {feature.avatar}
              </Avatar>
              <Typography variant="h6" sx={{ fontWeight: 700, color: "#37474F" }}>
                {feature.title}
              </Typography>
            </Box>
            <Typography variant="body1" sx={{ mb: 2, color: "#5a5a5a" }}>
              {feature.description}
            </Typography>
            <ul style={{ paddingLeft: "1.25rem", color: "#616161", lineHeight: 1.8 }}>
              {feature.points.map((point, idx) => (
                <li key={idx}>
                  <Typography variant="body2" sx={{ fontWeight: 500 }}>
                    {point}
                  </Typography>
                </li>
              ))}
            </ul>
            <Box sx={{ position: "absolute", bottom: 16, right: 16, display: "flex", gap: 1 }}>
              {feature.relatedIcons?.map((icon, idx) => (
                <Box key={idx} sx={{ display: "flex", alignItems: "center" }}>
                  {icon}
                </Box>
              ))}
            </Box>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} md={6}>
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mx: "auto",
            position: "relative",
          }}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <img
            src={isMobile ? feature.images[0] : feature.images[currentImageIndex]}
            alt={`Feature ${index + 1} Image ${currentImageIndex + 1}`}
            style={{
              width: "90%",
              height: "90%",
              objectFit: "cover",
              borderRadius: 4,
              transition: "opacity 1.5s ease-in-out, transform 1.5s ease-in-out", // Increased transition duration
              transform: fade ? "scale(1)" : "scale(1.05)",
              opacity: fade ? 1 : 0,
            }}
          />
        </Box>
      </Grid>
    </Grid>
  );
};

export const Ecosystem: React.FC = () => (
  <Grid container spacing={8} justifyContent="center" mt={2} p={2} direction={{ xs: "column", md: "row" }}>
    {featureData.map((feature, index) => (
      <FeatureCard key={index} feature={feature} index={index} />
    ))}
  </Grid>
);