import React, { useState, useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import {
  TextField,
  Grid,
  Box,
  Typography,
  IconButton,
  InputAdornment,
  Tooltip,
  Avatar,
  useMediaQuery,
} from "@mui/material";
import { Visibility, VisibilityOff, Edit as EditIcon } from "@mui/icons-material";
import { useFormStyles } from "../../styles/text-field";
import { FormValues } from "../vendor-form/types";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { firebaseStorage } from "../../firebase";
import { styled, keyframes } from "@mui/material/styles";

const passwordRequirements = [
  {
    test: (value: string) => value.length >= 8,
    message: "At least 8 characters",
  },
  {
    test: (value: string) => /[A-Z]/.test(value),
    message: "At least one capital letter",
  },
  {
    test: (value: string) => /[a-z]/.test(value),
    message: "At least one lowercase letter",
  },
  {
    test: (value: string) => /[0-9]/.test(value),
    message: "At least one number",
  },
  {
    test: (value: string) => /[!@#$%^&*(),.?":{}|<>]/.test(value),
    message: "At least one special character",
  },
];

const checkPasswordRequirements = (password: string) => {
  return passwordRequirements.map((requirement) => ({
    ...requirement,
    isMet: requirement.test(password),
  }));
};

const tooltipContent = (password: string) => (
  <Box style={{ padding: "8px" }}>
    {checkPasswordRequirements(password).map((req, index) => (
      <Box
        key={index}
        style={{
          color: "black",
          padding: "4px 8px",
          margin: 0,
          display: "flex",
          alignItems: "center",
        }}
      >
        <span
          style={{
            marginRight: "8px",
            color: req.isMet ? "#5dac51" : "red",
          }}
        >
          {req.isMet ? "✓" : "✗"}
        </span>{" "}
        {req.message}
      </Box>
    ))}
  </Box>
);

const validatePassword = (value: string) => {
  const allRequirementsMet = checkPasswordRequirements(value).every(
    (req) => req.isMet
  );
  return allRequirementsMet || "Weak password";
};

const shimmer = keyframes`
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
`;

const pulse = keyframes`
  0% {
    transform: scale(0.95);
    opacity: 0.8;
  }
  50% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(0.95);
    opacity: 0.8;
  }
`;

const LoadingAvatar = styled(Avatar)`
  animation: ${pulse} 1.5s ease-in-out infinite;
  position: relative;
  overflow: hidden;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      90deg,
      rgba(237,234,222, 0) 0%,
      rgba(237,234,222, 0.3) 50%,
      rgba(237,234,222, 0) 100%
    );
    animation: ${shimmer} 1.5s infinite;
    transform: skewX(-20deg);
  }
`;

export const BasicForm: React.FC = () => {
  const {
    control,
    formState: { errors },
    setValue,
    watch,
  } = useFormContext<FormValues>();

  const methods = useFormContext<FormValues>();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const logo = watch("logo");
  const [brandName, setBrandName] = useState(Date.now().toString());
  const [isUploading, setIsUploading] = useState(false);

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword(!showConfirmPassword);

  const handleLogoUploadClick = () => {
    document.getElementById("logo-upload")?.click();
  };

  const handleLogoUploadChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      try {
        setIsUploading(true);
        const downloadURL = await uploadLogoFile(file, brandName);
        setValue("logo", downloadURL);
      } catch (error) {
        console.error("Error uploading logo file:", error);
      } finally {
        setIsUploading(false);
      }
    }
  };

  const validateGst = (value: string) => {
    return (
      /^[A-Za-z0-9]{15}$/.test(value) ||
      "GSTIN must be 15 alphanumeric characters"
    );
  };

  const uploadLogoFile = async (
    logoFile: File,
    brandName: string
  ): Promise<string> => {
    const storageRef = ref(firebaseStorage, `logos/${brandName}`);
    await uploadBytes(storageRef, logoFile);
    const downloadURL = await getDownloadURL(storageRef);
    return downloadURL;
  };

  useEffect(() => {
    const password = methods.getValues("password");
    const allRequirementsMet = checkPasswordRequirements(password).every(
      (req) => req.isMet
    );
    setTooltipOpen(password.length > 0 && !allRequirementsMet);
  }, [methods.watch("password")]);

  useEffect(() => {
    setValue("logo", logo);
  }, [logo, setValue]);

  const isSmallScreen = useMediaQuery("(max-width:600px)");

  return (
    <Box>
      <Box
        sx={{
          mb: "24px",
          display: "flex",
          justifyContent: "space-between",
          flexDirection: isSmallScreen ? "column" : "row",
          gap: isSmallScreen ? "16px" : "0",
        }}
      >
        <Box>
          <Typography sx={{ fontSize: "18px", fontWeight: "600" }} gutterBottom>
            Basic Information
          </Typography>
          <Typography color="#939393" fontSize={14}>
            Please provide your brand name, email, GSTIN, and create your
            password
          </Typography>
        </Box>
        <Box
          sx={{
            position: "relative",
            display: "inline-block",
            mt: isSmallScreen ? "2" : "0",
          }}
        >
          {errors.logo && (
            <Typography sx={{ ...useFormStyles["& .errorText"] }}>
              {errors.logo.message}
            </Typography>
          )}
          {isUploading ? (
  <LoadingAvatar
    sx={{
      width: 88,
      height: 88,
      borderRadius: 2,
      border: errors.logo ? "2px solid red" : "none",
      backgroundColor: "#f0f0f0",
    }}
  />
) : (
  <Avatar
    sx={{
      width: 88,
      height: 88,
      borderRadius: 2,
      border: errors.logo ? "2px solid red" : "none",
    }}
    src={logo as string}
  />
)}
          <IconButton
            sx={{
              position: "absolute",
              bottom: -5,
              right: -5,
              width: 24,
              height: 24,
              backgroundColor: "#45127b",
              color: "white",
              borderRadius: "50%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              border: "1px solid white",
              "&:hover": {
                backgroundColor: (theme) => theme.palette.primary.main,
              },
            }}
            onClick={handleLogoUploadClick}
          >
            <EditIcon sx={{ fontSize: 14 }} />
          </IconButton>
          <Controller
            control={control}
            name="logo"
            rules={{ required: "Required" }}
            render={({ field }) => (
              <>
                <input
                  id="logo-upload"
                  type="file"
                  accept="image/*"
                  style={{ display: "none" }}
                  onChange={handleLogoUploadChange}
                />
              </>
            )}
          />
        </Box>
      </Box>

      <Grid container spacing={3}>
        {/* Brand Name */}
        <Grid item xs={12} md={6}>
          <Typography sx={{ fontSize: "14px" }}>Brand Name*</Typography>
          <Controller
            control={control}
            name="brandName"
            rules={{ required: "Required" }}
            render={({ field }) => (
              <div style={{ position: "relative" }}>
                <TextField
                  id="brand-name"
                  variant="outlined"
                  {...field}
                  error={!!errors.brandName}
                  sx={{ ...useFormStyles }}
                  fullWidth
                  required
                  onChange={(e) => {
                    field.onChange(e);
                    methods.trigger("brandName");
                    setBrandName(e.target.value);
                  }}
                />
                {errors.brandName && (
                  <Typography sx={{ ...useFormStyles["& .errorText"] }}>
                    {errors.brandName.message}
                  </Typography>
                )}
              </div>
            )}
          />
        </Grid>
        {/* Brand Email */}
        <Grid item xs={12} md={6}>
          <Typography sx={{ fontSize: "14px" }}>Brand Email*</Typography>
          <Controller
            control={control}
            name="brandEmail"
            rules={{
              required: "Required",
              pattern: { value: /^\S+@\S+$/, message: "Invalid email address" },
            }}
            render={({ field }) => (
              <div style={{ position: "relative" }}>
                <TextField
                  id="brand-email"
                  variant="outlined"
                  {...field}
                  error={!!errors.brandEmail}
                  sx={{ ...useFormStyles }}
                  fullWidth
                  onChange={(e) => {
                    field.onChange(e);
                    methods.trigger("brandEmail");
                  }}
                />
                {errors.brandEmail && (
                  <Typography sx={{ ...useFormStyles["& .errorText"] }}>
                    {errors.brandEmail.message}
                  </Typography>
                )}
              </div>
            )}
          />
        </Grid>
        {/* Password */}
        <Grid item xs={12} md={6}>
          <Typography sx={{ fontSize: "14px" }}>Password*</Typography>
          <Controller
            control={control}
            name="password"
            rules={{ validate: validatePassword }}
            render={({ field }) => (
              <div style={{ position: "relative" }}>
                <Tooltip
                  title={tooltipContent(field.value)}
                  placement="top"
                  arrow
                  open={tooltipOpen}
                  PopperProps={{
                    modifiers: [
                      {
                        name: "offset",
                        options: {
                          offset: [0, -10],
                        },
                      },
                    ],
                  }}
                  componentsProps={{
                    tooltip: {
                      sx: {
                        backgroundColor: "white",
                        borderRadius: "8px",
                        boxShadow: "0px 4px 4px 2px rgba(0, 0, 0, 0.25)",
                      },
                    },
                    arrow: {
                      sx: {
                        color: "white",
                      },
                    },
                  }}
                >
                  <TextField
                    id="password"
                    type={showPassword ? "text" : "password"}
                    variant="outlined"
                    {...field}
                    error={!!errors.password}
                    sx={{ ...useFormStyles }}
                    fullWidth
                    onChange={(e) => {
                      field.onChange(e);
                      methods.trigger("password");
                    }}
                    onBlur={() => setTooltipOpen(false)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            edge="end"
                            sx={{ mr: 0 }}
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Tooltip>
                {errors.password && (
                  <Typography sx={{ ...useFormStyles["& .errorText"] }}>
                    {errors.password.message}
                  </Typography>
                )}
              </div>
            )}
          />
        </Grid>
        {/* Confirm Password */}
        <Grid item xs={12} md={6}>
          <Typography sx={{ fontSize: "14px" }}>Confirm Password*</Typography>
          <Controller
            control={control}
            name="confirmPassword"
            rules={{
              required: "Required",
              validate: (value) =>
                value === methods.getValues("password") ||
                "Passwords do not match",
            }}
            render={({ field }) => (
              <div style={{ position: "relative" }}>
                <TextField
                  id="confirm-password"
                  type={showConfirmPassword ? "text" : "password"}
                  variant="outlined"
                  {...field}
                  error={!!errors.confirmPassword}
                  sx={{ ...useFormStyles }}
                  fullWidth
                  onChange={(e) => {
                    field.onChange(e);
                    methods.trigger("confirmPassword");
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle confirm password visibility"
                          onClick={handleClickShowConfirmPassword}
                          edge="end"
                          sx={{ mr: 0 }}
                        >
                          {showConfirmPassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                {errors.confirmPassword && (
                  <Typography sx={{ ...useFormStyles["& .errorText"] }}>
                    {errors.confirmPassword.message}
                  </Typography>
                )}
              </div>
            )}
          />
        </Grid>
        {/* GSTIN */}
        <Grid item xs={12} md={6}>
          <Typography sx={{ fontSize: "14px" }}>GSTIN*</Typography>
          <Controller
            control={control}
            name="gstin"
            rules={{ required: "Required", validate: validateGst }}
            render={({ field }) => (
              <div style={{ position: "relative" }}>
                <TextField
                  id="gstin"
                  variant="outlined"
                  {...field}
                  error={!!errors.gstin}
                  sx={{ ...useFormStyles }}
                  fullWidth
                  onChange={(e) => {
                    const upperValue = e.target.value.toUpperCase();
                    field.onChange(upperValue);
                    methods.trigger("gstin");
                  }}
                  value={field.value.toUpperCase()}
                />
                {errors.gstin && (
                  <Typography sx={{ ...useFormStyles["& .errorText"] }}>
                    {errors.gstin.message}
                  </Typography>
                )}
              </div>
            )}
          />
        </Grid>
      </Grid>
    </Box>
  );
};