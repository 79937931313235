import React from 'react';
import { Paper, Typography, Box, Grid } from '@mui/material';

const AdsSections = [
  {
    title: 'Ad Performance',
    items: [
      { label: 'Total Spent:', value: '₹1.6cr' },
      { label: 'Total ads:', value: '100k' },
    ],
  },
  {
    title: 'Category',
    items: [
      { label: 'Tiles:', value: '82k' },
      { label: 'Banners:', value: '16k' },
      { label: 'Stories:', value: '2k' },
    ],
  },
  {
    title: 'Inventory by Dept',
    items: [
      { label: 'Men:', value: '22k' },
      { label: 'Women:', value: '50k' },
      { label: 'Kids:', value: '3k' },
      { label: 'Accessories:', value: '7k' },
    ],
  },
];

const AdsItem = ({ label, value }: { label: string, value: string }) => (
  <Grid container sx={{ marginBottom: '8px' }}>
    <Grid item xs={6}>
      <Typography sx={{ fontSize: '16px', color: '#AAAAAA', whiteSpace: 'nowrap', marginBottom: '8px' }}>{label}</Typography>
    </Grid>
    <Grid item xs={6}>
      <Typography sx={{ color: '#9E0D75', textAlign: 'right', marginBottom: '8px' }}>{value}</Typography>
    </Grid>
  </Grid>
);

export const AdsSummary: React.FC = () => {
    return (
      <Paper elevation={3} sx={{ padding: '16px', backgroundColor: 'white', marginTop: '8px', height: '100%', borderRadius: '10px' }}>
        {AdsSections.map((section) => (
          <Box key={section.title} sx={{ marginBottom: '8px' }}>
            <Typography
              sx={{
                fontSize: section.title === 'Category' || section.title === 'Inventory by Dept' ? '16px' : '18px',
                color: section.title === 'Category' || section.title === 'Inventory by Dept' ? '#AAAAAA' : 'Black',
                fontWeight: 'bolder',
                marginBottom: '8px'
              }}
            >
              {section.title}
            </Typography>
            {section.items.map((item, index) => (
              <AdsItem key={index} label={item.label} value={item.value} />
            ))}
          </Box>
        ))}
      </Paper>
    );
  };