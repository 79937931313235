import React, { useContext, useState } from 'react';
import {
  Button,
  AppBar as MuiAppBar,
  Toolbar,
  Box,
  Typography,
  IconButton,
  styled,
  CircularProgress,
  Modal,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { Logout, Menu, Tune, Store } from '@mui/icons-material';
import customTheme from '../theme/customTheme';
import { SideNav } from '../sidenav';
import { useAuth } from '../../authprovider';
import { ConfirmDialog } from '../confirm-dialog';
import { Selector } from '../selector';
import { CustomGrowOnHover } from '../CustomGrowOnHover';
import { StoreContext } from '../../context/StoreContext';

const HeaderContainer = styled(MuiAppBar)`
  z-index: ${({ theme }) => theme.zIndex.drawer + 1};
  border-radius: none;
  background: ${customTheme.palette.primary.main};
  box-shadow: none;
`;

const MobileModalBox = styled(Box)`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 25vh;
  background-color: ${({ theme }) => theme.palette.background.paper};
  border-radius: 16px 16px 0 0;
  box-shadow: 0px -5px 15px rgba(0, 0, 0, 0.1);
  padding: ${({ theme }) => theme.spacing(2)};
  overflow-y: auto;
  transition: transform 0.3s ease-in-out;
`;


export const Header: React.FC = () => {
  const { currentUser, logout } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const { storesLoading, storesError } = useContext(StoreContext);

  const isManager = currentUser?.isManager;
  const isDashboard = location.pathname === '/dashboard';

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleLogout = () => {
    setOpenConfirmDialog(true);
  };

  const handleConfirmLogout = async () => {
    await logout();
    //navigate('/');
    window.location.href = '/'; //need to reload the page to reset server context
    setOpenConfirmDialog(false);
  };

  const handleCloseDialog = () => {
    setOpenConfirmDialog(false);
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      <HeaderContainer position="relative" style={{ zIndex: 1000 }}>
        <Toolbar
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            gap: 2,
            px: 0,
          }}
        >
          <Box
            sx={{
              alignItems: 'center',
              justifyContent: 'center',
              display: 'flex',
            }}
          >
            <IconButton
              color="inherit"
              onClick={handleDrawerOpen}
              sx={{
                color: 'white',
                display: { xs: 'block', md: 'none' },
                pb: '6px',
              }}
            >
              <Menu />
            </IconButton>
            <Typography
              variant="h5"
              sx={{
                fontSize: '20px',
                color: 'white',
                fontWeight: 'bold',
                cursor: 'pointer',
              }}
              onClick={() => navigate('/')}
            >
              ShopPayGo
            </Typography>
          </Box>
          {/* {!isManager && isDashboard && (
            <Box
              sx={{
                flexGrow: 1,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {storesLoading && <CircularProgress />}
              {storesError && <Typography>Error loading stores</Typography>}
              {!storesLoading && !storesError && !isMobile && <Selector closeModal={() => setOpenModal(false)} />}
            </Box>
          )} */}
          {currentUser && (
            <Box display={'flex'}>
              <CustomGrowOnHover>
                <Button
                  sx={{
                    color: 'white',
                    px: 4,
                    borderRadius: '10px',
                    textTransform: 'none',
                    backgroundColor: '#fcaa4f',
                    display: { xs: 'none', md: 'block' },
                    '&:hover': {
                      backgroundColor: '#fcaa4f',
                    },
                  }}
                  onClick={handleLogout}
                >
                  Logout
                </Button>
              </CustomGrowOnHover>
              <Box
                sx={{
                  display: { xs: 'flex', md: 'none' },
                  flexDirection: 'row',
                  gap: 1,
                }}
              >
                {/* {!isManager && isDashboard && (
                <Store
                  style={{
                    fontSize: 28,
                    cursor: 'pointer',
                    color: '#fcaa4f',
                  }}
                  onClick={handleOpenModal}
                />
                )}
                <Tune
                  style={{
                    fontSize: 28,
                    cursor: 'pointer',
                    color: '#fcaa4f',
                  }}
                  onClick={() => alert('Coming Soon!')}
                /> */}
                <Logout
                  style={{
                    fontSize: 28,
                    cursor: 'pointer',
                    color: '#fcaa4f',
                    marginRight: 8,
                  }}
                  onClick={handleLogout}
                />
              </Box>
            </Box>
          )}
        </Toolbar>
        {isDrawerOpen && (
          <SideNav
            variant="temporary"
            open={isDrawerOpen}
            onClose={() => setDrawerOpen(false)}
          />
        )}
        <ConfirmDialog
          open={openConfirmDialog}
          onClose={handleCloseDialog}
          onConfirm={handleConfirmLogout}
          message="Are you sure you want to logout?"
          title="Logout"
          icon={<Logout sx={{fontSize:"32px"}} />}
        />
      </HeaderContainer>
      <Modal open={openModal} onClose={handleCloseModal}>
      <MobileModalBox sx={{backgroundColor: 'primary.main'}}>
        <Typography 
          variant="h6" 
          component="h2" 
          align="center" 
          sx={{ mb: 3, color: '#fff', fontWeight: 'bold' }}
        >
          Select Store
        </Typography>
        <Selector closeModal={() => setOpenModal(false)} />
        </MobileModalBox>
    </Modal>
    </>
  );
};