import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { TextField, Grid, Typography, Box } from "@mui/material";
import { FormValues } from "./types";
import { useFormStyles } from "../../styles/text-field";

export const AccountForm: React.FC = () => {
  const {
    control,
    formState: { errors },
    trigger,
  } = useFormContext<FormValues>();
  const methods = useFormContext<FormValues>();

  const validateUpi = (value: string) => {
    return value ? (value.includes("@") || "Please enter a valid UPI ID") : true;
  };

  return (
    <Box>
      <Box sx={{ mb: 6 }}>
        <Typography sx={{ fontSize: "18px", fontWeight: "600" }}>
          Account Information
        </Typography>
        <Typography fontSize={14} color="#939393">
          Please provide your account number, bank name, IFSC code, and UPI details
        </Typography>
      </Box>
      <Grid container spacing={3}>
        {/* Account Number */}
        <Grid item xs={12} md={6}>
          <Typography sx={{ fontSize: "14px" }}>Account Number*</Typography>
          <Controller
            control={control}
            name="accountNumber"
            rules={{ required: "Required" }}
            render={({ field }) => (
              <div style={{ position: "relative" }}>
                <TextField
                  id="account-number"
                  variant="outlined"
                  fullWidth
                  {...field}
                  error={!!errors.accountNumber}
                  sx={{ ...useFormStyles }}
                  onChange={(e) => {
                    field.onChange(e);
                    trigger("accountNumber");
                  }}
                  onBlur={() => trigger("accountNumber")}
                  onPaste={(e) => e.preventDefault()}
                  onCopy={(e) => e.preventDefault()}
                />
                {errors.accountNumber && (
                  <Typography sx={{ ...useFormStyles["& .errorText"] }}>
                    {errors.accountNumber.message}
                  </Typography>
                )}
              </div>
            )}
          />
        </Grid>
        {/* Confirm Account Number */}
        <Grid item xs={12} md={6}>
          <Typography sx={{ fontSize: "14px" }}>
            Confirm Account Number*
          </Typography>
          <Controller
            control={control}
            name="confirmAccountNumber"
            rules={{
              required: "Please confirm your account number",
              validate: {
                matchesPrevious: (value) => {
                  const { accountNumber } = methods.getValues();
                  return (
                    value === accountNumber || "Account numbers do not match"
                  );
                },
              },
            }}
            render={({ field }) => (
              <div style={{ position: "relative" }}>
                <TextField
                  id="confirm-account-number"
                  variant="outlined"
                  fullWidth
                  {...field}
                  error={!!errors.confirmAccountNumber}
                  sx={{ ...useFormStyles }}
                  onChange={(e) => {
                    field.onChange(e);
                    trigger("confirmAccountNumber");
                  }}
                  onBlur={() => trigger("confirmAccountNumber")}
                  onPaste={(e) => e.preventDefault()}
                  onCopy={(e) => e.preventDefault()}
                />
                {errors.confirmAccountNumber && (
                  <Typography sx={{ ...useFormStyles["& .errorText"] }}>
                    {errors.confirmAccountNumber.message}
                  </Typography>
                )}
              </div>
            )}
          />
        </Grid>
        {/* Bank Name */}
        <Grid item xs={12} md={6}>
          <Typography sx={{ fontSize: "14px" }}>Bank Name*</Typography>
          <Controller
            control={control}
            name="bankName"
            rules={{ required: "Required" }}
            render={({ field }) => (
              <div style={{ position: "relative" }}>
                <TextField
                  id="bank-name"
                  variant="outlined"
                  fullWidth
                  {...field}
                  error={!!errors.bankName}
                  sx={{ ...useFormStyles }}
                  onChange={(e) => {
                    field.onChange(e);
                    trigger("bankName");
                  }}
                  onBlur={() => trigger("bankName")}
                />
                {errors.bankName && (
                  <Typography sx={{ ...useFormStyles["& .errorText"] }}>
                    {errors.bankName.message}
                  </Typography>
                )}
              </div>
            )}
          />
        </Grid>
        {/* IFSC Code */}
        <Grid item xs={12} md={6}>
          <Typography sx={{ fontSize: "14px" }}>IFSC Code*</Typography>
          <Controller
            control={control}
            name="ifscCode"
            rules={{
              required: "Required",
              pattern: {
                value: /^[A-Za-z]{4}0[A-Z0-9a-z]{6}$/,
                message: "Please enter a valid IFSC code",
              },
            }}
            render={({ field }) => (
              <div style={{ position: "relative" }}>
                <TextField
                  id="ifsc-code"
                  variant="outlined"
                  fullWidth
                  {...field}
                  error={!!errors.ifscCode}
                  sx={{ ...useFormStyles }}
                  onChange={(e) => {
                    field.onChange(e);
                    trigger("ifscCode");
                  }}
                  onBlur={() => trigger("ifscCode")}
                />
                {errors.ifscCode && (
                  <Typography sx={{ ...useFormStyles["& .errorText"] }}>
                    {errors.ifscCode.message}
                  </Typography>
                )}
              </div>
            )}
          />
        </Grid>
        {/* UPI */}
        <Grid item xs={12} md={6}>
          <Typography sx={{ fontSize: "14px" }}>UPI</Typography>
          <Controller
            control={control}
            name="upi"
            rules={{ validate: validateUpi }}
            render={({ field }) => (
              <div style={{ position: "relative" }}>
                <TextField
                  id="upi"
                  variant="outlined"
                  fullWidth
                  {...field}
                  error={!!errors.upi}
                  sx={{ ...useFormStyles }}
                  onChange={(e) => {
                    field.onChange(e);
                    trigger("upi");
                  }}
                  onBlur={() => trigger("upi")}
                />
                {errors.upi && (
                  <Typography sx={{ ...useFormStyles["& .errorText"] }}>
                    {errors.upi.message}
                  </Typography>
                )}
              </div>
            )}
          />
        </Grid>
      </Grid>
    </Box>
  );
};