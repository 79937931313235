import React from "react";
import { Box, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";
import customTheme from "../theme/customTheme";
import { useNavigate } from "react-router-dom";

export const FormHeader: React.FC<any> = ({ heading }) => {
  const navigate = useNavigate();
  return (
    <Box
      style={{
        backgroundColor: customTheme.palette.primary.main,
        padding: 0,
        flexDirection: "row",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        borderRadius: "4px",
      }}
    >
      <Typography fontSize={18} fontWeight={"bold"} color="white" p={2}>
        {heading}
      </Typography>
      <Close
        sx={{
          color: "#8E8E8E",
          fontSize: 28,
          cursor: "pointer",
          position: "absolute",
          top: '-7px',
          right: '-7px',
          borderRadius: '50%',
          background: 'white',
          padding: '4px',
          "&:hover": { color: "red" },
        }}
        onClick={() => navigate(-1)}
      />
    </Box>
  );
};
