import React, { useContext } from 'react';
import {
  MenuItem,
  FormControl,
  Select,
  SelectChangeEvent,
  Typography,
  Box,
  Button,
  CircularProgress,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { StoreContext } from '../../context/StoreContext';

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  minWidth: 120,
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#fff',
      boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
      transition: 'border-color 0.3s ease, box-shadow 0.3s ease',
    },
    '&:hover fieldset': {
      borderColor: '#fcaa4f',
      boxShadow: '0px 4px 15px rgba(252, 170, 79, 0.5)',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#fcaa4f',
      boxShadow: '0px 4px 20px rgba(252, 170, 79, 0.6)',
    },
  },
}));

interface SelectorProps {
  closeModal: () => void;
}

export const Selector: React.FC<SelectorProps> = ({ closeModal }) => {
  const {
    stores,
    selectedStore,
    setSelectedStore,
    storesLoading,
    storesError,
  } = useContext(StoreContext);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleStoreChange = (event: SelectChangeEvent<string>) => {
    const storeId = event.target.value;
    setSelectedStore(storeId);
  };

  const handleReset = () => {
    if (stores.length > 0) {
      setSelectedStore(stores[0]._id);
    }
    closeModal();
  };

  const handleConfirm = () => {
    closeModal();
  };

  const storeOptions = stores.map((store) => ({
    value: store._id,
    label: store.name,
  }));

  if (storesLoading) {
    return <CircularProgress />;
  }

  if (storesError) {
    return <Typography>Error loading stores</Typography>;
  }

  const buttonText = isMobile ? 'Confirm' : 'Reset';
  const buttonAction = isMobile ? handleConfirm : handleReset;

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: { md: 1, xs: 2, sm: 1 },
        flexDirection: {
          xs: 'column',
          md: 'row',
          sm: 'row',
        },
      }}
    >
      <StyledFormControl variant="outlined">
        <Select
          labelId="store-select-label"
          id="store-select"
          value={selectedStore}
          onChange={handleStoreChange}
          displayEmpty
          inputProps={{ 'aria-label': 'Without label' }}
          sx={{
            '& .MuiSelect-select': {
              color: '#333',
              fontWeight: 'bold',
              padding: '12px 20px',
              fontSize: '14px',
            },
            '& .MuiSvgIcon-root': {
              color: '#333',
            },
            height: '40px', // Set height to match the logout button
            width: { xs: '80vw', md: '160px', sm: '160px' },
            borderRadius: '10px',
            backgroundColor: 'white',
            transition: 'background-color 0.3s ease',
            '&:hover': {
              backgroundColor: '#f5f5f5',
            },
          }}
        >
          {storeOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              <Typography
                sx={{ fontWeight: '700', fontSize: '14px', color: '#888' }}
              >
                {option.label}
              </Typography>
            </MenuItem>
          ))}
        </Select>
      </StyledFormControl>

      <Button
        variant="outlined"
        onClick={buttonAction}
        sx={{
          color: 'white',
          px: 4,
          width: { xs: '80vw', md: 'auto', sm: 'auto' },
          height: '40px', 
          textTransform: 'none',
          backgroundColor: '#fcaa4f',
          borderRadius: '10px',
          '&:hover': {
            backgroundColor: '#fcaa4f',
          },
        }}
      >
        {buttonText}
      </Button>
    </Box>
  );
};