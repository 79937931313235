import React from 'react';
import { OuterLayout } from '../../components/outer-layout';
import { useAuth } from '../../authprovider';
import { Account } from '../../components/accountinfo';
import { Subscription } from '../../components/accountinfo/Subscription';
import { Grid } from '@mui/material';
import { LimitDetails } from '../../components/accountinfo/LimitDetails';
import { ContactSPG } from '../../components/accountinfo/ContactSPG';
import { PaymentHistory } from '../../components/accountinfo/PaymentHistory';

export const AccountInfo: React.FC = () => {
  const { currentUser } = useAuth(); 

  const renderDashboardContent = () => {
    if (!currentUser) {
      return null;
    }

    if (currentUser.isManager) {
      return (
        <>
          <Account />
          {/* <Grid container spacing={1} sx={{ mt: '1px' }}>
            <Grid item xs={12} md={12}>
              <Subscription />
            </Grid>
            <Grid item xs={12} md={3}>
              <LimitDetails />
            </Grid>
          </Grid> */}
          {/* <Grid container spacing={1} sx={{ mt: '1px' }}>
            <Grid item xs={12} md={4}>
              <ContactSPG />
            </Grid>
          </Grid> */}
        </>
      );
    }

    if (currentUser.isAdvertiser) {
      return (
        <>
          <Account />
          <Grid container spacing={1} sx={{ mt: '1px' }}>
            <Grid item xs={12} md={12}>
              <Subscription />
            </Grid>
            {/* <Grid item xs={12} md={3}>
              <LimitDetails />
            </Grid> */}
          </Grid>
          <Grid container spacing={1} sx={{ mt: '1px' }}>
            {/* <Grid item xs={12} md={4}>
              <ContactSPG />
            </Grid> */}
            <Grid item xs={12}>
              <PaymentHistory />
            </Grid>
          </Grid>
        </>
      );
    }

    if (currentUser.isAdmin) {
      return (
        <>
          <Account />
          <Grid container spacing={1} sx={{ mt: '1px' }}>
            <Grid item xs={12} md={12}>
              <Subscription />
            </Grid>
            {/* <Grid item xs={12} md={3}>
              <LimitDetails />
            </Grid> */}
          </Grid>
          <Grid container spacing={1} sx={{ mt: '1px' }}>
            {/* <Grid item xs={12} md={4}>
              <ContactSPG />
            </Grid> */}
            <Grid item xs={12}>
              <PaymentHistory />
            </Grid>
          </Grid>
        </>
      );
    }

    if (currentUser.isVendor) {
      return (
        <>
          <Account />
          <Grid container spacing={1} sx={{ mt: '1px' }}>
            <Grid item xs={12} md={12}>
              <Subscription />
            </Grid>
            {/* <Grid item xs={12} md={3}>
              <LimitDetails />
            </Grid> */}
          </Grid>
          <Grid container spacing={1} sx={{ mt: '1px' }}>
            {/* <Grid item xs={12} md={4}>
              <ContactSPG />
            </Grid> */}
            <Grid item xs={12}>
              <PaymentHistory />
            </Grid>
          </Grid>
        </>
      );
    }

    return null;
  };

  return (
    <OuterLayout>
      {renderDashboardContent()}
    </OuterLayout>
  );
};