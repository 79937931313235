import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "../../authprovider";
import { useRoleMenuItems } from "../../utils/role";
import customTheme from "../theme/customTheme";
import CompanyLogo from "../../Images/shoppershop.png";
import { useTheme } from '@mui/system';
import { Chip } from "@mui/material";
import { CustomGrowOnHover } from "../CustomGrowOnHover";
import packageJson from "../../../package.json";

const drawerWidth = 240;

const Drawer = styled(MuiDrawer)(() => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
}));

const UserProfile: React.FC<{ currentUser: any, role: string }> = ({ currentUser, role }) => (
  <Box
    sx={{
      display: "flex",
      flexDirection: "row",
      backgroundColor: customTheme.palette.secondary.main,
      p: 1,
      justifyContent: "flex-start",
      gap: 1,
      borderRadius: "10px",
      mb: 2,
    }}
  >
    <img
      src={currentUser?.profile?.logo || CompanyLogo}
      alt="Logo"
      style={{
        borderRadius: "10px",
        width: 60,
        height: 60,
        objectFit: 'contain',
      }}
    />
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Chip
        style={{
          backgroundColor: "#3EAD56",
          color: "white",
          fontWeight: "bold",
          borderRadius: "5px",
        }}
        label={role}
        size="small"
      />
      <Typography
        color="black"
        fontSize={14}
        style={{ textWrap: "nowrap" }}
      >
        {currentUser?.profile?.name || 'Vendor Name'}
      </Typography>
    </Box>
  </Box>
);

const MenuItem: React.FC<{ item: any, index: number, selectedIndex: number, handleListItemClick: (index: number, path: string) => void }> = ({ item, index, selectedIndex, handleListItemClick }) => (
  <ListItem
    key={index}
    disablePadding
    sx={{
      display: "block",
      backgroundColor: selectedIndex === index ? "#fcaa4f" : "transparent",
      mb: 1,
      borderRadius: "10px",
      width: "auto",
      "&:hover": {
        backgroundColor: selectedIndex === index ? "#fcaa4f" : "transparent",
        "& .MuiListItemIcon-root": {
          color: selectedIndex === index ? "white" : "#fcaa4f",
        },
        "& .MuiListItemText-primary": {
          color: selectedIndex === index ? "white" : "#fcaa4f",
        },
      },
      "@media (max-width: 600px)": {
        "&:hover": {
          backgroundColor: "#fcaa4f",
          "& .MuiListItemIcon-root": {
            color: "white",
          },
          "& .MuiListItemText-primary": {
            color: "white",
          },
        },
      },
    }}
    onClick={() => handleListItemClick(index, item.path)}
  >
    {selectedIndex === index ? (
      <ListItemButton
        sx={{
          minHeight: 40,
          justifyContent: "center",
          px: 4,
          gap: 1.5,
          height: "20px",
        }}
      >
        <ListItemIcon
          sx={{ minWidth: 0, justifyContent: "center", color: "white" }}
        >
          {item.icon}
        </ListItemIcon>
        <ListItemText
          primary={item.text}
          primaryTypographyProps={{
            sx: {
              color: "white",
              fontSize: "15px",
            },
          }}
        />
      </ListItemButton>
    ) : (
      <CustomGrowOnHover>
        <ListItemButton
          sx={{
            minHeight: 40,
            justifyContent: "center",
            px: 4,
            gap: 1.5,
            height: "20px",
          }}
        >
          <ListItemIcon
            sx={{ minWidth: 0, justifyContent: "center", color: "white" }}
          >
            {item.icon}
          </ListItemIcon>
          <ListItemText
            primary={item.text}
            primaryTypographyProps={{
              sx: {
                color: "white",
                fontSize: "15px",
              },
            }}
          />
        </ListItemButton>
      </CustomGrowOnHover>
    )}
  </ListItem>
);

export const SideNav: React.FC<any> = ({
  variant = "permanent",
  onClose = null,
  open = true,
}) => {
  const { currentUser } = useAuth();
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;

  const isAdmin = currentUser?.isAdmin;
  const isManager = currentUser?.isManager;
  const isAdvertiser = currentUser?.isAdvertiser;
  const isVendor = currentUser?.isVendor;

  const role = isAdmin ? 'ADMIN' : isManager ? 'MANAGER' : isAdvertiser ? 'ADVERTISER' : isVendor ? 'VENDOR' : 'User';
  const allMenuItems = useRoleMenuItems();
  const menuItems = allMenuItems[role] || [];

  const initialIndex = menuItems.findIndex((item) => item.path === currentPath);
  const [selectedIndex, setSelectedIndex] = useState(
    initialIndex !== -1 ? initialIndex : 0
  );

  useEffect(() => {
    const currentIndex = menuItems.findIndex(
      (item) => item.path === location.pathname
    );
    if (currentIndex !== -1) {
      setSelectedIndex(currentIndex);
    }
  }, [location, menuItems]);

  const handleListItemClick = (index: number, path: string) => {
    navigate(path);
  };

  return (
    <Box sx={{ display: "flex", p: 1, pr: 0, height: `calc(100vh - 64px)` }}>
      <Drawer
        anchor="left"
        variant={variant}
        open={open}
        sx={{
          "& .MuiDrawer-paper": {
            background: customTheme.palette.primary.main,
            borderRadius: "10px",
            position: "static",
            p: 1,
            overflow: "hidden",
            pt: { xs: 2, sm: 1 },
            display: "flex",
            flexDirection: "column",
          },
          flexGrow: 1,
        }}
        onClose={onClose}
      >
        <Typography
          variant="h5"
          sx={{
            fontSize: "24px",
            color: "white",
            fontWeight: "bold",
            cursor: "pointer",
            textAlign: 'center',
            display: { xs: 'block', md: 'none' },
            mb: 2,
          }}
        >
          ShopPayGo
        </Typography>
        <UserProfile currentUser={currentUser} role={role} />
        <List sx={{ flexGrow: 1 }}>
          {menuItems.map((item, index) => (
            <MenuItem
              key={index}
              item={item}
              index={index}
              selectedIndex={selectedIndex}
              handleListItemClick={handleListItemClick}
            />
          ))}
        </List>
        <Box sx={{ mt: 'auto' }}>
          <img
            src={require("../../Images/navbar.png")}
            alt="Description"
            style={{ width: "100%", height: '100%', }}
          />
        </Box>
        <Box sx={{
          position: 'absolute',
          bottom: 8,
          left: 80,
          mb: 1
        }}>
          <Typography
            variant="body2"
            color="white"
            sx={{ fontSize: '0.8rem' }}
          >
            Version: {packageJson.version}
          </Typography>
        </Box>
      </Drawer>
    </Box>
  );
};