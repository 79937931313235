import React from 'react';
import { Box, Typography, Button, Grid, Container, styled, Avatar } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import LocationOnIcon from '@mui/icons-material/LocationOn';

interface ContactMethod {
  icon: React.ReactNode;
  title: string;
  description: string;
  value: string;
}

const StyledButton = styled(Button)({
  borderRadius: '10px',
  padding: '6px 18px',
  fontSize: '16px',
  fontWeight: 600,
  textTransform: 'none',
});

export const Contact: React.FC = () => {
  const contactMethods: ContactMethod[] = [
    {
      icon: <EmailIcon />,
      title: 'Chat to us',
      description: 'Our friendly team is here to help',
      value: 'internal@shoppaygo.in',
    },
    {
      icon: <PhoneIcon />,
      title: 'Call us',
      description: 'Mon-Fri from 8am to 5pm',
      value: '+91 7777777777',
    },
    {
      icon: <LocationOnIcon />,
      title: 'Visit us',
      description: 'Come say hello at our office HQ',
      value: 'Bangalore, India',
    },
  ];

  const handleEmailClick = () => {
    const email = 'internal@shoppaygo.in'; 
    const subject = 'Inquiry about ShopPayGo'; 
    const body = 'Hello,\n\nI would like to get in touch regarding...'; 
    window.location.href = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
  };

  return (
    <>
    <Box sx={{ background: 'linear-gradient(135deg, #ffffff 0%, #e0f7fa 100%)', padding: '20px 0' }}>
      <Container maxWidth="lg">
        <Grid container spacing={2} alignItems="center">
          {/* Left Grid for Image */}
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                overflow: 'hidden', 
                position: 'relative',
              }}
            >
              <img
                src={require('../../Images/contact.png')}
                alt="Contact"
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                  objectPosition: 'center',
                  borderRadius: '8px',
                }}
              />
            </Box>
          </Grid>

          {/* Right Grid for Contact Queries */}
          <Grid item xs={12} md={6}>
            <Box sx={{ padding: { xs: 2, md: 4 } }}>
              <Typography variant="h4" component="h2" fontWeight={600} gutterBottom>
                Get in touch with us
              </Typography>
              <Typography variant="body1" color="text.secondary" sx={{ mb: 4 }}>
                Whether you have questions about our services, need support, or just want to learn
                more about ShopPayGo, we're always happy to connect with you. Reach out to us
                through any of the following methods, and we'll get back to you as soon as possible.
              </Typography>
              <Box display="flex" flexDirection="column" gap={3}>
                {contactMethods.map((method, index) => (
                  <Box key={index} display="flex" alignItems="center" gap={2}>
                    <Avatar
                      sx={{
                        background: "linear-gradient(135deg, #29B6F6, #81C784)",
                        color: "#fff",
                        boxShadow: "0px 4px 8px rgba(0,0,0,0.1)",
                        mr: 2,
                      }}
                    >
                      {method.icon}
                    </Avatar>
                    <Box>
                      <Typography variant="h6" fontWeight={600}>
                        {method.title}
                      </Typography>
                      <Typography variant="body1" color="text.secondary">
                        {method.description}
                      </Typography>
                      <Typography variant="body1">{method.value}</Typography>
                    </Box>
                  </Box>
                ))}
              </Box>
              <StyledButton variant="contained" sx={{ backgroundColor: "primary.main", mt: 4 }} onClick={handleEmailClick}>
                Contact Us
              </StyledButton>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
    </>
  );
};