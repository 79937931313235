import React from 'react';
import { Paper, Typography, Box, Grid } from '@mui/material';

const inventorySections = [
  {
    title: 'Inventory Summary',
    items: [
      { label: 'Total Inventory Value:', value: '₹1.6cr' },
      { label: 'Total Inventory:', value: '100k' },
    ],
  },
  {
    title: 'Inventory Age',
    items: [
      { label: 'New Arrivals:', value: '82k' },
      { label: 'Order Placed:', value: '16k' },
      { label: 'Stagnant Items:', value: '2k' },
    ],
  },
  {
    title: 'Inventory by Dept',
    items: [
      { label: 'Men:', value: '22k' },
      { label: 'Women:', value: '50k' },
      { label: 'Kids:', value: '3k' },
      { label: 'Accessories:', value: '7k' },
    ],
  },
];

const InventoryItem = ({ label, value }: { label: string, value: string }) => (
  <Grid container sx={{ marginBottom: '8px' }}>
    <Grid item xs={6}>
      <Typography sx={{ fontSize: '16px', color: '#AAAAAA', whiteSpace: 'nowrap', marginBottom: '8px' }}>{label}</Typography>
    </Grid>
    <Grid item xs={6}>
      <Typography sx={{ color: '#9E0D75', textAlign: 'right', marginBottom: '8px' }}>{value}</Typography>
    </Grid>
  </Grid>
);

export const InventorySummary: React.FC = () => {
    return (
      <Paper elevation={3} sx={{ padding: '16px', backgroundColor: 'white', marginTop: '8px', height: '100%', borderRadius: '10px' }}>
        {inventorySections.map((section) => (
          <Box key={section.title} sx={{ marginBottom: '8px' }}>
            <Typography
              sx={{
                fontSize: section.title === 'Inventory Age' || section.title === 'Inventory by Dept' ? '16px' : '18px',
                color: section.title === 'Inventory Age' || section.title === 'Inventory by Dept' ? '#AAAAAA' : 'Black',
                fontWeight: 'bolder',
                marginBottom: '8px'
              }}
            >
              {section.title}
            </Typography>
            {section.items.map((item, index) => (
              <InventoryItem key={index} label={item.label} value={item.value} />
            ))}
          </Box>
        ))}
      </Paper>
    );
  };