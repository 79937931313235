import {
  Box,
  Container,
  Typography,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";

export const FAQs = ({ faqs }: { faqs: { question: string; answer: string }[] }) => (
  <Box sx={{ background: "linear-gradient(135deg, #ffffff 0%, #e3f2fd 100%)", py: 8 }}>
    <Container maxWidth="lg">
      <Box textAlign="center" mb={6}>
        <SupportAgentIcon fontSize="large" sx={{ color: "#1976d2", mb: 1 }} />
        <Typography variant="h4" sx={{ fontWeight: 600, color: "#004d40" }}>
          Frequently Asked Questions
        </Typography>
        <Typography variant="body1" sx={{ color: "#5a5a5a", mt: 1 }}>
          Have questions? We’ve got the answers you need.
        </Typography>
      </Box>

      <Grid container spacing={4}>
        {faqs.map((faq, index) => (
          <Grid item xs={12} md={6} key={index}>
            <Accordion
              sx={{
                borderRadius: 2,
                boxShadow: "0px 3px 6px rgba(0,0,0,0.1)",
                overflow: "hidden",
                "&:before": { display: "none" },
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon sx={{ color: "#1976d2", fontSize: "large" }} />}
                sx={{ backgroundColor: "#e3f2fd", "&:hover": { backgroundColor: "#bbdefb" }, padding: 2 }}
              >
                <HelpOutlineIcon fontSize="small" sx={{ color: "#1976d2", mr: 1, mt: 1 }} />
                <Typography variant="h6" sx={{ fontWeight: 600 }}>
                  {faq.question}
                </Typography>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  padding: 2,
                  backgroundColor: "#fff",
                  borderTop: "1px solid #e0e0e0",
                  transition: "background-color 0.3s ease",
                  "&:hover": { backgroundColor: "#e0f7fa" },
                }}
              >
                <Typography variant="body2" sx={{ color: "#5a5a5a" }}>
                  {faq.answer}
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Grid>
        ))}
      </Grid>
    </Container>
  </Box>
);

