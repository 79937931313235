import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { TextField, Grid, Typography, Box, Autocomplete } from "@mui/material";
import { useFormStyles } from "../../styles/text-field";
import { FormValues } from "../vendor-form/types";

const indianStates = [
  'Andhra Pradesh', 'Arunachal Pradesh', 'Assam', 'Bihar', 'Chhattisgarh',
  'Goa', 'Gujarat', 'Haryana', 'Himachal Pradesh', 'Jharkhand', 'Karnataka',
  'Kerala', 'Madhya Pradesh', 'Maharashtra', 'Manipur', 'Meghalaya',
  'Mizoram', 'Nagaland', 'Odisha', 'Punjab', 'Rajasthan', 'Sikkim',
  'Tamil Nadu', 'Telangana', 'Tripura', 'Uttar Pradesh', 'Uttarakhand',
  'West Bengal', 'Andaman and Nicobar Islands', 'Chandigarh',
  'Dadra and Nagar Haveli and Daman and Diu', 'Delhi', 'Jammu and Kashmir',
  'Ladakh', 'Lakshadweep', 'Puducherry'
];

export const ContactForm: React.FC = () => {
  const { control, formState: { errors } } = useFormContext<FormValues>(); 

  const validatePhoneNumber = (value: string) => {
    return /^\d{10}$/.test(value) || "Invalid phone number";
  };

  const handlePostalCodeChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, 
    onChange: (...event: any[]) => void
  ) => {
    const value = e.target.value.replace(/[^0-9]/g, '').slice(0, 6);
    onChange(value);
  };

  return (
    <Box>
      <Box sx={{ mb: 6 }}>
        <Typography sx={{ fontSize: '18px', fontWeight: '600' }}>Contact Information</Typography>
        <Typography fontSize={14} color="#939393">
          Please provide your address, ZIP code, and phone number
        </Typography>
      </Box>

      <Grid container spacing={3}>
        {/* Street Address */}
        <Grid item xs={12} md={6}>
          <Typography sx={{ fontSize: '14px' }}>Street Address*</Typography>
          <Controller
            control={control}
            name="streetAddress"
            rules={{ required: "Required" }}
            render={({ field }) => (
              <div style={{ position: "relative" }}>
                <TextField
                  id="street-address"
                  variant="outlined"
                  fullWidth
                  {...field}
                  error={!!errors.streetAddress}
                  sx={{ ...useFormStyles }}
                />
                {errors.streetAddress && (
                  <Typography sx={{ ...useFormStyles["& .errorText"] }}>
                    {errors.streetAddress.message}
                  </Typography>
                )}
              </div>
            )}
          />
        </Grid>

        {/* City */}
        <Grid item xs={12} md={6}>
          <Typography sx={{ fontSize: '14px' }}>City*</Typography>
          <Controller
            control={control}
            name="city"
            rules={{ required: "Required" }}
            render={({ field }) => (
              <div style={{ position: "relative" }}>
                <TextField
                  id="city"
                  variant="outlined"
                  fullWidth
                  {...field}
                  error={!!errors.city}
                  sx={{ ...useFormStyles }}
                />
                {errors.city && (
                  <Typography sx={{ ...useFormStyles["& .errorText"] }}>
                    {errors.city.message}
                  </Typography>
                )}
              </div>
            )}
          />
        </Grid>

        {/* State */}
        <Grid item xs={12} md={6}>
          <Typography sx={{ fontSize: '14px' }}>State*</Typography>
          <Controller
            control={control}
            name="state"
            rules={{ required: "Required" }}
            render={({ field }) => (
              <div style={{ position: 'relative' }}>
                <Autocomplete
                  options={indianStates}
                  getOptionLabel={(option) => option}
                  onChange={(_, value) => field.onChange(value)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      error={!!errors.state}
                      sx={{ ...useFormStyles }}
                      fullWidth
                    />
                  )}
                />
                {errors.state && (
                  <Typography sx={{ ...useFormStyles["& .errorText"] }}>
                    {errors.state.message}
                  </Typography>
                )}
              </div>
            )}
          />
        </Grid>

        {/* Postal Code */}
        <Grid item xs={12} md={6}>
          <Typography sx={{ fontSize: '14px' }}>Postal Code*</Typography>
          <Controller
            control={control}
            name="postalCode"
            rules={{
              required: "Required",
              minLength: { value: 6, message: "Postal code must be 6 digits" },
              maxLength: { value: 6, message: "Postal code must be 6 digits" },
              pattern: { value: /^\d{6}$/, message: "Postal code must be 6 digits" }
            }}
            render={({ field }) => (
              <div style={{ position: "relative" }}>
          <TextField
            id="postal-code"
            variant="outlined"
            fullWidth
            {...field}
            error={!!errors.postalCode}
            sx={{ ...useFormStyles }}
            inputProps={{ 
              maxLength: 6,
              inputMode: 'numeric',
              pattern: '[0-9]*'
            }}
            onChange={(e) => handlePostalCodeChange(e, field.onChange)}
          />
          {errors.postalCode && (
            <Typography sx={{ ...useFormStyles["& .errorText"] }}>
              {errors.postalCode.message}
            </Typography>
          )}
              </div>
            )}
          />
        </Grid>

        {/* Phone Number */}
        <Grid item xs={12} md={6}>
          <Typography sx={{ fontSize: '14px' }}>Phone Number*</Typography>
          <Controller
            control={control}
            name="phoneNumber"
            rules={{
              required: "Required",
              validate: validatePhoneNumber,
            }}
            render={({ field }) => (
              <div style={{ position: "relative" }}>
                <TextField
                  id="phone-number"
                  variant="outlined"
                  fullWidth
                  {...field}
                  error={!!errors.phoneNumber}
                  sx={{ ...useFormStyles }}
                  type="tel"
                />
                {errors.phoneNumber && (
                  <Typography sx={{ ...useFormStyles["& .errorText"] }}>
                    {errors.phoneNumber.message}
                  </Typography>
                )}
              </div>
            )}
          />
        </Grid>
      </Grid>
    </Box>
  );
};