import { gql } from '@apollo/client';

//MUTATION
export const CREATE_VENDOR = gql`
  mutation createVendor($vendorInput: VendorInput!) {
    createVendor(vendorInput: $vendorInput) {
      _id
      name
      address
      logo
      phoneNo
      email
      gstin
      upi
      bankDetails {
        accountNumber
        ifsc
        bankName
      }
      inboundApi
      outboundApi
      apiToken
      noOfStores
      role
      createdBy
      createdDate
    }
  }
`;

export const GET_VENDOR_BY_EMAIL = gql`
query GetVendorByEmail($email: String!) {
  getVendorByEmail(email: $email) {
    _id
    name
    address
    logo
    phoneNo
    email
    gstin
    upi
    bankDetails {
      accountNumber
      ifsc
      bankName
    }
    inboundApi
    outboundApi
    apiToken
    noOfStores
    role
    createdBy
    createdDate
  }
}
`;

export const GET_VENDOR_BY_AUTH = gql`
query GetVendorByAuth {
  getVendorByAuth {
    _id
    name
    address
    logo
    phoneNo
    email
    gstin
    upi
    bankDetails {
      accountNumber
      ifsc
      bankName
    }
    inboundApi
    outboundApi
    apiToken
    noOfStores
    role
    createdBy
    createdDate
  }
}
`;