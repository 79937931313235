import React, { createContext, useContext, ReactNode, useState, useEffect, useCallback } from 'react';
import {
  signInWithPhoneNumber,
  signInWithEmailAndPassword,
  ConfirmationResult,
  signOut,
  onAuthStateChanged,
  setPersistence,
  browserLocalPersistence,
  createUserWithEmailAndPassword,
  sendEmailVerification,
  sendPasswordResetEmail,
  User as FirebaseUser,
} from 'firebase/auth';
import { auth, RecaptchaVerifier } from './firebase';
import { useMutation, useLazyQuery } from '@apollo/client';
import { CREATE_VENDOR, GET_VENDOR_BY_AUTH } from './gql/vendor';

// Extending the FirebaseUser to include profile and role-related properties
interface ExtendedUser extends FirebaseUser {
  profile?: any;
  isAdmin?: boolean;
  isAdvertiser?: boolean;
  isVendor?: boolean;
  isManager?: boolean;
}

interface AuthContextType {
  sendOtp: (phoneNumber: string) => Promise<ConfirmationResult | null>;
  loginWithEmailAndPassword: (email: string, password: string) => Promise<void>;
  signupWithEmailAndPassword: (data: any) => Promise<void>;
  isLoggedIn: boolean;
  authLoading: boolean;
  logout: () => void;
  currentUser: ExtendedUser | null;
  setCurrentUser: (user: ExtendedUser | null) => void; // Add setCurrentUser
  resetPassword: (email: string) => Promise<void>; 
  setUserProfile: (user: any) => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

const resetPassword = async (email: string): Promise<void> => {
  try {
    await sendPasswordResetEmail(auth, email);
    console.log('Password reset email sent');
  } catch (error) {
    console.error('Error sending password reset email:', error);
    throw error;
  }
};

export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [authLoading, setAuthLoading] = useState<boolean>(true);
  const [recaptchaVerifier, setRecaptchaVerifier] = useState<RecaptchaVerifier | null>(null);
  const [currentUser, setCurrentUser] = useState<ExtendedUser | null>(null);
  const [createVendorMutation] = useMutation(CREATE_VENDOR);
  const [getVendorByAuth] = useLazyQuery(GET_VENDOR_BY_AUTH);

  useEffect(() => {
    const verifier = new RecaptchaVerifier(auth, 'recaptcha-container', {
      size: 'invisible',
      callback: (response: any) => {
        console.log('Recaptcha solved:', response);
      },
      'expired-callback': () => {
        console.log('Recaptcha expired');
      },
    });
    setRecaptchaVerifier(verifier);

    // Set persistence
    setPersistence(auth, browserLocalPersistence)
      .then(() => {
        console.log('Persistence set to local');
      })
      .catch((error) => {
        console.error('Error setting persistence:', error);
      });

    // Monitor auth state changes
    const unsubscribe = onAuthStateChanged(auth, async (user: FirebaseUser | null) => {
      if (user?.emailVerified) {
        console.log("user----", user);
        const token = await user.getIdToken();
        localStorage.setItem("accessToken", token);

        // Fetch user detail from DB using the email
        const { data } = await getVendorByAuth();
        if (data) {
          const profile = data.getVendorByAuth;
          const extendedUser: ExtendedUser = {
            ...user,
            profile,
            isAdmin: profile.role === 'ADMIN',
            isAdvertiser: profile.role === 'ADVERTISER',
            isVendor: profile.role === 'VENDOR',
            isManager: profile.role === 'MANAGER',
          };
          setIsLoggedIn(true);
          setAuthLoading(false);
          setCurrentUser(extendedUser);
        } else {
          setIsLoggedIn(false);
          setAuthLoading(false);
          setCurrentUser(null);
          await logout();  //Firebase logout
        }
      } else {
        setIsLoggedIn(false);
        setAuthLoading(false);
        setCurrentUser(null);
      }
    });

    return () => unsubscribe();
  }, []);

  const sendOtp = async (phoneNumber: string): Promise<ConfirmationResult | null> => {
    if (!recaptchaVerifier) {
      console.error('Recaptcha verifier not initialized yet');
      return null;
    }

    try {
      const confirmation = await signInWithPhoneNumber(auth, phoneNumber, recaptchaVerifier);
      return confirmation;
    } catch (error) {
      console.error('Error sending OTP:', error);
      return null;
    }
  };

  const loginWithEmailAndPassword = async (email: string, password: string): Promise<void> => {
    try {
      const userCredentials = await signInWithEmailAndPassword(auth, email, password);
      if (!userCredentials?.user?.emailVerified) {
        const error = new Error("Email is not verified");
        await logout();
        throw error;
      }
    } catch (error) {
      console.error('Error logging in with email and password:', error);
      throw error;
    }
  };

  const signupWithEmailAndPassword = async (data: any): Promise<void> => {
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, data.brandEmail, data.password);
      await sendEmailVerification(userCredential.user);

      // Map the input data to vendorInput
      const vendorInput = {
        name: data.brandName,
        logo: data.logo ?? null,
        address: `${data.streetAddress}, ${data.city}, ${data.state}, ${data.postalCode}`,
        phoneNo: data.phoneNumber,
        email: data.brandEmail,
        gstin: data.gstin,
        upi: data.upi ?? null,
        bankDetails: [{
          accountNumber: data.accountNumber ?? null,
          ifsc: data.ifscCode ?? null,
          bankName: data.bankName ?? null,
        }],
        inboundApi: data.inboundApi ?? null, 
        outboundApi: data.outboundApi ?? null,
        noOfStores: parseInt(data.numberOfStores, 10),
        apiToken: data.apiKeyToken ?? null,
        role: data.role,
      };

      // Call the createVendor mutation
      await createVendorMutation({ variables: { vendorInput } });
      console.log('Vendor created successfully');
    } catch (error) {
      console.error('Error signing up with email and password:', error);
      throw error;
    }
  };

  const logout = useCallback(async (): Promise<void> => {
    try {
      await signOut(auth);
      setIsLoggedIn(false);
      setCurrentUser(null);
      localStorage.removeItem("accessToken");
    } catch (error) {
      console.error('Error logging out:', error);
      throw error;
    }
  }, []);
 // Inactivity Timer Logic
 useEffect(() => {
  let logoutTimer: NodeJS.Timeout | null = null;

  const resetLogoutTimer = () => {
    if (logoutTimer) clearTimeout(logoutTimer);
    logoutTimer = setTimeout(async () => {
      console.log('User inactive for 4 hours. Logging out...');
      await logout();
    }, 4 * 60 * 60 * 1000); // 4 hours in milliseconds
  };

  const activityListener = () => {
    resetLogoutTimer();
  };

  // Attach event listeners for activity
  window.addEventListener('mousemove', activityListener);
  window.addEventListener('keydown', activityListener);
  window.addEventListener('scroll', activityListener);

  resetLogoutTimer(); // Initialize timer on mount

  return () => {
    if (logoutTimer) clearTimeout(logoutTimer);
    window.removeEventListener('mousemove', activityListener);
    window.removeEventListener('keydown', activityListener);
    window.removeEventListener('scroll', activityListener);
  };
}, [logout]);

  const setUserProfile = async (user: any) => {
    const { data } = await getVendorByAuth();
        if (data) {
          const profile = data.getVendorByAuth;
          const extendedUser: ExtendedUser = {
            ...user,
            profile,
            isAdmin: profile.role === 'ADMIN',
            isAdvertiser: profile.role === 'ADVERTISER',
            isVendor: profile.role === 'VENDOR',
            isManager: profile.role === 'MANAGER',
          };
          setIsLoggedIn(true);
          setAuthLoading(false);
          setCurrentUser(extendedUser);
        } else {
          setIsLoggedIn(false);
          setAuthLoading(false);
          setCurrentUser(null);
          await logout();  //Firebase logout
        }
  }

  const authContextValue: AuthContextType = {
    sendOtp,
    loginWithEmailAndPassword,
    signupWithEmailAndPassword,
    isLoggedIn,
    authLoading,
    logout,
    currentUser,
    setCurrentUser,
    resetPassword,
    setUserProfile,
  };

  return <AuthContext.Provider value={authContextValue}>{children}</AuthContext.Provider>;
};