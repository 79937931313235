import React from 'react';
import { Box, Grid, Typography, Divider } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  box: {
    backgroundColor: 'white',
    color: 'black',
    width: '100%',
    padding: '16px',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  },
  gridItem: {
    textAlign: 'center',
  },
  text: {
    fontSize: '14px',
    color: '#AAAAAA',
  },
  divider: {
    height: 'auto',
    margin: '0 8px',
    color: '#AAAAAA',
  },
});

interface InsightsProps {
  itemsSold: string;
  totalSale: string;
  averageTransaction: string;
  averageQuantity: string;
  noOfTransactions: string;
  peakHours: string;
}

interface DailyInsightsProps {
  userRole: string;
  insights?: InsightsProps;
}

const getInsightsData = (userRole: string) => {
  switch (userRole.toUpperCase()) {
    case 'MANAGER':
      return [
        { number: 8, text: 'Active Team Members', color: '#33AABA' },
        { number: '85%', text: 'Client Satisfaction', color: '#BB50B0' },
        { number: '₹1,20,000', text: 'Budget Allocated', color: '#3EAD56' },
        { number: 15, text: 'Campaigns Managed', color: '#F48D43' },
        { number: 20, text: 'Meetings Scheduled', color: '#E30815' },
        { number: '₹1,500', text: 'Average Spend per Client', color: '#E38800' },
      ];
    case 'ADVERTISER':
      return [
        { number: 120, text: 'Ads Run', color: '#33AABA' },
        { number: '₹1,50,000', text: 'Total Spend', color: '#BB50B0' },
        { number: '₹500', text: 'Average CPC', color: '#3EAD56' },
        { number: 3000, text: 'Impressions', color: '#F48D43' },
        { number: 200, text: 'Clicks', color: '#E30815' },
        { number: '5-6 PM', text: 'Peak Hours', color: '#E38800' },
      ];
    case 'ADMIN':
      return [
        { number: 2500, text: 'Total Users', color: '#33AABA' },
        { number: '₹5,00,000', text: 'Revenue Generated', color: '#BB50B0' },
        { number: '99.9%', text: 'Platform Uptime', color: '#3EAD56' },
        { number: 45, text: 'Support Tickets Resolved', color: '#F48D43' },
        { number: 3, text: 'Pending Issues', color: '#E30815' },
        { number: '5-6 PM', text: 'Peak Usage Time', color: '#E38800' },
      ];
    case 'VENDOR':
    default:
      return [
        { number: 325, text: 'Items Sold', color: '#33AABA' },
        { number: '₹2,59,675', text: 'Total Sale', color: '#BB50B0' },
        { number: '₹1,195', text: 'Average Transaction', color: '#3EAD56' },
        { number: 4, text: 'Average Quantity', color: '#F48D43' },
        { number: 65, text: 'No of Transactions', color: '#E30815' },
        { number: '7-8 PM', text: 'Peak Hours', color: '#E38800' },
      ];
  }
};

export const DailyInsights: React.FC<DailyInsightsProps> = ({
  userRole,
  insights,
}) => {
  const classes = useStyles();

  const insightsData = insights
    ? [
        { number: insights.itemsSold, text: 'Items Sold', color: '#33AABA' },
        { number: `₹${insights.totalSale}`, text: 'Total Sale', color: '#BB50B0' },
        {
          number: `₹${insights.averageTransaction}`,
          text: 'Average Transaction',
          color: '#3EAD56',
        },
        {
          number: insights.averageQuantity,
          text: 'Average Quantity',
          color: '#F48D43',
        },
        {
          number: insights.noOfTransactions,
          text: 'No of Transactions',
          color: '#E30815',
        },
        { number: insights.peakHours, text: 'Peak Hours', color: '#E38800' },
      ]
    : getInsightsData(userRole);

  return (
    <Box className={classes.box}>
      <Typography sx={{ mb: 2, fontSize: '18px', fontWeight: 'bolder' }}>
        {userRole.toUpperCase() === 'ADMIN' ? 'Stats' : 'Daily Insights'}
      </Typography>
      <Grid container alignItems="center" sx={{ mb: 1, gap: 2 }}>
        {insightsData.map((insight, index) => (
          <React.Fragment key={index}>
            <Grid item xs>
              <Box className={classes.gridItem}>
                <Typography
                  style={{
                    color: insight.color,
                    fontWeight: '700',
                    fontSize: '22px',
                  }}
                >
                  {insight.number}
                </Typography>
                <Typography className={classes.text}>{insight.text}</Typography>
              </Box>
            </Grid>
            {index < insightsData.length - 1 && (
              <Divider
                orientation="vertical"
                flexItem
                className={classes.divider}
              />
            )}
          </React.Fragment>
        ))}
      </Grid>
    </Box>
  );
};