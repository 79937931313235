import React from 'react';
import { Dashboard, Badge, Campaign, Summarize, Payments, Announcement, ManageAccounts, CallOutlined } from '@mui/icons-material';
import { useAuth } from '../authprovider';

export interface MenuItem {
  icon: React.ReactElement;
  text: string;
  path: string;
}

export const useRoleMenuItems = (): { [key: string]: MenuItem[] } => {
  const { currentUser } = useAuth();

  if (!currentUser) {
    return {};
  }

  const adminMenuItems = [
    { icon: <Dashboard />, text: 'Dashboard', path: '/dashboard' },
    { icon: <Badge />, text: 'Account Info', path: '/accountinfo' },
    { icon: <Campaign />, text: 'Advertisements', path: '/advertiser' },
    { icon: <Summarize />, text: 'Reports', path: '/reports' },
    { icon: <Payments />, text: 'Payments', path: '/payments' },
    { icon: <Announcement />, text: 'Announcements', path: '/announcements' },
    { icon: <ManageAccounts />, text: 'Manage', path: '/manage' },
    { icon: <CallOutlined />, text: 'Contact Us', path: '/contact' },
  ];

  const advertiserMenuItems = [
    { icon: <Dashboard />, text: 'Dashboard', path: '/dashboard' },
    { icon: <Badge />, text: 'Account Info', path: '/accountinfo' },
    { icon: <Campaign />, text: 'Advertisements', path: '/advertiser' },
    { icon: <Summarize />, text: 'Reports', path: '/reports' },
    { icon: <Payments />, text: 'Payments', path: '/payments' },
    { icon: <Announcement />, text: 'Announcements', path: '/announcements' },
    { icon: <CallOutlined />, text: 'Contact Us', path: '/contact' },
  ];

  const vendorMenuItems = [
    { icon: <Dashboard />, text: 'Dashboard', path: '/dashboard' },
    { icon: <Badge />, text: 'Account Info', path: '/accountinfo' },
    { icon: <Campaign />, text: 'Advertisements', path: '/advertiser' },
    { icon: <Summarize />, text: 'Reports', path: '/reports' },
    { icon: <Payments />, text: 'Payments', path: '/payments' },
    { icon: <Announcement />, text: 'Announcements', path: '/announcements' },
    { icon: <CallOutlined />, text: 'Contact Us', path: '/contact' },
  ];

  const menuItems = {
    ADMIN: adminMenuItems,
    ADVERTISER: advertiserMenuItems,
    VENDOR: vendorMenuItems,
  };

  return menuItems;
};