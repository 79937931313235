import React, { useContext, useState, useRef, useEffect } from "react";
import { DeviceFrameset } from "react-device-frameset";
import "react-device-frameset/styles/marvel-devices.min.css";
import { Box, Typography, IconButton } from "@mui/material";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import StoreOutlinedIcon from "@mui/icons-material/StoreOutlined";
import BarcodeOutlinedIcon from "@mui/icons-material/QrCodeScannerOutlined";
import ShoppingBagOutlinedIcon from "@mui/icons-material/ShoppingBagOutlined";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import { StoryDialog } from "./StoryDialog";
import { dummyStories, dummyBanners, dummyTiles } from "./dummyData";
import { FileContext } from "../../../context/FileContext";
import { ContentFeed } from "./ContentFeed";

export const FrameSet: React.FC = () => {
  const { stories, banners, tiles } = useContext(FileContext);
  const [open, setOpen] = useState(false);
  const [selectedStoryIndex, setSelectedStoryIndex] = useState(0);
  const [isBuffering, setIsBuffering] = useState(false);
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    if (open && videoRef.current) {
      videoRef.current.play().catch((error) => {
        console.error("Error attempting to play the video:", error);
      });
    }
  }, [open, selectedStoryIndex]);

  const combinedStories = [...stories, ...dummyStories];
  const combinedBanners = [...banners, ...dummyBanners];
  const combinedTiles = [...tiles, ...dummyTiles];

  const handleOpen = (index: number) => {
    const story = combinedStories[index];
    if (dummyStories.includes(story)) {
      return;
    }
    setSelectedStoryIndex(index);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedStoryIndex(0);
  };

  const handleNext = () => {
    setIsBuffering(true);
    setSelectedStoryIndex((prevIndex) => (prevIndex + 1) % combinedStories.length);
  };

  const handlePrevious = () => {
    setIsBuffering(true);
    setSelectedStoryIndex((prevIndex) => (prevIndex - 1 + combinedStories.length) % combinedStories.length);
  };

  return (
    <>
      <style>
        {`
          .marvel-device.iphone-x .notch {
              left: 55px; 
          }
          .slick-slide > div {
              margin: 0 5px;
          }
          .slick-list {
              margin: 0 -5px; 
          }
          ::-webkit-scrollbar {
              width: 0;
              height: 0;
          }
          .no-scrollbar {
              scrollbar-width: none; 
          }
        `}
      </style>
      <DeviceFrameset device="iPhone X" width={270} height={550}>
        <Box
          sx={{
            backgroundColor: "#ECE6F4",
            p: 1,
            width: "100%",
            zIndex: 1,
            top: 0,
            mt: 0,
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              pt: 4,
            }}
          >
            <Typography sx={{ color: "#281839", fontWeight: "bolder" }}>
              ShopPayGo
            </Typography>

          </Box>
        </Box>
        <ContentFeed
          combinedStories={combinedStories}
          combinedBanners={combinedBanners}
          combinedTiles={combinedTiles}
          handleOpen={handleOpen}
        />
        <Box
          sx={{
            width: "100%",
            position: "absolute",
            bottom: 0,
            backgroundColor: "white",
            borderTop: "1px solid #DDD",
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            height: "60px",
            right: 7,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                position: "relative",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: 50,
                height: 24,
                backgroundColor: "primary.main",
                borderRadius: "10px",
              }}
            >
              <HomeOutlinedIcon sx={{ color: "white", fontSize: 16 }} />
            </Box>
            <Typography
              variant="caption"
              sx={{ fontSize: 10, fontWeight: "bold" }}
            >
              Home
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <BarcodeOutlinedIcon sx={{ fontSize: 20 }} />
            <Typography variant="caption" sx={{ fontSize: 10, mt: "3px" }}>
              Scan
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <ShoppingBagOutlinedIcon sx={{ fontSize: 20 }} />
            <Typography variant="caption" sx={{ fontSize: 10, mt: "3px" }}>
              Bag
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <AccountCircleOutlinedIcon sx={{ fontSize: 20 }} />
            <Typography variant="caption" sx={{ fontSize: 10, mt: "3px" }}>
              Account
            </Typography>
          </Box>
        </Box>
      </DeviceFrameset>
      <StoryDialog
        open={open}
        handleClose={handleClose}
        handleNext={handleNext}
        handlePrevious={handlePrevious}
        selectedStoryIndex={selectedStoryIndex}
        combinedStories={combinedStories}
        isBuffering={isBuffering}
        setIsBuffering={setIsBuffering}
        videoRef={videoRef}
        dummyStories={dummyStories}
      />
    </>
  );
};