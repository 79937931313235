import React, { useState, useEffect, useContext } from 'react';
import { DailyInsights } from "../../components/dashboard/DailyInsights";
import { InventorySummary } from "../../components/dashboard/InventorySummary";
import { OuterLayout } from "../../components/outer-layout";
import { CircularProgress, Grid, Typography } from "@mui/material";
import { Bar1 } from "../../components/dashboard/Bar1";
import { Bar2 } from "../../components/dashboard/Bar2";
import { AdsSummary } from "../../components/dashboard/AdsSummary";
import { LineGraph } from "../../components/dashboard/LineGraph";
import {
  monthlyPromotionData,
  yearlyPromotionData,
  monthlySalesData,
  yearlySalesData,
  impressionsData,
  costPerClickData,
  itemsSoldData,
  categoriesSoldData,
  newUsersData,
  permanentUsersData,
} from "../../components/dashboard/data";
import { useAuth } from "../../authprovider"; 
import { SubscriptionPerformance } from "../../components/dashboard/SubscriptionPerformance";
import { useQuery } from '@apollo/client';
import { GET_INSIGHTS } from '../../gql/insights';
import { StoreContext, StoreProvider } from '../../context/StoreContext';

export const Dashboard: React.FC = () => {
  const { currentUser } = useAuth();
  const { selectedStore } = useContext(StoreContext);
  const [insightsData, setInsightsData] = useState<any>(null);

  // Fetch insights for the selected store
  const {
    loading: insightsLoading,
    error: insightsError,
    data: insightsDataResult,
  } = useQuery(GET_INSIGHTS, {
    variables: {
      storeId: selectedStore,
      status: 'DAILY',
    },
    skip: !selectedStore,
  });

  // Update insights data when query result changes
  useEffect(() => {
    if (insightsDataResult && insightsDataResult.getInsights) {
      setInsightsData(insightsDataResult.getInsights[0]);
    } else {
      setInsightsData(null);
    }
  }, [insightsDataResult]);

  const renderDashboardContent = () => {
    if (!currentUser) {
      return null;
    }

    if (currentUser.isManager) {
      return (
        <>
          <DailyInsights userRole="MANAGER" />
          <Grid container spacing={1}>
            <Grid item xs={12} md={9}>
              <LineGraph
                monthlyData={monthlyPromotionData}
                yearlyData={yearlyPromotionData}
                heading="Total Promotions"
                dataKey="promotions"
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <AdsSummary />
            </Grid>
          </Grid>
          <Grid container spacing={1} sx={{ mt: "0px" }}>
            <Grid item xs={12} md={6}>
              <Bar1
                data={impressionsData}
                heading="Impressions"
                dataKey="impressions"
                labelKey="item" 
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Bar2
                data={costPerClickData}
                heading="Cost per Click"
                dataKey="cost"
                labelKey='campaign'
              />
            </Grid>
          </Grid>
        </>
      );
    }

    if (currentUser.isAdvertiser) {
      return (
        <>
          <DailyInsights userRole="ADVERTISER" />
          <Grid container spacing={1}>
            <Grid item xs={12} md={9}>
              <LineGraph
                monthlyData={monthlyPromotionData}
                yearlyData={yearlyPromotionData}
                heading="Total Promotions"
                dataKey="promotions"
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <AdsSummary />
            </Grid>
          </Grid>
          <Grid container spacing={1} sx={{ mt: "0px" }}>
            <Grid item xs={12} md={6}>
              <Bar1
                data={impressionsData}
                heading="Impressions"
                dataKey="impressions"
                labelKey="item" 
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Bar2
                data={costPerClickData}
                heading="Cost per Click"
                dataKey="cost"
                labelKey='campaign'
              />
            </Grid>
          </Grid>
        </>
      );
    }

    if (currentUser.isAdmin) {
      return (
        <>
          <DailyInsights userRole="ADMIN" />
          <Grid container spacing={1}>
            <Grid item xs={12} md={9}>
              <LineGraph
                monthlyData={monthlySalesData}
                yearlyData={yearlySalesData}
                heading="Total Sales"
                dataKey="sales"
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <SubscriptionPerformance />
            </Grid>
          </Grid>
          <Grid container spacing={1} sx={{ mt: "0px" }}>
            <Grid item xs={12} md={6}>
              <Bar1
                data={newUsersData}
                heading="New Users"
                dataKey="quantity"
                labelKey="month" 
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Bar2
                data={permanentUsersData}
                heading="Permanent Users"
                dataKey="quantity"
                labelKey='month'
              />
            </Grid>
          </Grid>
        </>
      );
    }

    if (currentUser.isVendor) {
      return (
        <>
         {/* Daily Insights Component */}
         {/* {insightsLoading && <CircularProgress />} */}
         {insightsError && <Typography>Error loading insights</Typography>}
         {insightsData && <DailyInsights userRole="VENDOR" insights={insightsData} />}
          <Grid container spacing={1}>
            <Grid item xs={12} md={9}>
              <LineGraph
                monthlyData={monthlySalesData}
                yearlyData={yearlySalesData}
                heading="Total Sales"
                dataKey="sales"
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <InventorySummary />
            </Grid>
          </Grid>
          <Grid container spacing={1} sx={{ mt: "0px" }}>
            <Grid item xs={12} md={6}>
              <Bar1
                data={itemsSoldData}
                heading="Items Sold"
                dataKey="quantity"
                labelKey="item" 
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Bar2
                data={categoriesSoldData}
                heading="Categories Sold"
                dataKey="quantity"
                labelKey='category'
              />
            </Grid>
          </Grid>
        </>
      );
    }

    return null;
  };

  return <OuterLayout>{renderDashboardContent()}</OuterLayout>;
};