// ColorlibStepIcon.tsx
import { styled } from '@mui/material/styles';
import { StepIconProps } from '@mui/material/StepIcon';
import customTheme from '../theme/customTheme';

const ColorlibStepIconRoot = styled('div')<{
  ownerState: { completed?: boolean; active?: boolean };
}>(({ theme, ownerState }) => ({
  backgroundColor: '#2C3E50', 
  zIndex: 1,
  color: '#B8C2CC',
  width: 50,
  height: 50,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: '1.5rem',
  border: '2px solid transparent',
  transition: 'all 0.3s ease-in-out',
  ...(ownerState.active && {
    backgroundColor: '#FCAA4F', 
    boxShadow: '0 4px 10px 0 rgba(255, 200, 0, 0.3)',
    color: '#ffffff',
    transform: 'scale(1.1)',
  }),
  ...(ownerState.completed && {
    backgroundColor: '#FCAA4F', 
    color: '#ffffff',
    border: '2px solid #FCAA4F',
    boxShadow: '0 4px 10px 0 rgba(255, 200, 0, 0.3)',
  }),
}));

function ColorlibStepIcon(props: StepIconProps) {
  const { active, completed, className } = props;

  return (
    <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
      {props.icon}
    </ColorlibStepIconRoot>
  );
}

export default ColorlibStepIcon;