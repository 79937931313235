import React from "react";
import { OuterLayout } from "../../components/outer-layout";
import { ComingSoon } from "../../components/coming-soon";
import { RecentAddInsights } from "../../components/advertiser/RecentAddInsights";
import { SubscriptionInfo } from "../../components/advertiser/SubscriptionInfo";
import { Box, Grid } from "@mui/material";
import { TopPerformingAds } from "../../components/advertiser/TopPerformingAds";
import { Resource } from "../../components/advertiser/resource/Resource";
import { useAuth } from "../../authprovider";
import { FrameSet } from "../../components/advertiser/frameset/internal";
import { FileProvider } from "../../context/FileContext";

export const Advertiser: React.FC = () => {
  const { currentUser } = useAuth();

  const renderDashboardContent = () => {
    if (!currentUser) {
      return null;
    }

    if (currentUser.isManager) {
      return (
        <>
          <ComingSoon />
        </>
      );
    }

    if (currentUser.isAdvertiser) {
      return <AdvertiserItem />;
    }

    if (currentUser.isAdmin) {
      return (
        <>
          <AdvertiserItem />
        </>
      );
    }

    if (currentUser.isVendor) {
      return (
        <>
          <AdvertiserItem />
        </>
      );
    }

    return (
      <>
        <AdvertiserItem />
      </>
    );
  };

  return <OuterLayout>{renderDashboardContent()}</OuterLayout>;
};

const AdvertiserItem = () => {
  return (
    <>
      <FileProvider>
        {/* <RecentAddInsights />
        <Grid container spacing={1} sx={{ mt: "1px" }}>
          <Grid item xs={12} md={12}>
            <SubscriptionInfo />
          </Grid>
          <Grid item xs={12} md={6}>
            <TopPerformingAds />
          </Grid>
        </Grid> */}
          <Box
          style={{display: "flex", height: '100%' }}
        >
          <Grid container spacing={0}>
            <Grid
              item
              xs={12}
              md={8}
              style={{
                paddingTop: 0,
                borderRadius: "10px",
                background: "white",
              }}
            >
              <Resource />
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
              style={{
                display: "flex",
                justifyContent: "center",
                padding: "10px",
                alignItems: "center",
              }}
            >
              <FrameSet />
            </Grid>
          </Grid>
        </Box>

      </FileProvider>
    </>
  );
};
