import React from "react";
import { Container, Grid, Paper, Typography, Box, Button } from "@mui/material";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import DashboardIcon from "@mui/icons-material/Dashboard";
import StorefrontIcon from "@mui/icons-material/Storefront";
import { useNavigate } from "react-router-dom";

const benefits = [
  {
    title: "Increased Sales",
    description: "Reach more customers and increase your sales with targeted advertising and promotions.",
    icon: <MonetizationOnIcon fontSize="large" sx={{ color: "#29B6F6" }} />,
  },
  {
    title: "Powerful Insights",
    description: "Gain valuable insights into customer behavior and sales trends with our advanced analytics tools.",
    icon: <DashboardIcon fontSize="large" sx={{ color: "#4CAF50" }} />,
  },
  {
    title: "Seamless Integration",
    description: "Easily integrate with our platform and start selling in no time with our user-friendly onboarding process.",
    icon: <StorefrontIcon fontSize="large" sx={{ color: "#FFA500" }} />,
  },
];

export const WhyOnboard: React.FC = () => {
    const navigate = useNavigate();
    const handleGetStarted = () => {
        navigate("/vendor-onboard");
      };
  return (
    <Container maxWidth="lg" sx={{ py: 8 }}>
      <Typography variant="h4" sx={{ textAlign: "center", fontWeight: 700, mb: 6 }}>
        Why Onboard on SPG?
      </Typography>
      <Typography variant="body1" sx={{ textAlign: "center", mb: 6, color: "#5a5a5a" }}>
        Discover the benefits of joining our platform and how it can help you grow your business.
      </Typography>
      <Grid container spacing={6}>
        {benefits.map((benefit, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Paper
              elevation={3}
              sx={{
                p: 4,
                textAlign: "center",
                borderRadius: 3,
                background: "#e3f2fd",
                position: "relative",
                transition: "transform 0.3s, boxShadow 0.3s",
                "&:hover": {
                  transform: "scale(1.05)",
                  boxShadow: "0px 12px 24px rgba(0, 0, 0, 0.1)",
                },
                overflow: "hidden",
              }}
            >
              <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mb: 2 }}>
                {benefit.icon}
              </Box>
              <Typography variant="h6" sx={{ fontWeight: 600 }}>
                {benefit.title}
              </Typography>
              <Typography variant="body2" sx={{ mt: 1, color: "#5a5a5a", textAlign: "center" }}>
                {benefit.description}
              </Typography>
            </Paper>
          </Grid>
        ))}
      </Grid>
      <Box
        sx={{
          mt: 8,
          p: 4,
          background: "linear-gradient(135deg, #ffffff 0%, #e0f7fa 100%)",
          borderRadius: 3,
          textAlign: "center",
          boxShadow: "0px 12px 24px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Typography variant="h5" sx={{ fontWeight: 600, mb: 2 }}>
          Ready to Get Started?
        </Typography>
        <Typography variant="body1" sx={{ mb: 4, color: "#5a5a5a" }}>
          Join SPG today and take your business to the next level with our powerful tools and features.
        </Typography>
        <Button
          variant="contained"
          onClick={handleGetStarted}
          sx={{
            backgroundColor: "#281839",
            color: "#fff",
            fontSize: "16px",
            fontWeight: "500",
            textTransform: "none",
            px: 3,
            py: 1,
            borderRadius: "10px",
            border: "2px solid #281839",
            "&:hover": {
              backgroundColor: "#1f1137",
            },
          }}
        >
          Get Started
        </Button>
      </Box>
    </Container>
  );
};