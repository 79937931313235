import { createTheme, ThemeOptions } from '@mui/material/styles';

const customThemeOptions: ThemeOptions = {
  palette: {
    primary: {
      main: '#281839',  // Primary color
    },
    secondary: {
      main: '#ECE5F4',  // Accent color
    },
    background: {
      default: '#fff',   // Background color
      paper: '#ECE5F4',  // Surface color
    },
    text: {
      primary: '#110904',    // Text color
      disabled: '#8E8E8E',   // Disabled text color
    },
    action: {
      disabledBackground: '#8E8E8E', // Disabled background color
      hoverOpacity: 0.08,    // Hover opacity for interactive elements
    },
    error: {
      main: '#F94449',   // Error color
    },
    success: {
      main: '#4BB543',   // Success color
    },
    divider: '#a1a1a1',  // Placeholder color
  },
  shape: {
    borderRadius: 10,  // Roundness
  },
  // Add additional customizations here if needed
};

const customTheme = createTheme(customThemeOptions);

export default customTheme;
