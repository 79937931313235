import React, { useState } from 'react';
import {
  Box,
  TextField,
  Typography,
  Avatar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Tooltip,
  useMediaQuery,
  InputAdornment,
  CircularProgress,
} from '@mui/material';
import { UploadFile as UploadIcon, Search as SearchIcon } from '@mui/icons-material';
import { OuterLayout } from '../../components/outer-layout';
import { useTheme } from '@mui/material/styles';
import {
  tableContainerStyle,
  tableHeadRowStyle,
  tableCellStyle,
  lastTableCellStyle,
  avatarStyle,
  iconButtonStyle,
  outerBoxStyle,
  headerBoxStyle,
  searchInputStyle,
} from './style';
import { firebaseStorage } from '../../firebase';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { useQuery, useMutation } from '@apollo/client';
import { GET_STORES_WITH_VENDOR } from '../../gql/store';
import { UPLOAD_INVENTORY } from '../../gql/inventory';
import { SnackbarComponent } from '../../components/snackbar-component';
import Spinner from '../../components/Spinner';

export const Manage: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error' | 'warning' | 'info'>('success');
  const [selectedStoreId, setSelectedStoreId] = useState('');

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { loading, error, data, refetch } = useQuery(GET_STORES_WITH_VENDOR);
  const [uploadInventory, { loading: uploadLoading }] = useMutation(UPLOAD_INVENTORY);

  if (error) return <p>Error: {error.message}</p>;

  const filteredStores = data?.getStores.filter(
    (store: any) =>
      store?.vendor?.role === 'VENDOR' &&
      store?.vendor?.name?.toLowerCase()?.includes(searchTerm.toLowerCase())
  ) || [];

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const handleUpdateInventoryClick = async (storeId: string) => {
    if (storeId) {
      setSelectedStoreId(storeId);
      const fileInput = document.createElement('input');
      fileInput.type = 'file';
      fileInput.accept = '.csv';
      fileInput.onchange = (event: any) => handleFileChange(event, storeId);
      fileInput.click();
    } else {
      alert('Store not found.');
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>, storeId: string) => {
    const file = event.target.files?.[0];
    if (file) {
      const fileExtension = file.name.split('.').pop()?.toLowerCase();
      if (fileExtension === 'csv') {
        uploadFile(storeId, file);
      } else {
        alert('Please upload a CSV file.');
      }
    }
  };

  const uploadFile = async (storeId: string, file: File) => {
    try {
      const storageRef = ref(firebaseStorage, `Inventory/${storeId}/${file.name}`);
      await uploadBytes(storageRef, file);
      const fileURL = await getDownloadURL(storageRef);
      console.log('File URL:', fileURL);
  
      const response = await uploadInventory({ variables: { csvUrl: fileURL, storeId } });
      console.log('Upload inventory response:', response);
  
      const { success, message, errors, updatedCount, failedCount, totalCount } = response.data.uploadInventory;

      let snackbarMsg = `${message}`;
      if (errors && errors.length > 0) {
        snackbarMsg += `\nErrors:\n${errors.join('\n')}`;
      }
      snackbarMsg += `\nTotal Entries: ${totalCount}\nUpdated: ${updatedCount}\nFailed: ${failedCount}`;
  
      setSnackbarMessage(snackbarMsg);
      setSnackbarSeverity(success ? 'success' : 'error');
      setSnackbarOpen(true);
  
      if (success) {
        // Refetch the stores data to get the updated updatedDate
        await refetch();
      }
    } catch (error) {
      console.error('Error uploading file:', error);
      setSnackbarMessage('Error uploading file');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };
  

  const handleCloseSnackbar = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      setSnackbarOpen(false);
    }
    setSnackbarOpen(false);
  };
  
  return (
    <OuterLayout>
      <Box sx={outerBoxStyle}>
        {loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "90vh",
            }}
          >
            <Spinner color="primary" size={40} />
          </Box>
        ) : (
          <>
            <Box sx={headerBoxStyle}>
              <Typography
                variant="h5"
                sx={{ color: "primary.main", fontWeight: "bold" }}
              >
                Manage Vendors
              </Typography>
              <TextField
                placeholder="Search Vendors"
                variant="outlined"
                value={searchTerm}
                onChange={handleSearch}
                InputProps={{
                  sx: searchInputStyle,
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>

            <TableContainer
              component={Paper}
              elevation={3}
              sx={tableContainerStyle}
            >
              <Table>
                <TableHead>
                  <TableRow sx={tableHeadRowStyle}>
                    <TableCell sx={tableCellStyle}>Logo</TableCell>
                    <TableCell sx={tableCellStyle}>Vendor</TableCell>
                    {!isMobile && (
                      <TableCell sx={tableCellStyle}>Email</TableCell>
                    )}
                    {!isMobile && (
                      <TableCell sx={tableCellStyle}>Phone No</TableCell>
                    )}
                    <TableCell sx={tableCellStyle}>Last Modified</TableCell>
                    <TableCell sx={lastTableCellStyle}>
                      Update Inventory
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredStores.map((store: any, index: number) => (
                    <TableRow
                      key={`${store._id}-${index}`}
                      sx={{
                        "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.04)" },
                      }}
                    >
                          <TableCell>
                          <Avatar
                            alt={store.vendor.name}
                            src={store.vendor.logo || require('../../Images/defaultshop.png')}
                            sx={{ ...avatarStyle, borderRadius: '10px' }}
                          />
                          </TableCell>
                      <TableCell sx={{ textAlign: "center" }}>
                        <Typography
                          variant="subtitle1"
                          sx={{ fontWeight: "bold" }}
                        >
                          {store.vendor.name}
                        </Typography>
                      </TableCell>
                      {!isMobile && (
                        <TableCell sx={{ textAlign: "center" }}>
                          {store.vendor.email}
                        </TableCell>
                      )}
                      {!isMobile && (
                        <TableCell sx={{ textAlign: "center" }}>
                          {store.vendor.phoneNo}
                        </TableCell>
                      )}
                      <TableCell sx={{ textAlign: "center" }}>
                        {store.updatedDate
                          ? new Date(store.updatedDate).toLocaleString()
                          : "N/A"}
                      </TableCell>
                      <TableCell sx={{ textAlign: "center" }}>
                        {(uploadLoading && selectedStoreId === store._id) ? (
                          <CircularProgress color="primary" size={24} />
                        ) : (
                          <Tooltip
                            title={`Update Inventory for ${store.vendor.name}`}
                          >
                            <IconButton
                              onClick={() =>
                                handleUpdateInventoryClick(store._id)
                              }
                              sx={iconButtonStyle}
                            >
                              <UploadIcon />
                            </IconButton>
                          </Tooltip>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}
      </Box>
      <SnackbarComponent
        open={snackbarOpen}
        message={snackbarMessage}
        severity={snackbarSeverity}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      />
    </OuterLayout>
  );
};
