import React from 'react';
import { Container, Card, CardContent, Typography, Box, Grid } from '@mui/material';
import { styled } from '@mui/system';
import customTheme from '../theme/customTheme';

interface TeamMember {
  name: string;
  designation: string;
  imageSrc: string;
}

const teamMembers: TeamMember[] = [
  {
    name: 'Chetan Shukla',
    designation: 'CEO',
    imageSrc: 'https://via.placeholder.com/300x400',
  },
  {
    name: 'Shirish Ankit',
    designation: 'CTO',
    imageSrc: 'https://via.placeholder.com/300x400',
  },
  {
    name: 'Chetan Shukla',
    designation: 'CEO',
    imageSrc: 'https://via.placeholder.com/300x400',
  },
];

export const MeetTheTeamSection: React.FC = () => {
  return (
    <Container>
      <SectionHeading variant="h5">Meet the Team</SectionHeading>
      <TeamWrapper>
        {teamMembers.map((member, index) => (
          <TeamCard key={index}>
            <CardImage src={member.imageSrc} alt={member.name} />
            <CardBottomContent>
              <Typography variant="h6" component="div" color="white">
                {member.name}
              </Typography>
              <Typography variant="body2" color="white">
                {member.designation}
              </Typography>
            </CardBottomContent>
          </TeamCard>
        ))}
      </TeamWrapper>
    </Container>
  );
};

const SectionHeading = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  marginBottom: theme.spacing(4),
  fontWeight: 700,
  marginTop: theme.spacing(4),
  color: '#FCAA4F'
}));

const TeamWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  gap: theme.spacing(4),
  flexWrap: 'wrap',
}));

const TeamCard = styled(Card)(({ theme }) => ({
  width: 300,
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '10px',
  overflow: 'hidden',
  height: '100%', 
  position: 'relative', 
}));

const CardImage = styled('img')({
  width: '100%',
  height: '80%',
  objectFit: 'cover',
  display: 'block', 
});

const CardBottomContent = styled(CardContent)(({ theme }) => ({
  backgroundColor: customTheme.palette.primary.main,
  padding: '16px',
  color: '#fff',
  height: '20%', 
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center', 
  position: 'absolute', 
  bottom: 0,
  left: 0,
  right: 0,
}));