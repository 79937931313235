import React from "react";
import { Grid, Typography, Avatar, Checkbox, Box, Grow } from "@mui/material";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined"; // A file-related icon

// Function to get avatar class based on tab
const getAvatarClass = (tabValue: number, classes: any) => {
  return classes.storyAvatar;
};

const isVideo = (avatar: string): boolean => {
  if (typeof avatar !== "string") return false;
  const lowerCaseURL = avatar.toLowerCase();
  const [urlPath] = lowerCaseURL.split('?'); 
  
  return (
    urlPath.endsWith(".mp4") ||
    urlPath.endsWith(".mkv") ||
    urlPath.endsWith(".avi") ||
    urlPath.endsWith(".mov") ||
    lowerCaseURL.includes("video")
  );
};

// Main FileGrid component
export const FileGrid: React.FC<{
  files: any[];
  checkedItems: number[];
  handleCheckboxChange: (index: number) => void;
  tabValue: number;
  classes: any;
}> = ({ files, checkedItems, handleCheckboxChange, tabValue, classes }) => {
  
  // Map the files from the props
  const mappedFiles = files.map((file, index) => ({
    srNo: index + 1,
    avatar: file.avatar, 
    views: file.views || 'N/A', 
    status: file.status || 'N/A', 
    uploadedOn: file.uploadedOn ? new Date(file.uploadedOn).toLocaleDateString('en-IN', { day: '2-digit', month: '2-digit', year: 'numeric' }) : 'N/A',  
    isUploaded: true,
    expiresIn: file.expiresIn || 'N/A',
  }));

  // Check if there are no files to display
  if (mappedFiles.length === 0) {
    return (
      <Box sx={{ textAlign: "center", mt: 5 }}>
        <Grow in timeout={1000}>
          <Box>
            <InsertDriveFileOutlinedIcon
              sx={{ fontSize: 80, color: "gray", mb: 2 }}
            />
            <Typography variant="h5" color="textSecondary">
              No data found
            </Typography>
            <Typography variant="body1" color="textSecondary">
              Try uploading a file to see it here.
            </Typography>
          </Box>
        </Grow>
      </Box>
    );
  }
  
  return (
    <>
      {mappedFiles.map((file, index) => (
        <Grid container key={index} sx={{ mb: 1, alignItems: "center" }}> 
          <Grid
            item
            xs={1}
            sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
          >
            <Checkbox
              checked={checkedItems.includes(file.srNo)}
              onChange={() => handleCheckboxChange(file.srNo)}
              disabled={!file.isUploaded} // Disable if the file is not uploaded
            />
          </Grid>
          <Grid item xs={1}>
            <Typography className={classes.field}>{index + 1}</Typography>
          </Grid>
          <Grid
            item
            xs={2}
            sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
          >
            <Avatar
              src={isVideo(file.avatar) ? require("../../../Images/videoicon.jpg") : file.avatar}
              className={`${getAvatarClass(tabValue, classes)} ${classes.circularAvatar}`}
            />
          </Grid>
          <Grid item xs={2}>
            <Typography className={classes.field}>{file.views}</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography
              className={classes.field}
              style={{
                color: file.status === "Live" ? "#3EAD56" : file.status === "Expired" ? "#E30815" : "#FFA500"
              }}
            >
              {file.status}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography className={classes.field}>{file.uploadedOn}</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography className={classes.field}>{file.expiresIn}</Typography>
          </Grid>
        </Grid>
      ))}
    </>
  );
};