// index.tsx
import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";
import { AuthErrorCodes } from "firebase/auth";
import { AuthProvider } from "./authprovider";
import { ApolloProvider } from "@apollo/client";
import client from "./apolloClient"; // Import the client from apolloClient.ts

const theme = createTheme({
  palette: {
    primary: {
      main: "#281839",
    },
    secondary: {
      main: "#dfd3ec",
    },
  },
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <AuthProvider>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <App />
        </ThemeProvider>
      </AuthProvider>
    </ApolloProvider>
  </React.StrictMode>
);
