import { gql } from '@apollo/client';

export const GET_STORE_BY_VENDOR_ID = gql`
query GetStoreByVendorId {
    getStoreByVendorId {
      _id
      name
    }
  } `;

export const GET_STORES_WITH_VENDOR = gql`
query GetStores {
  getStores {
    _id
    name
    updatedDate
    vendor {
      _id
      address
      email
      logo
      name
      createdDate
      noOfStores
      phoneNo
      role
    }
  }
}`;