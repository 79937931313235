import React, { useState } from "react";
import {
  Box,
  Button,
  useTheme,
  Radio,
  RadioGroup,
  Typography,
  Grid,
  useMediaQuery,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import CampaignIcon from "@mui/icons-material/Campaign";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import { useNavigate } from "react-router-dom";
import customTheme from "../../components/theme/customTheme";

const useStyles = makeStyles({
  root: {
    position: "relative",
    display: "flex",
    justifyContent: "center",
    background: "linear-gradient(-115.705deg, #FBE9D7 0%, #F6D5F7 96%)",
  },
  card: {
    textAlign: "center",
    padding: "18px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  header: {
    paddingBottom: "56px",
  },
  radioGroup: {
    display: "flex",
    justifyContent: "space-between",
  },
  radioBox: {
    border: "2px solid #ccc",
    borderRadius: "8px",
    padding: "16px",
    paddingBottom: "48px",
    textAlign: "left",
    position: "relative",
    cursor: "pointer",
    transition: "all 0.3s ease",
    backgroundColor: "#fff",
    "&:hover": {
      backgroundColor: "#EBFEFB",
      border: "2px solid #30A976",
      transform: "scale(1.05)",
    },
    minWidth: 350
  },
  activeRadioBox: {
    backgroundColor: "#EBFEFB",
    border: "2px solid #30A976",
  },
  icons: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  radio: {
    "& .MuiSvgIcon-root": {
      fontSize: "32px",
    },
    "&.Mui-checked .MuiSvgIcon-root": {
      fontSize: "32px",
      color: "#30A976",
    },
  },
  labelText: {
    marginTop: "32px",
  },
  bottomLeftImage: {
    position: "absolute",
    bottom: 0,
    left: 0,
    width: "250px",
    height: "auto",
  },
  bottomRightImage: {
    position: "absolute",
    bottom: 0,
    right: 0,
    width: "250px",
    height: "auto",
  },
});

export const AdvertiserVendor: React.FC = () => {
  const classes = useStyles();
  const [selectedValue, setSelectedValue] = useState<string>("");
  const navigate = useNavigate();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue(event.target.value);
  };

  const handleCreateAccountClick = () => {
    if (selectedValue === "advertise") {
      navigate("/advertiser-onboard");
    } else if (selectedValue === "vendor") {
      navigate("/vendor-onboard");
    }
  };

  return (
    <>
      <Box
        className={classes.root}
        style={{
          backgroundImage: `url(${require("../../Images/formback.jpg")})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          minHeight: `calc(100vh - ${isSmallScreen ? "56px" : "64px"})`,
        }}
      >
        <Box sx={{ display: { xs: "none", md: "block" } }}>
          <img
            src={require("../../Images/selectadv.png")}
            alt="Bottom Left"
            className={classes.bottomLeftImage}
          />
          <img
            src={require("../../Images/selectven.png")}
            alt="Bottom Right"
            className={classes.bottomRightImage}
          />
        </Box>
        <Box className={classes.card}>
          <Typography
            sx={{ color: "#181818", my: 0, fontSize: 28 }}
            className={classes.header}
          >
            Join as an <br />
            advertiser or vendor
          </Typography>
          <RadioGroup
            name="join-options"
            value={selectedValue}
            onChange={handleChange}
            className={classes.radioGroup}
          >
            <Grid container spacing={4} sx={{ justifyContent: "center" }}>
              <Grid item xs={12} sm={6}>
                <Box
                  className={`${classes.radioBox} ${
                    selectedValue === "advertise" ? classes.activeRadioBox : ""
                  }`}
                  onClick={() => setSelectedValue("advertise")}
                >
                  <Box className={classes.icons}>
                    <CampaignIcon sx={{ fontSize: 48 }} />
                    <Radio
                      checked={selectedValue === "advertise"}
                      onChange={handleChange}
                      value="advertise"
                      name="radio-buttons"
                      inputProps={{ "aria-label": "advertise" }}
                      classes={{ root: classes.radio }}
                    />
                  </Box>
                  <Box className={classes.labelText}>
                    <Typography fontSize={18}>
                      I only want to advertise <br /> on ShopPayGo
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box
                  className={`${classes.radioBox} ${
                    selectedValue === "vendor" ? classes.activeRadioBox : ""
                  }`}
                  onClick={() => setSelectedValue("vendor")}
                >
                  <Box className={classes.icons}>
                    <AddShoppingCartIcon sx={{ fontSize: 48 }} />
                    <Radio
                      checked={selectedValue === "vendor"}
                      onChange={handleChange}
                      value="vendor"
                      name="radio-buttons"
                      inputProps={{ "aria-label": "vendor" }}
                      classes={{ root: classes.radio }}
                    />
                  </Box>
                  <Box className={classes.labelText}>
                    <Typography fontSize={18}>
                      I want to enable ShopPayGo <br /> checkout system on my
                      store{" "}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </RadioGroup>
          <Box mt={4} mb={1}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              sx={{
                textTransform: "none",
                "&:disabled": {
                  backgroundColor: customTheme.palette.primary.main,
                  opacity: 0.7,
                  color: "#fff",
                },
              }}
              onClick={handleCreateAccountClick}
              disabled={!selectedValue}
            >
              Create Account
            </Button>
          </Box>
          <Typography fontSize={14}>
            Already have an account? <a href="/login">Log In</a>
          </Typography>
        </Box>
      </Box>
    </>
  );
};