import React from 'react';
import {
  Box,
  Grid,
  Typography,
  TextField,
  Button,
  Stack,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import AndroidIcon from '@mui/icons-material/Android';
import AppleIcon from '@mui/icons-material/Apple';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import XIcon from '@mui/icons-material/X';
import logo from '../../Images/logo.png';


const commonBoxStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  border: '1px solid #525354',
  borderRadius: 1,
  padding: 1,
  mb: 2,
  width: '120px',
  height: '30px',
  cursor: 'pointer',
  backgroundColor: 'white',
};

const commonGridStyles = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
};

export const Footer: React.FC = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
const isTabletPortrait = useMediaQuery(theme.breakpoints.between("sm", "md"));

  const handleNavigation = (path: string) => {
    navigate(path);
  };

  const handleEmailClick = () => {
    const email = 'internal@shoppaygo.in'; 
    const subject = 'Inquiry about ShopPayGo'; 
    const body = 'Hello,\n\nI would like to get in touch regarding...'; 
    window.location.href = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
  };

  return (
    <Box sx={{ background: '#f8f9fa', padding: 4 }}>
      <Box
        sx={{
          background: "linear-gradient(135deg, #ffffff 0%, #e0f7fa 100%)",
          padding: 6,
          borderRadius: 2,
          position: 'relative',
          paddingBottom: isMobile ? '150px' : '40px',
          boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.2)', 
        }}
      >
        <Grid
          container
          spacing={4}
          sx={{ mb: isMobile ? '32px' : '0px' }}
        >
          <Grid item xs={12} md={3} sx={commonGridStyles}>
            <Box sx={{display:'flex', alignItems:'center', flexDirection:'column'}}>
              <img
                src={logo}
                alt="Logo"
                style={{ width: 60, height: 60, marginRight: 8 }}
              />
              <Typography fontSize={20} fontWeight="bold" >
                ShopPayGo
              </Typography>
            </Box>
            <Typography
              variant="h6"
              sx={{
              textAlign: 'center',
              color: '#828282',
              fontSize: '12px',
              fontWeight: 'bold',
              fontFamily: 'emoji',
              }}
            >
              Skip Lines. Save Time.
            </Typography>
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography
              variant="h5"
              fontWeight="bold"
              gutterBottom
              sx={{ textAlign: 'center' }}
            >
              Redefining Retail <br />
              The future of shopping is now.
            </Typography>
            <Box display="flex" gap={2} sx={{ pt: 2 }}>
              <TextField
                variant="outlined"
                placeholder="internal@shoppaygo.in"
                fullWidth
                disabled
                InputProps={{
                  sx: {
                    borderRadius: '10px',
                    height: '40px',
                    backgroundColor: 'white',
                    input: {
                      '::placeholder': {
                        opacity: 1, 
                      },
                    },
                  },
                }}
              />
              <Button
                variant="contained"
                color="primary"
                sx={{
                  borderRadius: '10px',
                  height: '40px',
                  textTransform: 'none',
                  whiteSpace: 'nowrap',
                  width: '150px',
                }}
                onClick={handleEmailClick}
              >
                Contact Us
              </Button>
            </Box>
          </Grid>

          <Grid item xs={12} md={3} sx={commonGridStyles}>
            <Typography variant="body1" fontWeight="bold" mb={2}>
              Download Now
            </Typography>
            <Stack
  direction={isMobile || isTabletPortrait ? 'row' : 'column'}
  spacing={1}
              justifyContent="center"
              alignItems="center"
            >
              {['Android', 'iOS'].map((platform) => (
                <Box key={platform} sx={commonBoxStyles}>
                  {platform === 'Android' ? <AndroidIcon fontSize="small" /> : <AppleIcon fontSize="small" />}
                  <Typography ml={'5px'} fontSize="small">{platform}</Typography>
                </Box>
              ))}
            </Stack>
          </Grid>
        </Grid>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          gap={2}
          sx={{ pb: 4, pt: 3 }}
        >
          <XIcon fontSize="small" style={{ cursor: 'pointer' }} onClick={() => window.location.href = 'https://www.dockdevelopers.com'} />
          <FacebookIcon fontSize="small" style={{ cursor: 'pointer' }} onClick={() => window.location.href = 'https://www.dockdevelopers.com'} />
          <InstagramIcon fontSize="small" style={{ cursor: 'pointer' }} onClick={() => window.location.href = 'https://www.dockdevelopers.com'} />
        </Box>
        <Box
          sx={{
            borderRadius: '0px 0px 10px 10px',
            backgroundColor: 'primary.main',
            padding: 2,
            position: 'absolute',
            bottom: 0,
            width: '100%',
            transform: 'translateX(-50%)',
            left: '50%',
            ...(isMobile && {
              padding: 1,
              transform: 'translateX(0)',
              left: '0',
              width: '100%',
              position: 'absolute',
            }),
          }}
        >
          <Stack
            direction={isMobile ? 'column' : 'row'}
            spacing={isMobile ? 2 : 4}
            justifyContent="center"
            alignItems="center"
          >
            {[
              // { label: 'Features', path: '/features' },
              { label: 'About Us', path: '/about' },
              { label: 'Contact Us', path: '/contact' },
              { label: 'Privacy', path: '/privacy' },
              { label: 'Terms', path: '/terms' },
            ].map((item) => (
              <Typography
                key={item.label}
                variant="body2"
                sx={{ cursor: 'pointer', fontWeight: 500, color: 'white' }}
                onClick={() => handleNavigation(item.path)}
              >
                {item.label}
              </Typography>
            ))}
          </Stack>
        </Box>
      </Box>
      <Typography
        variant="body2"
        sx={{
          textAlign: 'center',
          color: 'gray',
          position: 'absolute',
          width: '-webkit-fill-available',
          mt: '5px',
        }}
      >
        © {new Date().getFullYear()} ShopPayGo | Version 1.0.0
      </Typography>
    </Box>
  );
};