import React from 'react';
import { Box, Card, CardContent, Grid, Typography, List, ListItem, ListItemText } from '@mui/material';

interface InfoCardProps {
  title: string;
  text?: string;
  imageSrc: string;
  titleColor?: string;
  children?: React.ReactNode;
}

const InfoCard: React.FC<InfoCardProps> = ({ title, text, imageSrc, titleColor = '#000', children }) => {
  return (
    <Card
      sx={{
        borderRadius: '16px',
        backgroundColor: '#EDEFFB',
        p: 2.5, // Reduced padding
      }}
    >
      <Typography
        variant="h5"
        component="h2"
        sx={{ fontWeight: 700, fontSize: '22px', mb: 1.5, color: titleColor, pl: 1 }}
      >
        {title}
      </Typography>
      <CardContent sx={{ p: 1 }}> {/* Reduced padding */}
        <Grid container spacing={2} alignItems="center" direction={{ xs: 'column', md: 'row' }}>
          <Grid item xs={12} md={8}>
            {text && (
              <Typography sx={{ fontWeight: 400, fontSize: '16px', mb: 1, color: '#000' }}>
                {text}
              </Typography>
            )}
            {children}
          </Grid>
          <Grid item xs={12} md={4}>
            <Box
              component="img"
              src={imageSrc}
              alt=""
              sx={{ 
                width: '100%',
                height: 'auto',
                objectFit: 'contain',
                maxHeight: '160px' // Control image height
              }}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

interface Benefit {
  text: string;
}

interface BenefitCardProps {
  title: string;
  benefits: Benefit[];
  imageSrc: string;
}

const BenefitCard: React.FC<BenefitCardProps> = ({ title, benefits, imageSrc }) => {
  return (
    <InfoCard title={title} imageSrc={imageSrc}>
      <List sx={{ p: 1.5 }}> {/* Reduced padding */}
        {benefits.map((benefit, index) => (
          <ListItem key={index} sx={{ 
            display: 'list-item', 
            listStyleType: 'disc',
            py: 0.5 // Reduced vertical padding
          }}>
            <ListItemText 
              primary={benefit.text}
              sx={{ 
                m: 0,
                '& .MuiTypography-root': { fontSize: '15px' }
              }} 
            />
          </ListItem>
        ))}
      </List>
    </InfoCard>
  );
};

interface BenefitsSectionProps {
  customerBenefits: Benefit[];
  storeBenefits: Benefit[];
}

export const Benefits: React.FC<BenefitsSectionProps> = ({ customerBenefits, storeBenefits }) => {
  return (
    <Grid container spacing={3}> {/* Reduced spacing */}
      <Grid item xs={12} md={6}>
        <BenefitCard
          title="Benefits to customers"
          benefits={customerBenefits}
          imageSrc={require('../../Images/about1.png')}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <BenefitCard
          title="Benefits to retail stores"
          benefits={storeBenefits}
          imageSrc={require('../../Images/about2.png')}
        />
      </Grid>
    </Grid>
  );
};

interface MissionSectionProps {
  missionText: string;
  journeyText: string;
}

const MissionSection: React.FC<MissionSectionProps> = ({ missionText, journeyText }) => {
  return (
    <Grid container spacing={3} sx={{ mt: 3 }}> {/* Adjusted spacing */}
      <Grid item xs={12} md={6}>
        <InfoCard
          title="Our Mission"
          text={missionText}
          imageSrc={require('../../Images/about3.png')}
          titleColor="#FCAA4F"
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <InfoCard
          title="Our Journey"
          text={journeyText}
          imageSrc={require('../../Images/about4.png')}
          titleColor="#FCAA4F"
        />
      </Grid>
    </Grid>
  );
};

export const BenefitsSection: React.FC = () => {
  const customerBenefits: Benefit[] = [
    { text: 'Reduced wait times' },
    { text: 'Convenience & Control' },
    { text: 'Enhanced privacy' },
  ];

  const storeBenefits: Benefit[] = [
    { text: 'Labor cost saving' },
    { text: 'Improved customer flow' },
    { text: 'Better data insights' },
  ];

  const missionText =
    "We aim to simplify retail for shoppers and businesses alike. From local markets in India to global retail hubs, our vision is to create a world where technology enables effortless shopping experiences.";

  const journeyText =
    "Founded with the dream of redefining retail shopping in India, ShopPayGo is now poised to take its groundbreaking solutions to the global stage. Our team is dedicated to building systems that are not only future-proof but adaptable to evolving needs.";

  return (
    <Box sx={{ my: 3 }}> {/* Reduced vertical margin */}
      <Benefits customerBenefits={customerBenefits} storeBenefits={storeBenefits} />
      <MissionSection missionText={missionText} journeyText={journeyText} />
    </Box>
  );
};