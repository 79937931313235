import React, { useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Box
} from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import customTheme from "../theme/customTheme";
import { auth } from "../../firebase";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../authprovider";

interface PopupProps {
  open: boolean;
  onClose: () => void;
}

export const EmailDialog: React.FC<PopupProps> = ({ open }) => {
  const navigate = useNavigate();
  const { setUserProfile } = useAuth();

  useEffect(() => {
    let intervalId: NodeJS.Timeout;

    if (open) {
      intervalId = setInterval(async () => {
        const user = auth.currentUser;
        if (user) {
          await user.reload();
          if (user.emailVerified) {
            clearInterval(intervalId);
            await setUserProfile(user);
            navigate("/dashboard"); 
          }
        }
      }, 3000);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [open, navigate, setUserProfile]);

  const onDialogClose = (e?: any, reason?: string) => {
    if (reason) return;
    window.location.reload();
  };

  return (
    <Dialog
      open={open}
      onClose={onDialogClose}
      PaperProps={{
        style: {
          borderRadius: 16,
          background: "#fff",
          width: "480px",
          maxWidth: "90%",
          boxShadow: "0px 10px 30px rgba(0, 0, 0, 0.1)",
        },
      }}
      disableEscapeKeyDown
    >
      <DialogTitle
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "16px 24px",
          borderBottom: "1px solid #e0e0e0",
          backgroundColor: "#f5f5f5",
        }}
      >
        <Box display="flex" alignItems="center" gap="10px">
          <EmailIcon sx={{ color: customTheme.palette.primary.main, fontSize: 28 }} />
          <Typography variant="h6" component="span" sx={{ fontWeight: "bold", color: customTheme.palette.primary.main }}>
            Email Verification
          </Typography>
        </Box>
      </DialogTitle>

      <DialogContent
        sx={{
          padding: "20px 24px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <CheckCircleOutlineIcon sx={{ color: "#4caf50", fontSize: 40, marginY: 2 }} />
        <Typography variant="body1" sx={{ marginBottom: 2, color: "#333" }}>
          We have sent a verification link to your email address.
        </Typography>
        <Typography variant="body2" sx={{ color: "#666", marginBottom: 3 }}>
          Please check your email and click on the verification link to complete the process.
          This link will allow you to verify your email address and proceed further.
        </Typography>

        <Box
          sx={{
            backgroundColor: "#f0f4ff",
            padding: "12px",
            borderRadius: "8px",
            borderLeft: `4px solid ${customTheme.palette.primary.main}`,
            marginBottom: 3,
            width: "100%",
          }}
        >
          <Typography
            variant="body2"
            sx={{
              fontStyle: "italic",
              color: "#333",
              fontWeight: 500,
            }}
          >
            *Note: The link will expire in 24 hours. Check your spam folder if you don't see the email.
          </Typography>
        </Box>

        <Typography
          variant="body2"
          sx={{
            color: "#d32f2f",
            fontWeight: "bold",
            marginTop: 2,
          }}
        >
          Important: Do not reload or leave this page. You will be redirected to login automatically once your email is verified.
        </Typography>
      </DialogContent>
    </Dialog>
  );
};
