import { gql } from '@apollo/client';

export const UPLOAD_INVENTORY = gql`
  mutation UploadInventory($csvUrl: String!, $storeId: String!) {
    uploadInventory(csvUrl: $csvUrl, storeId: $storeId) {
      success
      message
      errors
      updatedCount
      failedCount
      totalCount
    }
  }
`;
