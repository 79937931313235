import React, { useState } from "react";
import { Box, Grid, Typography, Divider, IconButton, Pagination } from "@mui/material";
import { makeStyles } from "@mui/styles";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import GetAppIcon from '@mui/icons-material/GetApp'; // Import the download icon
import { useAuth } from "../../authprovider";

const useStyles = makeStyles({
    headingContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: '16px',
    },
    subHeading: {
      fontSize: "14px",
      marginTop: "16px",
      textAlign: "center",
    },
    field: {
      fontSize: "14px",
      textAlign: "center",
    },
    divider: {
        marginTop: "8px !important", 
        marginBottom: "8px !important", 
      },
    downloadButton: {
      fontSize: "12px",
    },
    pagination: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
  });

const rowsPerPage = 5;

export const PaymentHistory: React.FC = () => {
  const classes = useStyles();
  const [page, setPage] = useState(1);
  const { currentUser } = useAuth();

  const getStartDate = () => {
    const startTimeSTamp = currentUser?.profile?.createdDate;
    return new Date(Number(startTimeSTamp)).toDateString();
  }
  const dummyData = [
    { srNo: 1, date: getStartDate(), amount: "₹0", method: "--" },
  ];

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const handleDownloadClick = () => {
    alert("Feature coming soon");
  };

  const paginatedData = dummyData.slice((page - 1) * rowsPerPage, page * rowsPerPage);

  return (
    <Box sx={{ backgroundColor: "white", color: "black", padding: "16px", borderRadius: "8px", boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", minHeight:'360px',  }}>
      <Box className={classes.headingContainer}>
        <Typography sx={{ fontWeight: 'bolder', fontSize: "18px" }}>Payment History</Typography>
        <Box className={classes.pagination}>
          <Pagination
            count={Math.ceil(dummyData.length / rowsPerPage)}
            page={page}
            onChange={handlePageChange}
            color="primary"
          />
        </Box>
      </Box>
      <Grid container sx={{ justifyContent: 'space-between' }}>
        <Grid item xs={2}>
          <Typography className={classes.subHeading}>Sr. no</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography className={classes.subHeading}>Date</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography className={classes.subHeading}>Amount</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography className={classes.subHeading}>Payment Mode</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography className={classes.subHeading}>Invoice</Typography>
        </Grid>
      </Grid>
      <Divider className={classes.divider} />
      {paginatedData.map((row, index) => (
        <Grid container key={index} sx={{ mb: 1, alignItems: "center", justifyContent: 'space-between' }}>
          <Grid item xs={2}>
            <Typography className={classes.field}>{row.srNo}</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography className={classes.field}>{row.date}</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography className={classes.field}>{row.amount}</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography className={classes.field}>{row.method}</Typography>
          </Grid>
          <Grid item xs={2} sx={{textAlign:'center'}}>
            <IconButton onClick={handleDownloadClick} className={classes.downloadButton} disabled >
              <GetAppIcon sx={{color:"#37C3E2"}} />
            </IconButton>
          </Grid>
        </Grid>
      ))}
    </Box>
  );
};