import React from 'react';
import { Paper, Typography } from '@mui/material';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { useInView } from 'react-intersection-observer';

interface Bar2Props {
  data: any[];
  heading: string;
  dataKey: string;
  labelKey: string;
}

export const Bar2: React.FC<Bar2Props> = ({ data, heading, dataKey, labelKey }) => {
  const { ref, inView } = useInView({
    triggerOnce: true, 
    threshold: 0.1, 
  });

  return (
    <Paper elevation={3} style={{ padding: '16px', marginTop: '8px', height: '100%', borderRadius: '10px' }} ref={ref}>
      <Typography sx={{ fontSize: '18px', fontWeight: 'bolder', marginBottom: '16px' }}>
        {heading}
      </Typography>
      <ResponsiveContainer width="100%" height={400}>
        <BarChart data={data} margin={{ top: 30, right: 30, left: 0, bottom: 0 }}>
        <XAxis dataKey={labelKey} axisLine={false} tickLine={false} />
        <YAxis axisLine={false} tickLine={false} />
          <Tooltip cursor={false} />
          <Legend />
          <Bar 
            dataKey={dataKey} 
            fill="#56E19E" 
            barSize={20} 
            animationBegin={0} 
            animationDuration={1500} 
            animationEasing="ease-out" 
            isAnimationActive={inView} 
          />
        </BarChart>
      </ResponsiveContainer>
    </Paper>
  );
};
