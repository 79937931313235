import React from "react";
import { Typography, Box, useTheme } from "@mui/material";

import logo from "../../Images/logo.png";

export const ComingSoon: React.FC = () => {
  const theme = useTheme();

  return (
    <Box
      style={{
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        textAlign: "center",
        // backgroundImage: `url(${require("../../Images/formback.jpg")})`,
        // backgroundSize: "cover",
        // backgroundRepeat: "no-repeat",
      }}
    >
      <img
        src={logo}
        alt="Logo"
        style={{ marginBottom: "16px", maxWidth: "120px", height: "auto" }}
      />
      <Typography
        variant="h4"
        gutterBottom
        sx={{ color: theme.palette.primary.main }}
      >
        Coming Soon!
      </Typography>
    </Box>
  );
};
