import React, { useState, useEffect } from "react";
import {
  Grid,
  Box,
  Typography,
  TextField,
  Button,
  IconButton,
  InputAdornment,
  CircularProgress,
  useMediaQuery,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../authprovider";
import theme from "../../components/theme/customTheme";
import { SnackbarComponent } from "../../components/snackbar-component";
import { ForgotPasswordDialog } from "../../components/forgot-password-dialog/internal";
import { ErrorOutline } from '@mui/icons-material';

const useInput = (initialValue: string) => {
  const [value, setValue] = useState(initialValue);
  const [error, setError] = useState(false);
  const [helperText, setHelperText] = useState("");

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setValue(value);
    setError(false);
    setHelperText("");
  };

  return { value, onChange, error, setError, helperText, setHelperText };
};

const validateEmail = (email: string) =>
  /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(email.toLowerCase());

export const LoginForm: React.FC = () => {
  const navigate = useNavigate();
  const username = useInput("");
  const password = useInput("");
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<
    "success" | "info" | "warning" | "error"
  >("info");
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { loginWithEmailAndPassword, resetPassword, currentUser, isLoggedIn } = useAuth();

  const [forgotPasswordOpen, setForgotPasswordOpen] = useState(false);

  const togglePasswordVisibility = () => setShowPassword(!showPassword);

  useEffect(() => {
    if (currentUser && isLoggedIn) {
      navigate('/dashboard');
    }
  }, [currentUser, isLoggedIn, navigate]);

  const commonTextFieldStyles = {
    "& .MuiOutlinedInput-root": {
      borderRadius: "10px",
      padding: "10px",
      height: "50px",
    },
    "& .MuiInputBase-root": { background: "white" },
    mt: 0,
    minWidth: "100%",
    "& .MuiInputBase-input:-webkit-autofill": {
      transition: "background-color 5000s ease-in-out 0s",
    },
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);

    if (!validateEmail(username.value)) {
      username.setError(true);
      username.setHelperText("Please enter a valid email address.");
      setLoading(false);
      return;
    }

    if (!username.error && username.value && password.value) {
      try {
        await loginWithEmailAndPassword(username.value, password.value);
        // setSnackbarMessage("Login successful");
        // setSnackbarSeverity("success");
        // setSnackbarOpen(true);
        setTimeout(() => {
          setLoading(false);
          navigate("/dashboard");
        }, 500);
      } catch (error: any) {
        console.error(error);
        setLoading(false);
        if (error.message.includes("Email is not verified")) {
          setSnackbarMessage("Email not verified. Please check your inbox for a verification link to complete the process!");
        } else if (error.code === "auth/wrong-password") {
          setSnackbarMessage("Oops! Wrong password. Please double-check and try again!");
        } else if (error.code === "auth/user-not-found") {
          setSnackbarMessage("User not found. Please check your details or sign up to create a new account!");
        } else {
          setSnackbarMessage("Something went wrong. Please try again later!");
        }
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      }
    } else {
      setLoading(false);
      setSnackbarMessage("Invalid input. Please double-check your email and password, then try again!");
      setSnackbarSeverity("warning");
      setSnackbarOpen(true);
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const handleForgotPasswordOpen = () => {
    setForgotPasswordOpen(true);
  };

  const handleForgotPasswordClose = () => {
    setForgotPasswordOpen(false);
  };

  const handleForgotPasswordSend = async (email: string) => {
    try {
      await resetPassword(email);
    } catch (error: any) {
      console.error(error);
      throw new Error("Error sending password reset email");
    }
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    username.onChange(event);
    if (!validateEmail(event.target.value)) {
      username.setError(true);
      username.setHelperText("Please enter a valid email address.");
    } else {
      username.setError(false);
      username.setHelperText("");
    }
  };

  return (
    <>
      <Grid
        container
        sx={{
          padding: 1,
          background: "linear-gradient(135deg, #ffffff 0%, #e0f7fa 100%)",
          height: "91vh",
        }}
        style={{
          minHeight: `calc(100vh - ${isSmallScreen ? "56px" : "64px"})`,
        }}
      >
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            p: { xs: 1, sm: 3 },
          }}
        >
          <Box sx={{ mt: 0, width: "80%" }}>
            <form onSubmit={handleSubmit}>
              <Typography
                sx={{
                  textAlign: { xs: "center", sm: "left" },
                  fontSize: "32px",
                  fontWeight: "bold",
                }}
              >
                Welcome Back
              </Typography>
              <Typography
                sx={{
                  textAlign: { xs: "center", sm: "left" },
                  fontSize: "16px",
                  mb: 2,
                }}
              >
                We're glad you're here. Let's get started on maximizing your
                reach and sales on ShopPayGo.
              </Typography>
              {snackbarMessage && (
                <Box display={'flex'} gap={'4px'}>
                  <ErrorOutline style={{ color: '#d32f2f', fontSize: "16px", marginTop: 3, }} />
                  <Typography
                    sx={{
                      textAlign: { xs: "center", sm: "left" },
                      fontSize: "16px",
                      mb: 2,
                      color: '#d32f2f'
                    }}
                  >
                    {snackbarMessage}
                  </Typography>
                </Box>
              )}
              <Typography variant="subtitle2" fontWeight="bolder">
                Email
              </Typography>
              <TextField
                margin="normal"
                required
                fullWidth
                id="username"
                name="username"
                autoComplete="username"
                autoFocus
                value={username.value}
                onChange={handleEmailChange}
                error={username.error}
                helperText={username.helperText}
                sx={commonTextFieldStyles}
              />
              <Typography
                variant="subtitle2"
                fontWeight="bolder"
                align="left"
                mt={1}
              >
                Password
              </Typography>
              <Box display="flex" flexDirection="column" margin="normal">
                <Box display="flex" alignItems="center">
                  <TextField
                    type={showPassword ? "text" : "password"}
                    value={password.value}
                    onChange={password.onChange}
                    error={password.error}
                    helperText={password.error ? "Weak password" : ""}
                    sx={commonTextFieldStyles}
                    fullWidth
                    required
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={togglePasswordVisibility}
                            edge="end"
                            sx={{ mr: 0 }}
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
              </Box>
              <Box
                textAlign="right"
                mt={1}
                sx={{ display: "flex", justifyContent: "flex-end" }}
              >
                <Typography
                  color={theme.palette.primary.main}
                  style={{ textDecoration: "underline", cursor: "pointer" }}
                  onClick={handleForgotPasswordOpen}
                  fontSize={12}
                  fontWeight={"bold"}
                >
                  Forgot password?
                </Typography>
              </Box>

              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{
                  mt: 2,
                  height: "50px",
                  borderRadius: "10px",
                  textTransform: "none",
                  fontSize: "16px",
                }}
              >
                {loading ? (
                  <CircularProgress color="inherit" size={24} />
                ) : (
                  "Log In"
                )}
              </Button>
              <Typography textAlign={"center"} mt={1} fontSize={14}>
                Don't have an account? <a href="/advertiser-vendor">Sign Up</a>
              </Typography>
            </form>
          </Box>
        </Grid>
        <Grid
          item
          xs={false}
          md={6}
          sx={{
            display: { xs: "none", md: "flex" },
            alignItems: "center",
            justifyContent: "center",
            padding: 2,
            height: "100%",
          }}
        >
          <Box
            component="img"
            src={require("../../Images/login-1.png")}
            alt="Login illustration"
            sx={{
              width: "100%",
              height: "100%",
              objectFit: "contain",
            }}
          />
        </Grid>
      </Grid>
      {/* <SnackbarComponent
        open={snackbarOpen}
        message={snackbarMessage}
        severity={snackbarSeverity}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      /> */}
      <ForgotPasswordDialog
        open={forgotPasswordOpen}
        onClose={handleForgotPasswordClose}
        onSend={handleForgotPasswordSend}
      />
    </>
  );
};