import {Box, Fab,} from "@mui/material";
import { To, useNavigate } from "react-router-dom";
import SmartphoneIcon from "@mui/icons-material/Smartphone";
import QRCodeIcon from "@mui/icons-material/QrCode";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { HeroSection } from "../../components/home/HeroSection";
import { KeyFeatures } from "../../components/home/KeyFeatures";
import { FAQs } from "../../components/home/FAQs";
import { Ecosystem } from "../../components/home/Ecosystem";
import { CTASection } from "../../components/home/CTASection";
import { UserFlow } from "../../components/home/UserFlow";
import { useAuth } from "../../authprovider";
import { WhyOnboard } from "../../components/home/WhyOnboard";

const features = [
  {
    title: "Queueless Checkout",
    description: "Skip the line and enjoy a hassle-free shopping experience.",
    icon: <ShoppingCartIcon fontSize="large" />,
  },
  {
    title: "Fast Payments",
    description: "Complete your purchase in seconds with secure transactions.",
    icon: <SmartphoneIcon fontSize="large" />,
  },
  {
    title: "Privacy First",
    description: "Your data is safe with us. Shop with peace of mind.",
    icon: <QRCodeIcon fontSize="large" />,
  },
];


const faqs = [
  {
    question: "How does ShopPayGo work?",
    answer: "Simply scan the QR code at checkout, add items to your cart, and pay instantly.",
  },
  {
    question: "How do I add items to my cart?",
    answer: "Browse the items and click on the 'Add to Cart' button to add them to your cart.",
  },
  {
    question: "How do I make a payment?",
    answer: "Proceed to checkout and follow the instructions to complete your payment.",
  },
  {
    question: "Is my payment information secure?",
    answer: "Absolutely! We prioritize your security with encrypted transactions.",
  },
  {
    question: "Can I use ShopPayGo at any store?",
    answer: "Yes, ShopPayGo partners with various retailers for a seamless shopping experience.",
  },
  {
    question: "Are there any fees for using ShopPayGo?",
    answer: "No, ShopPayGo is completely free to use for all customers.",
  },
];

export const Home = () => {
  const navigate = useNavigate();
  const { currentUser } = useAuth();

  const handleNavigation = (path: To) => {
    navigate(path);
  };

  return (
    <Box sx={{ backgroundColor: "#f8f9fa", minHeight: "100vh" }}>
      <HeroSection handleNavigation={handleNavigation} />
      <KeyFeatures features={features} />
      <UserFlow />
      <Ecosystem />
      <WhyOnboard />
      <CTASection />
      <FAQs faqs={faqs} />
      <Fab
        variant="extended"
        sx={{
          backgroundColor: '#FCAA4F',
          color: 'white',
          fontSize: '16px',
          fontWeight: '500',
          textTransform: 'none',
          px: 3,
          py: 2,
          position: 'fixed',
          borderRadius: '10px',
          bottom: 10,
          right: 10,
          zIndex: 1000, 
          '&:hover': {
            backgroundColor: '#FFA500', 
            color: '#fff',
          },
        }}
        onClick={() => handleNavigation(currentUser ? 'advertiser' : '/advertiser-onboard')}
      >
        Start Advertising
      </Fab>
    </Box>
  );
};
