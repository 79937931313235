import React, { createContext, useState, useEffect, ReactNode, useCallback } from 'react';
import { ref, getDownloadURL, uploadBytes } from 'firebase/storage';
import { useLazyQuery } from '@apollo/client';
import { GET_BANNERS_BY_VENDOR_ID, GET_STORIES_BY_VENDOR_ID, GET_TILES_BY_VENDOR_ID } from '../gql/advertisement';
import { firebaseStorage } from '../firebase';
import { useAuth } from '../authprovider';

interface FileContextProps {
  stories: any[];
  banners: any[];
  tiles: any[];
  handleDrop: (tabValue: number, acceptedFiles: File[]) => Promise<any>;
  getType: (tabValue: number) => string;
  errorMessage: string | null;
  fetchAllData: () => Promise<void>;
  fetchBannersData: () => Promise<void>;
  fetchStoriesData: () => Promise<void>;
  fetchTilesData: () => Promise<void>;
}

const defaultValue: FileContextProps = {
  stories: [],
  banners: [],
  tiles: [],
  handleDrop: async () => {},
  getType: () => '',
  errorMessage: null,
  fetchAllData: async () => {},
  fetchBannersData: async () => {},
  fetchStoriesData: async () => {},
  fetchTilesData: async () => {},
};

export const FileContext = createContext<FileContextProps>(defaultValue);

export const FileProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [stories, setStories] = useState<any[]>([]);
  const [banners, setBanners] = useState<any[]>([]);
  const [tiles, setTiles] = useState<any[]>([]);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const { currentUser } = useAuth(); // Include currentUser

  const [fetchBanners, { data: bannersData }] = useLazyQuery(GET_BANNERS_BY_VENDOR_ID, { fetchPolicy: 'no-cache' });
  const [fetchStories, { data: storiesData }] = useLazyQuery(GET_STORIES_BY_VENDOR_ID, { fetchPolicy: 'no-cache' });
  const [fetchTiles, { data: tilesData }] = useLazyQuery(GET_TILES_BY_VENDOR_ID, { fetchPolicy: 'no-cache' });

  // Individual fetch functions
  const fetchBannersData = useCallback(async () => {
    try {
      await fetchBanners(); // No variables needed
    } catch (error) {
      console.error("Error fetching banners:", error);
    }
  }, [fetchBanners]);

  const fetchStoriesData = useCallback(async () => {
    try {
      await fetchStories(); // No variables needed
    } catch (error) {
      console.error("Error fetching stories:", error);
    }
  }, [fetchStories]);

  const fetchTilesData = useCallback(async () => {
    try {
      await fetchTiles(); // No variables needed
    } catch (error) {
      console.error("Error fetching tiles:", error);
    }
  }, [fetchTiles]);

  // Existing fetchAllData function
  const fetchAllData = useCallback(async () => {
    await Promise.all([
      fetchBannersData(),
      fetchStoriesData(),
      fetchTilesData(),
    ]);
  }, [fetchBannersData, fetchStoriesData, fetchTilesData]);

  // Fetch all data when currentUser changes
  useEffect(() => {
    fetchAllData();
  }, [fetchAllData, currentUser]); // Add currentUser to dependencies

  // Update state when data changes
  useEffect(() => {
    if (bannersData) setBanners(bannersData.getBannersByVendorId);
  }, [bannersData]);

  useEffect(() => {
    if (storiesData) setStories(storiesData.getStoriesByVendorId);
  }, [storiesData]);

  useEffect(() => {
    if (tilesData) setTiles(tilesData.getTilesByVendorId);
  }, [tilesData]);

  useEffect(() => {
    if (tilesData) setTiles(tilesData.getTilesByVendorId);
  }, [tilesData]);

  const getType = (tabValue: number): string => {
    return ['STORY', 'BANNER', 'TILE'][tabValue] || '';
  };

  const handleDrop = async (tabValue: number, acceptedFiles: File[]) => {
    const folder = getType(tabValue);
    const uploadPromises = acceptedFiles.map(async (file) => {
      const storageRef = ref(firebaseStorage, `${folder}/${file.name}`);
      await uploadBytes(storageRef, file);
      const downloadURL = await getDownloadURL(storageRef);
      const uploadedOn = new Date().toISOString();
      return { url: downloadURL, uploadedOn };
    });

    try {
      const uploadedFiles = await Promise.all(uploadPromises);
      setErrorMessage(null);
      return uploadedFiles;
    } catch (error) {
      setErrorMessage("Error uploading files");
      console.error("Error uploading files:", error);
      return [];
    }
  };

  return (
    <FileContext.Provider
      value={{
        stories,
        banners,
        tiles,
        handleDrop,
        getType,
        errorMessage,
        fetchAllData,
        fetchBannersData,
        fetchStoriesData,
        fetchTilesData,
      }}
    >
      {children}
      {errorMessage && <div className="error-message">{errorMessage}</div>}
    </FileContext.Provider>
  );
};
