import React from "react";
import FormContent from "../../components/advertiser-form/FormContent";
import { Onboard } from "../../components/onboard/internal";
import { FormValues } from "../../components/vendor-form/types";

const advertiserSteps = ["Basic Info", "Contact Info", "Additional Info",];

const advertiserDefaultValues: FormValues = {
  logo: "",
  brandName: "",
  brandEmail: "",
  password: "",
  confirmPassword: "",
  gstin: "",
  streetAddress: "",
  city: "",
  state: "",
  postalCode: "",
  phoneNumber: "",
  numberOfStores: "",
  ifscCode: "",
  upi: "",
  confirmAccountNumber: "",
  bankName: "",
  inboundApi: "",
  outboundApi: "",
  // apiKeyToken: "",
};

export const AdvertiserOnboard: React.FC = () => {
  return (
    <>
      
      <Onboard
        steps={advertiserSteps}
        defaultValues={advertiserDefaultValues}
        FormContentComponent={FormContent}
        type={'Advertiser'}
      />
    </>
  );
};
