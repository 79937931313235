import React, { useState, useContext, useCallback } from "react";
import {
  Box,
  Grid,
  Typography,
  Divider,
  Tab,
  Tabs,
  Chip,
  IconButton,
  Tooltip,
  Slide,
  useMediaQuery,
  Snackbar,
  Button,
  Checkbox,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import { ConfirmDialog } from "../../confirm-dialog";
import { FileGrid } from "./FileGrid";
import { FileUpload } from "./FileUpload";
import { makeStyles } from "@mui/styles";
import { FileContext } from "../../../context/FileContext";
import { useAuth } from "../../../authprovider";
import { useMutation } from "@apollo/client";
import { CREATE_ADVERTISEMENT, DELETE_ADVERTISEMENTS } from "../../../gql/advertisement";
import customTheme from "../../theme/customTheme";
import { alpha } from "@mui/material/styles";
import { CustomGrowOnHover } from "../../CustomGrowOnHover";
import SmallButton from "../../../styles/SmallButton";

const useStyles = makeStyles({
  box: {
    backgroundColor: "white",
    color: "black",
    borderRadius: "10px",
    minHeight: "100%",
  },
  header: {
    backgroundColor: "#281839",
    color: "#fff",
    padding: "16px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    height: "50px",
    borderRadius: "10px",
  },
  tab: {
    width: "70px",
    "&.Mui-selected": {
      color: "#FCAA4F",
      padding: 0,
    },
    "@media (max-width: 600px)": {
      padding: "6px 12px",
      fontSize: "12px",
      minWidth: 0,
    },
  },
  icon: {
    color: "red !important",
    marginLeft: "8px",
  },
  subHeading: {
    fontSize: "14px !important",
    marginTop: "8px !important",
    textAlign: "center",
  },
  field: {
    fontSize: "14px !important",
    textAlign: "center",
  },
  avatar: {
    width: "50px",
    height: "50px",
  },
  bannerAvatar: {
    width: "100px",
    height: "50px",
    borderRadius: "8px",
  },
  tileAvatar: {
    width: "120px",
    height: "160px",
    borderRadius: "8px",
  },
  divider: {
    margin: "16px 0 !important",
  },
});

export const Resource: React.FC = () => {
  const { currentUser } = useAuth();
  const isAdmin = currentUser?.isAdmin || false;
  const classes = useStyles();
  const isMobile = useMediaQuery("(max-width:600px)");
  const [tabValue, setTabValue] = useState(0);
  const [open, setOpen] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [checkedItems, setCheckedItems] = useState<number[]>([]);
  const [snackbar, setSnackbar] = useState({ open: false, message: "", color: "green" });
  const { stories, banners, tiles, fetchBannersData, fetchStoriesData, fetchTilesData, handleDrop, getType } =
    useContext(FileContext);

  const [createAdvertisement] = useMutation(CREATE_ADVERTISEMENT);
  const [deleteAdvertisements] = useMutation(DELETE_ADVERTISEMENTS);

  const getCurrentFiles = useCallback(() => {
    if (tabValue === 0) return stories;
    if (tabValue === 1) return banners;
    return tiles;
  }, [tabValue, stories, banners, tiles]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
    setCheckedItems([]);
  };

  const addNewResource = () => setOpen(true);

  const handleFileUpload = async (acceptedFiles: File[]) => {
    const type = getType(tabValue);
    const existingFiles = getCurrentFiles();
    const liveFileCount = existingFiles.filter((file) => file.status === "Live").length;

    if (!isAdmin && liveFileCount >= 3) {
      setSnackbar({ open: true, message: "You cannot upload more than 3 live files in free tier. Upgrade to upload more.", color: "red" });
      setOpen(false);
      return;
    }

    const fileObjs = await handleDrop(tabValue, acceptedFiles);

    if (!isAdmin && liveFileCount + fileObjs.length > 3) {
      setSnackbar({ open: true, message: "You cannot upload more than 3 live files in free tier. Upgrade to upload more.", color: "red" });
      setOpen(false);
      return;
    }

    if (fileObjs.length > 0) {
      const inputs = fileObjs.map((fileObj: { url: string }) => ({
        type,
        url: fileObj.url,
      }));

      try {
        await createAdvertisement({ variables: { inputs } });
        await fetchData(type);
        setSnackbar({ open: true, message: "Advertisements created successfully", color: "green" });
      } catch (error) {
        console.error("Error creating advertisements:", error);
        setSnackbar({ open: true, message: "Error creating advertisements. Please try again.", color: "red" });
      }
    }

    setOpen(false);
  };

  const fetchData = async (type: string) => {
    if (type === "STORY") await fetchStoriesData();
    else if (type === "BANNER") await fetchBannersData();
    else if (type === "TILE") await fetchTilesData();
  };

  const handleDeleteConfirm = async () => {
    const files = getCurrentFiles();
    const idsToDelete = checkedItems.map((index) => files[index - 1]._id);
    const type = getType(tabValue);

    try {
      await deleteAdvertisements({ variables: { ids: idsToDelete } });
      await fetchData(type);
      setOpenConfirm(false);
      setCheckedItems([]);
      setSnackbar({ open: true, message: "Files deleted successfully", color: "green" });
    } catch (error) {
      console.error("Error deleting files:", error);
      setSnackbar({ open: true, message: "Error deleting files. Please try again.", color: "red" });
    }
  };

  const handleCheckboxChange = (srNo: number) => {
    setCheckedItems((prevCheckedItems) =>
      prevCheckedItems.includes(srNo) ? prevCheckedItems.filter((item) => item !== srNo) : [...prevCheckedItems, srNo]
    );
  };

  const handleClose = () => setOpen(false);

  const liveItemCount = getCurrentFiles().filter((file) => file.status === "Live").length;
  const isUploadDisabled = !isAdmin && liveItemCount >= 3;

  return (
    <Box className={classes.box}>
      <Box className={classes.header}>
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          textColor="inherit"
          sx={{ "& .MuiTabs-indicator": { backgroundColor: "#FCAA4F" } }}
        >
          <Tab label="Stories" className={classes.tab} />
          <Tab label="Banners" className={classes.tab} />
          <Tab label="Tiles" className={classes.tab} />
        </Tabs>
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <Tooltip
            title={
              isUploadDisabled ? (
                <Typography variant="body2" sx={{ color: "#FFFFF0", textAlign: "center", p: 1 }}>
                  "You can only upload up to 3 files. Upgrade to upload more."
                </Typography>
              ) : (
                ""
              )
            }
            placement="bottom"
            arrow
            componentsProps={{
              tooltip: {
                sx: {
                  backgroundColor: alpha(customTheme.palette.primary.main, 0.8),
                  borderRadius: "8px",
                  boxShadow: "0px 4px 4px 2px rgba(0, 0, 0, 0.25)",
                },
              },
              arrow: { sx: { color: alpha(customTheme.palette.primary.main, 0.8) } },
            }}
          >
            <span>
              {isMobile ? (
                <CustomGrowOnHover>
                  <IconButton onClick={addNewResource} disabled={isUploadDisabled}>
                    <AddPhotoAlternateIcon sx={{ color: "#37CE1F" }} />
                  </IconButton>
                </CustomGrowOnHover>
              ) : (
                <CustomGrowOnHover>
                  <SmallButton
                    onClick={addNewResource}
                    style={{ backgroundColor: "#37CE1F" }}
                    disabled={isUploadDisabled}
                  >
                    {isUploadDisabled ? "Upgrade" : "Add New"}
                  </SmallButton>
                </CustomGrowOnHover>
              )}
            </span>
          </Tooltip>
          <CustomGrowOnHover>
            <IconButton
              className={classes.icon}
              disabled={checkedItems.length === 0}
              onClick={() => setOpenConfirm(true)}
              sx={{ "&.Mui-disabled": { color: "grey !important" } }}
            >
              <DeleteIcon />
            </IconButton>
          </CustomGrowOnHover>
        </Box>
      </Box>
      <Grid container display={"flex"} alignItems={"center"}>
        <Grid item xs={1}>
          <Box sx={{ display: 'flex', justifyContent: 'center', pt: 1 }}>
            <Checkbox
              checked={getCurrentFiles().length > 0 && checkedItems.length === getCurrentFiles().length}
              indeterminate={checkedItems.length > 0 && checkedItems.length < getCurrentFiles().length}
              onChange={() => {
                if (checkedItems.length === getCurrentFiles().length) {
                  setCheckedItems([]);
                } else {
                  setCheckedItems(getCurrentFiles().map((_, index) => index + 1));
                }
              }}
              disabled={getCurrentFiles().length === 0}
            />
          </Box>
        </Grid>
        <Grid item xs={1}>
          <Typography className={classes.subHeading}>Sr. no</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography className={classes.subHeading}>Avatar</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography className={classes.subHeading}>Views</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography className={classes.subHeading}>Status</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography className={classes.subHeading}>Uploaded on</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography className={classes.subHeading}>Expires In</Typography>
        </Grid>
      </Grid>
      <Divider className={classes.divider} />
      <Slide direction="up" in={true} timeout={800} key={tabValue}>
        <div>
          <FileGrid
            files={getCurrentFiles()}
            checkedItems={checkedItems}
            handleCheckboxChange={handleCheckboxChange}
            tabValue={tabValue}
            classes={classes}
          />
        </div>
      </Slide>
      <FileUpload open={open} handleClose={handleClose} handleOk={handleFileUpload} tabValue={tabValue} />
      <ConfirmDialog
        open={openConfirm}
        onClose={() => setOpenConfirm(false)}
        onConfirm={handleDeleteConfirm}
        message={"Do you want to delete selected file?"}
        title="Delete Selection"
        icon={<DeleteIcon sx={{fontSize:"32px"}} />}
      />
      <Snackbar
        open={snackbar.open}
        autoHideDuration={4000}
        onClose={() => setSnackbar((prev) => ({ ...prev, open: false }))}
        message={snackbar.message}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        ContentProps={{ sx: { backgroundColor: snackbar.color, color: "#fff" } }}
        sx={{height: '100%'}}
      />
    </Box>
  );
};