import { gql } from '@apollo/client';

export const GET_INSIGHTS = gql`
query GetInsights($storeId: ID!, $status: InsightStatus) {
    getInsights(storeID: $storeId, status: $status) {
      itemsSold
      totalSale
      averageTransaction
      averageQuantity
      noOfTransactions
      peakHours
    }
  }` ;

