import React from "react";
import { Button } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  smallButton: {
    padding: '4px 8px',
    fontSize: '12px',
    color: 'white',
    fontWeight: 'bold',
    borderRadius: '8px',
    textTransform: 'none',
  },
});

interface SmallButtonProps {
  onClick: () => void;
  style?: React.CSSProperties;
  children: React.ReactNode;
  disabled?: boolean;
}

const SmallButton: React.FC<SmallButtonProps> = ({ onClick, style, children, disabled }) => {
  const classes = useStyles();
  return (
    <Button
      variant="contained"
      className={classes.smallButton}
      style={style}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </Button>
  );
};

export default SmallButton;