import React from 'react';
import { Box, Typography, Button, Container, Grid } from '@mui/material';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

export const PageNotFound = () => {
  return (
    <Container maxWidth="lg">
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        minHeight="100vh"
      >
        <Grid item xs={12} md={6}>
          <Box display="flex" justifyContent="center" alignItems="center">
            <motion.div
              initial={{ opacity: 0, x: -50 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.8, ease: 'easeInOut' }}
            >
              <motion.img
                src={require('../../Images/404.png')}
                alt="Descriptive Alt Text"
                style={{ maxWidth: '80%', height: 'auto', marginBottom: 24 }}
                animate={{
                  x: [0, -5, 5, -3, 3, 0],
                }}
                transition={{
                  duration: 0.6,
                  repeat: Infinity,
                  repeatType: 'loop',
                }}
              />
            </motion.div>
          </Box>
        </Grid>

        <Grid item xs={12} md={6}>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            textAlign="center"
            sx={{ px: { xs: 2, md: 4 } }}
          >
            <motion.div
              initial={{ opacity: 0, y: -50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8, ease: 'easeInOut' }}
            >
              <Typography fontSize={32} fontWeight={700} color="#FCAA4F">
                Oops! Wrong Turn
              </Typography>
            </motion.div>

            <motion.div
              initial={{ x: -200, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              transition={{ duration: 0.8, ease: 'easeInOut', delay: 0.3 }}
            >
              <Typography variant="h5" color="#110904" sx={{ mb: 4 }}>
                The page you're looking for doesn't exist.
              </Typography>
            </motion.div>

            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 1 }}
            >
              <Link to="/" style={{ textDecoration: 'none' }}>
                <Button
                  variant="contained"
                  color="primary"
                  size="medium"
                  component={motion.button}
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.95 }}
                  sx={{
                    textTransform: 'none',
                    px: 4,
                    py: 2,
                    fontSize: '1rem',
                  }}
                >
                  Take Me Home
                </Button>
              </Link>
            </motion.div>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};
