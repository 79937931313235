import React from "react";
import {
  Box,
  Grid,
  Typography,
  Container,
  Button,
} from "@mui/material";
import SmartphoneIcon from "@mui/icons-material/Smartphone";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import QRCodeIcon from "@mui/icons-material/QrCode";
import { To } from "react-router-dom";
import { useAuth } from '../../authprovider';

interface HeroSectionProps {
  handleNavigation: (path: To) => void;
}

export const HeroSection: React.FC<HeroSectionProps> = ({ handleNavigation }) => {
  const { currentUser } = useAuth();
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: { xs: "2rem", md: "4rem" },
        background: "linear-gradient(135deg, #ffffff 0%, #e0f7fa 100%)",
        height: "100vh",
        position: "relative",
        overflow: "hidden",
      }}
    >
      <SmartphoneIcon
        sx={{
          position: "absolute",
          top: "10%",
          left: "5%",
          fontSize: 60,
          color: "#FFB74D",
          animation: "float 3s ease-in-out infinite",
          display: { xs: "none", md: "block" },
        }}
      />
      <ShoppingCartIcon
        sx={{
          position: "absolute",
          bottom: "20%",
          left: "4%",
          fontSize: 80,
          color: "#4CAF50",
          animation: "float 4s ease-in-out infinite",
          display: { xs: "none", md: "block" },
        }}
      />
      <QRCodeIcon
        sx={{
          position: "absolute",
          top: "15%",
          right: "5%",
          fontSize: 70,
          color: "#29B6F6",
          animation: "float 2.5s ease-in-out infinite",
          display: { xs: "none", md: "block" },
        }}
      />
      <Container maxWidth="lg" sx={{ zIndex: 2 }}>
        <Grid container spacing={4} alignItems="center">
          {/* Left Content */}
          <Grid item xs={12} md={6}>
            <Typography
              variant="h2"
              sx={{ fontWeight: 700, mb: 2, color: "primary.main", fontSize: { xs: "2rem", md: "3rem" } }}
            >
              Seamless Shopping Experience
            </Typography>
            <Typography variant="body1" sx={{ mb: 4, color: "#5a5a5a", fontSize: { xs: "1rem", md: "1.25rem" } }}>
              ShopPayGo is redefining retail with a revolutionary shopping
              journey. No queues. No hassle. Just scan, pay, and go. Enjoy fast,
              secure, and private shopping at your fingertips.
            </Typography>

            <Box sx={{ display: "flex", gap: 2, flexDirection: { xs: "column", sm: "row" } }}>
              <Button
                variant="contained"
                color="primary"
                sx={{ borderRadius: 2, px: 4, py: 1 }}
                onClick={() => handleNavigation(currentUser ? "/dashboard" : "/advertiser-vendor")}
              >
                Get Started
              </Button>
              <Button
                variant="outlined"
                color="primary"
                sx={{ borderRadius: 2, px: 4, py: 1, backgroundColor: "white" }}
                onClick={() => handleNavigation("/about")}
              >
                Learn More
              </Button>
            </Box>
          </Grid>

          <Grid item xs={12} md={6} sx={{ position: "relative", mt: { xs: 4, md: 0 } }}>
            <Box
              sx={{
                position: "absolute",
                top: 16,
                left: 16,
                background: "#FFEB3B",
                borderRadius: "8px",
                padding: "4px 8px",
                fontWeight: 600,
                color: "#37474F",
                fontSize: { xs: "0.75rem", md: "1rem" },
              }}
            >
              Try ShopPayGo!
            </Box>
            <img
              src={require("../../Images/hero.png")}
              alt="QR Code and Smartphone Illustration"
              style={{
                width: "100%",
                height: "auto",
                borderRadius: 16,
                boxShadow: "0px 8px 24px rgba(0, 0, 0, 0.1)",
                transition: "transform 0.3s ease",
              }}
            />
            <Typography
              variant="caption"
              sx={{ mt: 1, display: "block", textAlign: "center", color: "#5a5a5a", fontSize: { xs: "0.75rem", md: "1rem" } }}
            >
              Explore our queueless checkout in action!
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <style>
        {`
          @keyframes float {
            0% { transform: translateY(0); }
            50% { transform: translateY(-10px); }
            100% { transform: translateY(0); }
          }
        `}
      </style>
    </Box>
  );
};