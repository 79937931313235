import React, { useEffect, useRef } from 'react';
import { Box, Typography, Grid, Container } from '@mui/material';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import QRCodeIcon from '@mui/icons-material/QrCode';
import PaymentIcon from '@mui/icons-material/Payment';
import DoneAllIcon from '@mui/icons-material/DoneAll';

export const UserFlow: React.FC = () => {
  const steps = [
    {
      title: 'Step 1: Open the App',
      description: 'Open the ShopPayGo app as soon as you enter the store. No need to download any additional store-specific apps!',
      icon: <QRCodeIcon fontSize="large" sx={{ color: "#29B6F6" }} />,
    },
    {
      title: 'Step 2: Find Your Store',
      description: 'Our app will automatically detect your location and provide a list of nearby stores. Simply select the store you’re in to start shopping.',
      icon: <ShoppingCartIcon fontSize="large" sx={{ color: "#4CAF50" }} />,
    },
    {
      title: 'Step 3: Scan Products',
      description: 'Use your phone’s camera to scan product barcodes. As you scan, items will automatically be added to your digital shopping cart.',
      icon: <QRCodeIcon fontSize="large" sx={{ color: "#FFA500" }} />,
    },
    {
      title: 'Step 4: Complete Payment',
      description: 'When you’re ready to checkout, simply pay within the app using your preferred payment method. No queues, no waiting!',
      icon: <PaymentIcon fontSize="large" sx={{ color: "#F44336" }} />,
    },
    {
      title: 'Step 5: Exit the Store',
      description: "After payment, a QR code is generated. Show it to the security guard, who will scan and approve your checkout. Once approved, you're free to leave.",
      icon: <DoneAllIcon fontSize="large" sx={{ color: "#2E7D32" }} />,
    },
  ];

  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('animate');
          }
        });
      },
      { threshold: 0.1 }
    );

    const container = containerRef.current;
    if (container) {
      const elements = container.querySelectorAll('.step');
      elements.forEach((el) => observer.observe(el));
    }

    return () => {
      if (container) {
        const elements = container.querySelectorAll('.step');
        elements.forEach((el) => observer.unobserve(el));
      }
    };
  }, []);

  return (
    <Box
      ref={containerRef}
      sx={{
        backgroundColor: '#f8f9fa',
        py: 8,
        position: 'relative',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      {/* Background Floating Icons */}
      <Box
        sx={{
          position: 'absolute',
          top: '-20%',
          right: '-10%',
          width: '500px',
          height: '500px',
          background: 'radial-gradient(circle at top right, #f8bbd0, transparent)',
          borderRadius: '50%',
          zIndex: 0,
        }}
      />
      <Box
        sx={{
          position: 'absolute',
          bottom: '-20%',
          left: '-10%',
          width: '400px',
          height: '400px',
          background: 'radial-gradient(circle at bottom left, #e1f5fe, transparent)',
          borderRadius: '50%',
          zIndex: 0,
        }}
      />

      <Container maxWidth="lg" sx={{ zIndex: 2 }}>
        {/* Title */}
        <Typography variant="h4" align="center" sx={{ fontWeight: 700, mb: 6, color: "#2E7D32" }}>
          How ShopPayGo Works
        </Typography>

        {/* Animated Steps */}
        <Grid container spacing={8} justifyContent="center">
          {steps.map((step, index) => (
            <Grid
              key={index}
              item
              xs={12}
              md={4}
              className="step"
              sx={{
                textAlign: 'center',
                position: 'relative',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                opacity: 0,
                filter: 'blur(10px)',
                transition: 'opacity 1s ease-in, filter 1s ease-in',
                transitionDelay: `${index * 0.5}s`,
              }}
            >
              <Box
                sx={{
                  position: 'relative',
                  mb: 4,
                }}
              >
                <Box
                  sx={{
                    backgroundColor: '#ffffff',
                    borderRadius: '50%',
                    boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.1)',
                    p: 4,
                    zIndex: 2,
                    position: 'relative',
                  }}
                >
                  {step.icon}
                </Box>
                <Box
                  sx={{
                    position: 'absolute',
                    top: 0,
                    width: '100%',
                    height: '100%',
                    borderRadius: '50%',
                    background: 'linear-gradient(45deg, #FFEB3B, #29B6F6, #FF7043, #4CAF50)',
                    zIndex: 1,
                    animation: `ripple 2s infinite ease-in-out ${index * 2}s`,
                  }}
                />
              </Box>
              <Typography variant="h5" sx={{ fontWeight: 600, mb: 2 }}>
                {step.title}
              </Typography>
              <Typography variant="body1" sx={{ color: '#5a5a5a', mb: 6, maxWidth: '300px' }}>
                {step.description}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </Container>

      {/* Keyframe animations */}
      <style>
        {`
          @keyframes ripple {
            0% {
              transform: scale(0.5);
              opacity: 1;
            }
            100% {
              transform: scale(2.5);
              opacity: 0;
            }
          }

          .step.animate {
            opacity: 1 !important;
            filter: blur(0) !important;
          }
        `}
      </style>
    </Box>
  );
};