import React, { createContext, useState, useEffect, useCallback, ReactNode } from 'react';
import { useLazyQuery } from '@apollo/client';
import { GET_STORE_BY_VENDOR_ID } from '../gql/store';
import { useAuth } from '../authprovider';

interface Store {
  _id: string;
  name: string;
}

interface StoreContextProps {
  stores: Store[];
  selectedStore: string;
  setSelectedStore: (storeId: string) => void;
  storesLoading: boolean;
  storesError: any;
  fetchStoresData: () => Promise<void>;
}

const defaultValue: StoreContextProps = {
  stores: [],
  selectedStore: '',
  setSelectedStore: () => {},
  storesLoading: false,
  storesError: null,
  fetchStoresData: async () => {},
};

export const StoreContext = createContext<StoreContextProps>(defaultValue);

export const StoreProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [stores, setStores] = useState<Store[]>([]);
  const [selectedStore, setSelectedStore] = useState<string>('');
  const { currentUser } = useAuth();

  const [fetchStores, { loading: storesLoading, error: storesError, data: storesData }] = useLazyQuery(GET_STORE_BY_VENDOR_ID, { fetchPolicy: 'no-cache' });

  const fetchStoresData = useCallback(async () => {
    const vendorId = currentUser?.profile?._id;
    if (vendorId) {
      try {
        await fetchStores({ variables: { vendorId } });
      } catch (error) {
        console.error("Error fetching stores:", error);
      }
    }
  }, [currentUser, fetchStores]);

  useEffect(() => {
    fetchStoresData();
  }, [fetchStoresData]);

  useEffect(() => {
    if (storesData && storesData.getStoreByVendorId) {
      setStores(storesData.getStoreByVendorId);
      if (storesData.getStoreByVendorId.length > 0 && !selectedStore) {
        setSelectedStore(storesData.getStoreByVendorId[0]._id);
      }
    }
  }, [storesData, selectedStore]);

  return (
    <StoreContext.Provider
      value={{
        stores,
        selectedStore,
        setSelectedStore,
        storesLoading,
        storesError,
        fetchStoresData,
      }}
    >
      {children}
    </StoreContext.Provider>
  );
};