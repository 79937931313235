import { gql } from '@apollo/client';

//MUTATION
export const CREATE_ADVERTISEMENT = gql`
mutation CreateAdvertisement($inputs: [AdvertisementInput!]!) {
  createAdvertisement(inputs: $inputs) {
    _id
    type
    url
    duration {
      start
      end
    }
  }
}
` ;

export const GET_BANNERS_BY_VENDOR_ID = gql`
query GetBannersByVendorId {
  getBannersByVendorId {
    _id
    avatar
    views
    status
    uploadedOn
    expiresIn
  }
}
`;

export const GET_TILES_BY_VENDOR_ID = gql`
query GetTilesByVendorId {
    getTilesByVendorId{
    _id
    avatar
    views
    status
    uploadedOn
    expiresIn
    }
  }`;

export const GET_STORIES_BY_VENDOR_ID = gql`
query GetStoriesByVendorId {
  getStoriesByVendorId{
    _id
    avatar
    views
    status
    uploadedOn
    expiresIn
  }
}`;

export const DELETE_ADVERTISEMENTS = gql`
mutation DeleteAdvertisements($ids: [ID!]!) {
  deleteAdvertisements(ids: $ids) {
    success
    message
  }
}`;