import React, { useState } from 'react';
import { AppBar, Toolbar, Typography, Button, Box, IconButton, Drawer, List, ListItem } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../authprovider';
import customTheme from '../theme/customTheme';
import { useMediaQuery, useTheme } from '@mui/system';
import { Info, Phone, Login, PersonAddAlt, Menu, Logout, Dashboard } from '@mui/icons-material';
import { ConfirmDialog } from '../confirm-dialog';
import { Logout as LogoutIcon } from '@mui/icons-material';

interface NavbarProps { }

const actionButtonStyles = {
  px: 4,
  textTransform: 'none',
  '&:hover': {
    backgroundColor: 'static',
  },
};

const navRoutes = [
  { label: 'About Us', route: '/about', icon: Info },
  { label: 'Contact Us', route: '/contact', icon: Phone },
];

const NavItem: React.FC<{ item: typeof navRoutes[0]; onClick: () => void; active: boolean }> = ({ item, onClick, active }) => (
  <ListItem
    onClick={onClick}
    sx={{
      background: active ? customTheme.palette.primary.main : 'transparent',
      color: active ? 'white' : customTheme.palette.primary.main,
      mb: 2,
      borderRadius: '10px',
      width: 'auto',
      '&:hover': {
        background: customTheme.palette.primary.light,
        color: 'white',
      },
    }}
  >
    <item.icon />
    <Typography variant="body1" sx={{ fontSize: 14, fontWeight: 'bold', paddingLeft: 2 }}>
      {item.label}
    </Typography>
  </ListItem>
);

const NavList: React.FC<{ items: typeof navRoutes; onNavigate: (route: string) => void; currentPath: string }> = ({ items, onNavigate, currentPath }) => (
  <List style={{ marginTop: 40 }}>
    {items.map((item, index) => (
      <NavItem key={index} item={item} onClick={() => onNavigate(item.route)} active={currentPath === item.route} />
    ))}
  </List>
);

const AuthButtons: React.FC<{ onLogin: () => void; onSignUp: () => void }> = ({ onLogin, onSignUp }) => (
  <Box sx={{ display: 'flex', gap: 2 }}>
    <Button
      sx={{
        ...actionButtonStyles,
        color: 'primary.main',
        backgroundColor: 'secondary.main',
        borderRadius: '8px',
        '&:hover': { backgroundColor: 'secondary.main' },
      }}
      onClick={onLogin}
    >
      Login
    </Button>
    <Button
      sx={{
        ...actionButtonStyles,
        color: 'primary.main',
        backgroundColor: 'white',
        borderRadius: '8px',
        '&:hover': { backgroundColor: 'white' },
      }}
      onClick={onSignUp}
    >
      Sign Up
    </Button>
  </Box>
);

const DrawerContent: React.FC<{ onNavigate: (route: string) => void; currentUser: boolean; currentPath: string; onLogout: () => void }> = ({
  onNavigate,
  currentUser,
  currentPath,
  onLogout,
}) => (
  <Box display="flex" justifyContent="space-between" flexDirection="column" flexGrow={1}>
    <Box>
      <Box sx={{ top: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography
          variant="h5"
          sx={{ fontSize: '24px', color: customTheme.palette.primary.main, fontWeight: 'bold', cursor: 'pointer' }}
          onClick={() => onNavigate('/')}
        >
          ShopPayGo
        </Typography>
      </Box>
      <NavList items={navRoutes} onNavigate={onNavigate} currentPath={currentPath} />
      {!currentUser ? (
        <>
          <NavItem item={{ label: 'Sign Up', route: '/advertiser-vendor', icon: PersonAddAlt }} onClick={() => onNavigate('/advertiser-vendor')} active={currentPath === '/advertiser-vendor'} />
          <NavItem item={{ label: 'Login', route: '/login', icon: Login }} onClick={() => onNavigate('/login')} active={currentPath === '/login'} />
        </>
      ) : (
        <NavItem item={{ label: 'Logout', route: '', icon: Logout }} onClick={onLogout} active={false} />
      )}
    </Box>
    <Typography textAlign="center" mt={1} fontSize={14} style={{ display: 'flex', justifyContent: 'center' }}>
      &copy;ShopPayGo
    </Typography>
  </Box>
);

export const Navbar: React.FC<NavbarProps> = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { currentUser, authLoading, logout } = useAuth();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  const handleNavigation = (route: string) => {
    setDrawerOpen(false);
    navigate(route);
  };

  const handleConfirmLogout = () => {
    logout();
    navigate('/');
    setOpenConfirmDialog(false);
    setDrawerOpen(false);
  };

  return (
    <AppBar position="sticky" style={{ background: customTheme.palette.primary.main, boxShadow: 'none' }}>
      <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', px: 0 }}>
      <Box sx={{ flex: 1, display: 'flex', alignItems: 'center' }}>
    <IconButton
      color="inherit"
      onClick={() => setDrawerOpen(true)}
      sx={{ color: 'white', display: { xs: 'block', sm: 'none' }, pb: '6px' }}
    >
      <Menu />
    </IconButton>
    <Typography
      variant="h5"
      sx={{ fontSize: '20px', color: 'white', fontWeight: 'bold', cursor: 'pointer' }}
      onClick={() => navigate('/')}
    >
      ShopPayGo
    </Typography>
  </Box>

  <Box sx={{ 
    flex: 1, 
    display: { xs: 'none', sm: 'flex' }, 
    justifyContent: 'center', 
    gap: 4 
  }}>
    {navRoutes.map((item, index) => (
      <Typography
        key={index}
        color="white"
        onClick={() => handleNavigation(item.route)}
        sx={{
          fontSize: '16px',
          borderBottom: location.pathname === item.route ? '3px solid white' : 'none',
          cursor: 'pointer',
          whiteSpace: 'nowrap'
        }}
      >
        {item.label}
      </Typography>
    ))}
  </Box>

  <Box sx={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
    {!authLoading && (
      <>
        {!currentUser ? (
          <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
            <AuthButtons onLogin={() => navigate('/login')} onSignUp={() => navigate('/advertiser-vendor')} />
          </Box>
        ) : (
              <Box sx={{ display: 'flex', gap: 2 }}>
                {isSmallScreen ? (
                  <>
                    <IconButton
                      onClick={() => navigate('/dashboard')}
                      sx={{
                        color: '#fcaa4f',
                      }}
                    >
                      <Dashboard />
                    </IconButton>
                    <IconButton
                      onClick={() => setOpenConfirmDialog(true)}
                      sx={{
                        color: '#fcaa4f',
                      }}
                    >
                      <Logout />
                    </IconButton>
                  </>
                ) : (
                  <>
                    <Button
                      variant="contained"
                      onClick={() => navigate('/dashboard')}
                      sx={{
                        color: 'white',
                        px: 2,
                        borderRadius: '10px',
                        textTransform: 'none',
                        backgroundColor: '#fcaa4f',
                        '&:hover': { backgroundColor: '#fcaa4f' },
                      }}
                    >
                      Dashboard
                    </Button>
                    <Button
                      sx={{
                        color: 'primary.main',
                        px: 4,
                        borderRadius: '10px',
                        textTransform: 'none',
                        backgroundColor: 'white',
                        '&:hover': {
                          backgroundColor: 'white',
                        },
                      }}
                      onClick={() => setOpenConfirmDialog(true)}
                    >
                      Logout
                    </Button>
                  </>
                )}
              </Box>
            )}
          </>
        )}

        <Drawer
          anchor="left"
          open={isDrawerOpen}
          onClose={() => setDrawerOpen(false)}
          sx={{
            '& .MuiDrawer-paper': {
              width: isSmallScreen ? '70%' : '30%',
              p: 2,
              backdropFilter: 'blur(8px)',
              height: '100%',
            },
          }}
        >
          <DrawerContent
            onNavigate={handleNavigation}
            currentUser={!!currentUser}
            currentPath={location.pathname}
            onLogout={() => setOpenConfirmDialog(true)}
          />
        </Drawer>
        <ConfirmDialog
          open={openConfirmDialog}
          onClose={() => setOpenConfirmDialog(false)}
          onConfirm={handleConfirmLogout}
          message="Are you sure you want to logout?"
          title="Logout"
          icon={<LogoutIcon sx={{fontSize:"32px"}} />}
        />
        </Box>
      </Toolbar>
    </AppBar>
  );
};