import React from 'react';
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Container,
} from '@mui/material';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

export const TermsCondition = () => {
  return (
    <Box
      sx={{
        background: "linear-gradient(135deg, #ffffff 0%, #e0f7fa 100%)",
        minHeight: '100vh',
      }}
    >
      <Container maxWidth="md">
        <Box
          sx={{
            position: 'absolute',
            top: '50px',
            left: '50%',
            transform: 'translateX(-50%)',
            marginTop: '50px',
            backgroundColor: '#FCAA4F',
            width: '200px',
            height: '40px',
            borderRadius: 30,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography
            sx={{ fontWeight: 'bold', fontSize: '16px', color: 'white' }}
          >
            Terms & Conditions
          </Typography>
        </Box>
        <Box sx={{ padding: '2rem 0', paddingTop: '100px', }}>
          <Typography variant="h3" align="center" gutterBottom>
            Our terms of service
          </Typography>
          <Typography
            variant="body1"
            align="center"
            color="textSecondary"
            gutterBottom
          >
            Last updated: Sept 01, 2024
          </Typography>
          <Box mb={4}>
          <Typography variant="h5" gutterBottom>
            1. Introduction
          </Typography>
          <Typography variant="body1">
            Welcome to ShopPayGo! These Terms and Conditions ("Terms") govern
            your use of our website and services. By accessing or using
            ShopPayGo, you agree to be bound by these Terms. If you do not agree
            to these Terms, please do not use our services.
          </Typography>
        </Box>

          <Box mb={4}>
          <Typography variant="h5" gutterBottom>
            2. Definitions
          </Typography>
          <List>
            <ListItem>
              <ListItemIcon>
                <FiberManualRecordIcon
                  sx={{ color: 'black' }}
                  fontSize="inherit"
                />
              </ListItemIcon>
              <ListItemText primary='"Company","We", "Our", and "Us" refer to ShopPayGo, the owner and operator of the platform.' />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <FiberManualRecordIcon
                  sx={{ color: 'black' }}
                  fontSize="inherit"
                />
              </ListItemIcon>
              <ListItemText primary='"User","You" and "Your" refer to individuals or entities accessing or using the services provided by ShopPayGo.' />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <FiberManualRecordIcon
                  sx={{ color: 'black' }}
                  fontSize="inherit"
                />
              </ListItemIcon>
              <ListItemText primary='"Services" Refer to the self-checkout system, retailer tools, advertising opportunities, and other related services.' />
            </ListItem>
          </List>
        </Box>

        <Box mb={4}>
          <Typography variant="h5" gutterBottom>
            3. Eligibility
          </Typography>
          <Typography variant="body1">
            You must be at least 14 years old to use our services. By using
            ShopPayGo, you represent and warrant that you meet this age
            requirement.
          </Typography>
        </Box>

        <Box mb={4}>
          <Typography variant="h5" gutterBottom>
            4. Account Registration
          </Typography>
          <Typography variant="body1">
            To access certain features of our services, you may need to create
            an account. You agree to provide accurate, current, and complete
            information.
          </Typography>
        </Box>

        <Box mb={4}>
          <Typography variant="h5" gutterBottom>
            5. Use of Services
          </Typography>
          <Typography variant="body1" paragraph>
            License: ShopPayGo grants you a limited, non-exclusive,
            non-transferable, and revocable license to use our services.
          </Typography>
          <Typography variant="body1" paragraph>
            Restrictions: You agree not to:
          </Typography>
          <List>
            <ListItem>
              <ListItemIcon>
                <FiberManualRecordIcon
                  sx={{ color: 'black' }}
                  fontSize="inherit"
                />
              </ListItemIcon>
              <ListItemText primary="Use the services for any illegal or unauthorized purpose." />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <FiberManualRecordIcon
                  sx={{ color: 'black' }}
                  fontSize="inherit"
                />
              </ListItemIcon>
              <ListItemText primary="Interfere with the integrity or performance of the services." />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <FiberManualRecordIcon
                  sx={{ color: 'black' }}
                  fontSize="inherit"
                />
              </ListItemIcon>
              <ListItemText primary="Reverse engineer or disassemble any portion of the services." />
            </ListItem>
          </List>
        </Box>

        <Box mb={4}>
          <Typography variant="h5" gutterBottom>
            6. Payments and Fees
          </Typography>
          <Typography variant="body1" paragraph>
            Retailers: Retailers using ShopPayGo’s services may be subject to
            fees as described during the sign-up process. All fees are
            non-refundable unless otherwise stated.
          </Typography>
          <Typography variant="body1" paragraph>
            Payments: Payments must be made through the methods specified by
            ShopPayGo. Failure to make timely payments may result in the
            suspension or termination of your access to the services.
          </Typography>
        </Box>

        <Box mb={4}>
          <Typography variant="h5" gutterBottom>
            7. Data Privacy and Security
          </Typography>
          <Typography variant="body1" paragraph>
            Personal Data: ShopPayGo is committed to protecting your personal
            information. Our Privacy Policy outlines how we collect, use, and
            protect your data.
          </Typography>
          <Typography variant="body1" paragraph>
            Security: While we strive to protect your personal information, we
            cannot guarantee the security of any information transmitted through
            the services. You acknowledge that you provide your information at
            your own risk.
          </Typography>
        </Box>

        <Box mb={4}>
          <Typography variant="h5" gutterBottom>
            8. Intellectual Property
          </Typography>
          <Typography variant="body1" paragraph>
            Ownership: All content, trademarks, and other intellectual property
            rights on the ShopPayGo platform are owned by or licensed to
            ShopPayGo. You may not use any content or trademarks without our
            prior written consent.
          </Typography>
          <Typography variant="body1" paragraph>
            User Content: By submitting content (e.g., reviews, feedback) to
            ShopPayGo, you grant us a non-exclusive, worldwide, royalty-free,
            perpetual license to use, reproduce, and display such content.
          </Typography>
        </Box>

        <Box mb={4}>
          <Typography variant="h5" gutterBottom>
            9. Third-Party Services
          </Typography>
          <Typography variant="body1" paragraph>
            ShopPayGo may contain links to third-party websites or services that
            are not owned or controlled by us. We are not responsible for the
            content, privacy policies, or practices of any third-party websites
            or services.
          </Typography>
        </Box>

        <Box mb={4}>
          <Typography variant="h5" gutterBottom>
            10. Limitation of Liability
          </Typography>
          <Typography variant="body1" paragraph>
            To the maximum extent permitted by law, ShopPayGo and its affiliates
            shall not be liable for any indirect, incidental, special,
            consequential, or punitive damages, or any loss of profits or
            revenues, whether incurred directly or indirectly, or any loss of
            data, use, goodwill, or other intangible losses, resulting from:
          </Typography>
          <List>
            <ListItem>
              <ListItemIcon>
                <FiberManualRecordIcon
                  sx={{ color: 'black' }}
                  fontSize="inherit"
                />
              </ListItemIcon>
              <ListItemText primary="Your use or inability to use the services." />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <FiberManualRecordIcon
                  sx={{ color: 'black' }}
                  fontSize="inherit"
                />
              </ListItemIcon>
              <ListItemText primary="Any unauthorized access to or use of our services." />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <FiberManualRecordIcon
                  sx={{ color: 'black' }}
                  fontSize="inherit"
                />
              </ListItemIcon>
              <ListItemText primary="Any interruption or cessation of transmission to or from our services." />
            </ListItem>
          </List>
        </Box>

        <Box mb={4}>
          <Typography variant="h5" gutterBottom>
            11. Idemnification
          </Typography>
          <Typography variant="body1" paragraph>
            You agree to indemnify and hold harmless ShopPayGo, its affiliates,
            and its respective officers, directors, employees, and agents from
            and against any claims, liabilities, damages, losses, and expenses,
            including legal fees, arising out of or in any way connected with
            your use of the services or breach of these Terms.
          </Typography>
        </Box>

        <Box mb={4}>
          <Typography variant="h5" gutterBottom>
            12. Governing Law and Jurisdiction
          </Typography>
          <Typography variant="body1" paragraph>
            These Terms shall be governed by and construed in accordance with
            the laws of India, without regard to its conflict of law principles.
            Any disputes arising from these Terms or the use of the services
            shall be subject to the exclusive jurisdiction of the courts in
            Banglore, India.
          </Typography>
        </Box>

        <Box mb={4}>
          <Typography variant="h5" gutterBottom>
            13. International Users
          </Typography>
          <Typography variant="body1" paragraph>
            If you are accessing our services from outside India, you are
            responsible for complying with local laws. You agree to abide by all
            applicable laws, rules, and regulations, including but not limited
            to export and import regulations.
          </Typography>
        </Box>

        <Box mb={4}>
          <Typography variant="h5" gutterBottom>
            14. Termination
          </Typography>
          <Typography variant="body1" paragraph>
            ShopPayGo reserves the right to terminate or suspend your account
            and access to the services at any time, without prior notice or
            liability, for any reason, including if you breach these Terms.
          </Typography>
        </Box>

        <Box mb={4}>
          <Typography variant="h5" gutterBottom>
            15. Change to the Terms
          </Typography>
          <Typography variant="body1" paragraph>
            We may update these Terms from time to time. If we make changes, we
            will notify you by revising the "Last Updated" date at the top of
            these Terms. Your continued use of the services after the changes
            take effect will constitute your acceptance of the new Terms.
          </Typography>
        </Box>

          <Box mb={4}>
            <Typography variant="h5" gutterBottom>
              1. Introduction
            </Typography>
            <Typography variant="body1">
              Welcome to ShopPayGo! These Terms and Conditions ("Terms") govern
              your use of our website and services. By accessing or using
              ShopPayGo, you agree to be bound by these Terms. If you do not agree
              to these Terms, please do not use our services.
            </Typography>
          </Box>

          {/* Include all other sections as in your original code */}

          <Box mb={4}>
            <Typography variant="h5" gutterBottom>
              16. Contact Us
            </Typography>
            <Typography variant="body1" paragraph>
              If you have any questions or concerns about these Terms, please
              contact us at:
            </Typography>
            <List>
              <ListItem>
                <ListItemIcon>
                  <FiberManualRecordIcon
                    sx={{ color: 'black' }}
                    fontSize="inherit"
                  />
                </ListItemIcon>
                <ListItemText primary="Email: support@shoppaygo.in" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <FiberManualRecordIcon
                    sx={{ color: 'black' }}
                    fontSize="inherit"
                  />
                </ListItemIcon>
                <ListItemText primary="Address: Bangalore, India" />
              </ListItem>
            </List>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};