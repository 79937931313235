import React from "react";
import { Snackbar, SnackbarContent } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import InfoIcon from "@mui/icons-material/Info";
import WarningIcon from "@mui/icons-material/Warning";

interface SnackbarComponentProps {
  open: boolean;
  message: string;
  severity: "success" | "error" | "warning" | "info";
  onClose: () => void;
  anchorOrigin?: { vertical: "top" | "bottom"; horizontal: "left" | "center" | "right" };
}

const iconMapping = {
  success: <CheckCircleIcon fontSize="inherit" />,
  error: <ErrorIcon fontSize="inherit" />,
  warning: <WarningIcon fontSize="inherit" />,
  info: <InfoIcon fontSize="inherit" />,
};

export const SnackbarComponent: React.FC<SnackbarComponentProps> = ({
  open,
  message,
  severity,
  onClose,
  anchorOrigin,
}) => {
  return (
    <Snackbar
      open={open}
      autoHideDuration={6000}
      onClose={onClose}
      anchorOrigin={anchorOrigin}
      sx={{height: '100%'}}
    >
      <SnackbarContent
        message={
          <span style={{ display: "flex", alignItems: "center" }}>
            {iconMapping[severity]}
            <span style={{ marginLeft: 8 }}>{message}</span>
          </span>
        }
        style={{
          backgroundColor:
            severity === "success"
              ? "#4caf50"
              : severity === "error"
              ? "#f44336"
              : severity === "warning"
              ? "#ff9800"
              : "#2196f3",
          minWidth: "auto",
          maxWidth: "600px",
        }}
      />
    </Snackbar>
  );
};