import { SxProps, Theme } from '@mui/material/styles';

export const tableContainerStyle: SxProps<Theme> = {
  borderRadius: '10px',
  height: '77vh',
  overflowY: 'scroll',
  '::-webkit-scrollbar': { display: 'none' },
};

export const tableHeadRowStyle: SxProps<Theme> = {
  backgroundColor: 'primary.main',
  position: 'sticky',
  top: 0,
  zIndex: 1,
};

export const tableCellStyle: SxProps<Theme> = {
  fontWeight: 'bold',
  textAlign: 'center',
  color: 'white',
  borderRight: '1px solid #808080',
};

export const lastTableCellStyle: SxProps<Theme> = {
  fontWeight: 'bold',
  textAlign: 'center',
  color: 'white',
};

export const avatarStyle: SxProps<Theme> = {
  width: 48,
  height: 48,
  ml: 'auto',
  mr: 'auto',
};

export const iconButtonStyle: SxProps<Theme> = {
  color: 'primary.main',
  border: '1px solid',
  borderRadius: '8px',
  '&:hover': { backgroundColor: 'primary.light', color: 'white' },
};

export const outerBoxStyle: SxProps<Theme> = {
  backgroundColor: 'white',
  borderRadius: '12px',
  minHeight: '90vh',
  boxShadow: 2,
  padding: { xs: '8px', sm: '16px' },
};

export const headerBoxStyle: SxProps<Theme> = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  mb: 2,
};

export const searchInputStyle: SxProps<Theme> = {
  height: '40px',
  borderRadius: '10px',
  boxShadow: '0 3px 5px rgba(0,0,0,0.1)',
};