import React from 'react';
import { Box, Container, Grid, Typography } from '@mui/material';
import { BenefitsSection } from '../../components/about/BenefitsSection';
import { MeetTheTeamSection } from '../../components/about/MeetTeamSection';

interface HighlightedTextProps {
  text: string;
}

const HighlightedText: React.FC<HighlightedTextProps> = ({ text }) => (
  <Typography
    component="span"
    sx={{
      fontWeight: 700,
      fontSize: '24px',
      color: '#29B6F6', 
    }}
  >
    {text}
  </Typography>
);

export const About: React.FC = () => {
  return (
    <>
      <Container maxWidth={false} sx={{ p: 3, background: "linear-gradient(135deg, #ffffff 0%, #e0f7fa 100%)", }}>
        <Box
          sx={{
            // backgroundColor: '#E0F7FA', 
            borderRadius: '20px',
            p: 4,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Typography
            variant="h1"
            sx={{
              fontWeight: 700,
              fontSize: { xs: '32px', md: '48px' },
              color: 'primary.main',
              alignSelf: 'start',
              marginBottom: '40px',
            }}
          >
            Our Dream is Global <br /> Retail Checkout Transformation
          </Typography>

          <Grid container spacing={2}>
            <Grid item xs={12} md={6} sx={{alignContent:'center'}}>
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: '24px',
                  color: '#5a5a5a', 
                }}
              >
                <Box component="span" sx={{ fontWeight: 700 }}>
                  At ShopPayGo, we believe the future of shopping is{' '}
                </Box>
                <HighlightedText text="seamless" />
                <Box component="span" sx={{ fontWeight: 700 }}>
                  ,{' '}
                </Box>
                <HighlightedText text="efficient" />
                <Box component="span" sx={{ fontWeight: 700 }}>
                  , and{' '}
                </Box>
                <HighlightedText text="customer-first" />
                <Box component="span" sx={{ fontWeight: 700 }}>
                  . By integrating{' '}
                </Box>
                <HighlightedText text="advanced self-checkout systems" />
                <Box component="span" sx={{ fontWeight: 700 }}>
                  {' '}
                  and digital solutions, we are{' '}
                </Box>
                <HighlightedText text="reshaping retail" />
                <Box component="span" sx={{ fontWeight: 700 }}>
                  {' '}
                  - one innovative step at a time.
                </Box>
              </Typography>
            </Grid>

            <Grid item xs={12} md={6}>
              <Box
                component="img"
                src={require('../../Images/about.png')}
                alt="ShopPayGo retail transformation illustration"
                sx={{
                  objectFit: 'contain',
                  width: '100%',
                  borderRadius: '20px', 
                  boxShadow: '0px 8px 24px rgba(0, 0, 0, 0.1)', 
                }}
              />
            </Grid>
          </Grid>
        </Box>
        <BenefitsSection />
        <MeetTheTeamSection />
      </Container>
    </>
  );
};