import React, { useEffect } from 'react';
import {
  Routes,
  Route,
  BrowserRouter as Router,
  Navigate,
  useLocation,
} from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { LoginForm } from './pages/Login';
import { Dashboard } from './pages/Dashboard';
import { Advertiser } from './pages/Advertiser';
import { Announcements } from './pages/Announcements';
import { Payment } from './pages/Payments';
import { Reports } from './pages/Reports';
import { AccountInfo } from './pages/AccountInfo';
import { Home } from './pages/Home';
import { AdvertiserVendor } from './pages/advertiser-vendor';
import { AdvertiserOnboard } from './pages/AdvertiserOnboard/internal';
import SubscriptionPlans from './pages/SubscriptionPlans/internal';
import { VendorOnboard } from './pages/VendorOnboard/internal';
import { About } from './pages/About';
import { Contact } from './pages/Contact';
import { Pricing } from './pages/Pricing';
import { Features } from './pages/Features';
import { TermsCondition } from './pages/TermsCondition';
import { PrivacyPolicy } from './pages/PrivacyPolicy';
import { PageNotFound } from './pages/pageNotFound';
import { Footer } from './components/footer';
import { Navbar } from './components/navbar';
import { Loader } from './components/loader';
import ProtectedRoute from './components/ProtectedRoute';


// Context Providers
import { FileProvider } from './context/FileContext';
import { AuthProvider, useAuth } from './authprovider';
import { StoreProvider } from './context/StoreContext';
import { Manage } from './pages/Manage';

const theme = createTheme({
  palette: {
    primary: {
      main: '#281839',
    },
    secondary: {
      main: '#ECE5F4',
    },
  },
});

const ScrollToTop: React.FC = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, [pathname]);

  return null;
};

function ResponsiveRoutes() {
  const location = useLocation();
  const { isLoggedIn, authLoading } = useAuth();

  const showNavbarRoutes = [
    '/',
    // '/plans',
    '/about',
    // '/pricing',
    // '/features',
    '/terms',
    '/privacy',
    '/login',
    '/advertiser-vendor',
    '/advertiser-onboard',
    '/vendor-onboard',
    '/contact',
    '/pagenotfound',
  ];

  const showFooterRoutes = [
    '/',
    // '/plans',
    '/about',
    // '/pricing',
    // '/features',
    '/terms',
    '/privacy',
    '/pagenotfound',
    '/contact',
  ];

  if (authLoading) {
    return <Loader />;
  }

  return (
    <>
      {showNavbarRoutes.includes(location.pathname) && <Navbar />}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route
          path="/login"
          element={isLoggedIn ? <Navigate to="/dashboard" /> : <LoginForm />}
        />
         <Route
          path="/advertiser-vendor"
          element={isLoggedIn ? <Navigate to="/dashboard" /> : <AdvertiserVendor />}
        />
        <Route
         path="/advertiser-onboard"
         element={isLoggedIn ? <Navigate to="/dashboard" /> : <AdvertiserOnboard />}
       />
       <Route
        path="/vendor-onboard"
        element={isLoggedIn ? <Navigate to="/dashboard" /> : <VendorOnboard />}
      />
        {/* <Route path="/plans" element={<SubscriptionPlans />} /> */}
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        {/* <Route path="/pricing" element={<Pricing />} />
        <Route path="/features" element={<Features />} /> */}
        <Route path="/terms" element={<TermsCondition />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="/pagenotfound" element={<PageNotFound />} />
        <Route element={<ProtectedRoute />}>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/advertiser" element={<Advertiser />} />
          <Route path="/announcements" element={<Announcements />} />
          <Route path="/payments" element={<Payment />} />
          <Route path="/reports" element={<Reports />} />
          <Route path="/accountinfo" element={<AccountInfo />} />
          <Route path="/manage" element={<Manage />} />
        </Route>
        <Route path="*" element={<Navigate to="/pagenotfound" />} />
      </Routes>
      {showFooterRoutes.includes(location.pathname) && <Footer />}
    </>
  );
}

function App() {
  return (
    <ThemeProvider theme={theme}>
      {/* <FileProvider> */}
        <AuthProvider>
          <StoreProvider>
            <Router>
              <ScrollToTop />
              <ResponsiveRoutes />
            </Router>
          </StoreProvider>
          <div id="recaptcha-container"></div>
        </AuthProvider>
      {/* </FileProvider> */}
    </ThemeProvider>
  );
}

export default App;
