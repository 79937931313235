import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from '../authprovider';
import { Loader } from './loader';

const ProtectedRoute: React.FC = () => {
  const { isLoggedIn, authLoading } = useAuth();

  if (authLoading) {
    return <Loader />; // Use the Loader component
  }

  return isLoggedIn ? <Outlet /> : <Navigate to="/login" />;
};

export default ProtectedRoute;