// CustomGrowOnHover.tsx
import React, { ReactNode } from 'react';
import { Box } from '@mui/material';

interface GrowOnHoverProps {
  children: ReactNode;
  timeout?: number;
}

export const CustomGrowOnHover: React.FC<GrowOnHoverProps> = ({ children, timeout = 400 }) => {
  return (
    <Box
      onMouseEnter={(e) => (e.currentTarget.style.transform = 'scale(1.1)')}
      onMouseLeave={(e) => (e.currentTarget.style.transform = 'scale(1)')}
      sx={{
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        transition: `transform ${timeout}ms ease`,
      }}
    >
      {children}
    </Box>
  );
};