import React, { useState } from "react";
import { useForm, FormProvider, SubmitHandler, FieldValues } from "react-hook-form";
import { Box, Button, CardActions, Grid, Snackbar, SnackbarContent } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { FormStepper } from "../../components/steeper/FormStepper";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../authprovider";
import Spinner from "../../components/Spinner";
import { auth, firebaseStorage } from "../../firebase";
import { EmailDialog } from "../../components/email-dialog";
import customTheme from "../../components/theme/customTheme";
import { FormValues } from "../vendor-form/types";
import { FormHeader } from "../form-header";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";

const useStyles = makeStyles((theme: any) => ({
  container: {
    display: "flex",
    minHeight: "100vh",
    background: `url(${require("../../Images/formback.jpg")})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    alignItems: "center",
    justifyContent: "center",
    padding: 24,
  },
  card: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.2)",
    backgroundColor: "#fff",
    borderRadius: "4px",
    width: "100%",
    padding: 8,
    gap: 8,
  },
  stepperContainer: {
    backgroundColor: customTheme.palette.primary.main,
    borderRadius: "4px",
    padding: 16,
    paddingBottom: 0,
  },
  content: {
    flexGrow: 1,
    overflowY: "auto",
    overflowX: "hidden",
  },
  buttonContainer: {
    position: "absolute",
    bottom: "16px",
    right: "16px",
    gap: "20px",
    top: "16px",
  },
  button: {
    height: "40px",
    borderRadius: "4px",
    width: "140px",
  },
  spinnerContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
  },
}));

interface OnboardProps {
  steps: string[];
  defaultValues: FormValues;
  FormContentComponent: React.ComponentType<{ step: number }>;
  type: string;
}

export const Onboard: React.FC<OnboardProps> = ({
  steps,
  defaultValues,
  FormContentComponent,
  type,
}) => {
  const classes = useStyles();
  const methods = useForm<FormValues>({ defaultValues });
  const { signupWithEmailAndPassword } = useAuth();
  const [activeStep, setActiveStep] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [popupOpen, setPopupOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const handleNext: SubmitHandler<FormValues> = async (data: FieldValues, event?: React.BaseSyntheticEvent) => {
    event?.preventDefault();
    if (activeStep === steps.length - 1) {
      setIsLoading(true);
      try {
        const role = type === 'Advertiser' ? 'ADVERTISER' : 'VENDOR';
        data.role = role;
  
        // const logoFile = data.logo;
        // if (logoFile) {
        //   const storageRef = ref(firebaseStorage, `logos/${data.brandName}`);
        //   await uploadBytes(storageRef, logoFile);
        //   const downloadURL = await getDownloadURL(storageRef);
        //   data.logo = downloadURL;
        // }
        
        console.log("Form data on submit: ", data);

        await signupWithEmailAndPassword(data);
        setPopupOpen(true);
      } catch (error: any) {
        console.log(error);
        if (error.code === "auth/email-already-in-use") {
          setErrorMessage('Email already in use. Please try logging in or use a different email to sign up!');
        } else { 
        setErrorMessage(error.code);
        }
        setIsLoading(false);
        return;
      }
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleClosePopup = () => {
    setPopupOpen(false);
  };

  const handleCloseSnackbar = () => {
    setErrorMessage(null);
  };

  return (
    <Box
      className={classes.container}
      py={{ sm: 4, md: 4 }}
      px={{ sm: 4, md: 24 }}
    >
      <Box className={classes.card}>
        <FormHeader
          heading={
            type === "Advertiser"
              ? "Advertiser Onboarding"
              : "Vendor Onboarding"
          }
        />
        <Grid container>
          <Grid
            item
            xs={12}
            sm={5}
            md={4}
            lg={3}
            className={classes.stepperContainer}
            sx={{
              display: { xs: "none", sm: "flex" },
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <FormStepper activeStep={activeStep} steps={steps} />
            {steps.length <= 3 && (
              <Box>
                <img
                  src={require("../../Images/onboard-abstract.png")}
                  alt="Stepper Bottom"
                  style={{ width: "200px", height: "180px" }}
                />
              </Box>
            )}
          </Grid>
          <Grid
            item
            xs={12}
            sm={7}
            md={8}
            lg={9}
            className={classes.content}
            py={2}
            px={{ xs: 2, md: 4 }}
          >
            <FormProvider {...methods}>
              <form
                onSubmit={methods.handleSubmit(handleNext)}
                style={{ height: "100%" }}
              >
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  justifyContent={"space-between"}
                  height={"100%"}
                  gap={3}
                >
                  <FormContentComponent step={activeStep} />
                  <CardActions style={{ justifyContent: "flex-end " }}>
                    <Button
                      variant="outlined"
                      className={classes.button}
                      onClick={handleBack}
                      sx={{ display: activeStep === 0 ? "none" : "block" }}
                    >
                      Back
                    </Button>
                    <Button
                      className={classes.button}
                      variant="contained"
                      type="submit"
                      onClick={methods.handleSubmit(handleNext)}
                      sx={{ textTransform: "none", fontWeight: "bold" }}
                    >
                      {isLoading ? (
                        <Spinner size={20} color="white" />
                      ) : (
                        <>
                          {activeStep === steps.length - 1
                            ? "Verify Email"
                            : "Next"}
                        </>
                      )}
                    </Button>
                  </CardActions>
                </Box>
              </form>
            </FormProvider>
            {activeStep === steps.length && (
              <Box className={classes.spinnerContainer}>
                {isLoading && <Spinner color="primary" size={40} />}
              </Box>
            )}
          </Grid>
        </Grid>
      </Box>

      <EmailDialog open={popupOpen} onClose={handleClosePopup} />

      <Snackbar
        open={!!errorMessage}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        sx={{
          "& .MuiSnackbarContent-root": {
            backgroundColor: "red",
            color: "#fff",
          },
        }}
      >
        <SnackbarContent message={errorMessage} />
      </Snackbar>
    </Box>
  );
};