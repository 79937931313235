// FormStepper.tsx
import {
  Stepper,
  Step,
  StepLabel,
  Box,
  StepConnector,
  Typography,
  styled,
} from "@mui/material";
import ColorlibStepIcon from "./ColorlibStepIcon";

const CustomStepConnector = styled(StepConnector)({
  "& .MuiStepConnector-line": {
    borderLeftWidth: 4,
    minHeight: 50,
    marginLeft: "20px",
    borderColor: "#34495E",
    transition: "all 0.3s ease-in-out",
  },
  "&.Mui-active .MuiStepConnector-line": {
    borderColor: "#FCAA4F",
    borderLeftStyle: "solid",
  },
  "&.Mui-completed .MuiStepConnector-line": {
    borderColor: "#FCAA4F",
    borderLeftStyle: "solid",
  },
});

interface FormStepperProps {
  activeStep: number;
  steps: string[];
}

export const FormStepper: React.FC<FormStepperProps> = ({
  activeStep,
  steps,
}) => {
  return (
    <Box 
      sx={{ 
        width: "100%", 
        p: 3, 
        display: "flex", 
        justifyContent: 'center',
        borderRadius: 2,
      }}
    >
      <Stepper
        activeStep={activeStep}
        orientation="vertical"
        connector={<CustomStepConnector />}
      >
        {steps.map((step, index) => (
          <Step key={step}>
            <StepLabel
              StepIconComponent={ColorlibStepIcon}
              sx={{
                ".MuiStepLabel-label": {
                  fontSize: "1.20rem",
                  color: "#B8C2CC",
                  transition: "all 0.3s ease-in-out",
                },
                ".MuiStepLabel-label.Mui-active": {
                  color: "#FCAA4F",
                  fontWeight: 600,
                },
                ".MuiStepLabel-label.Mui-completed": {
                  color: "#FCAA4F",
                },
                ".MuiStepLabel-iconContainer": {
                  marginRight: "16px",
                },
                p: 1,
              }}
            >
              {step}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};