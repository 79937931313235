import React from 'react';
import { Paper, Typography, Box, Grid } from '@mui/material';

const subscriptionSections = [
  {
    title: 'Subscription Performance',
    items: [
      { label: 'Total Subscription Value:', value: '₹1.6cr' },
      { label: 'Total Subscription:', value: '100k' },
      { label: 'Subscription Churn rate:', value: '82k' },
      { label: 'ARPU:', value: '16k' },
      { label: 'MRR:', value: '2k' },
      { label: 'User Session Length:', value: '2k' },
      { label: 'ULTV:', value: '16k' },
      { label: 'Free Trail Conversion:', value: '2k' },
    ],
  },
];

const SubscriptionItem = ({ label, value }: { label: string, value: string }) => (
  <Grid container sx={{ marginBottom: '8px' }}>
    <Grid item xs={6}>
      <Typography sx={{ fontSize: '16px', color: '#AAAAAA', whiteSpace: 'nowrap', marginBottom: '8px' }}>{label}</Typography>
    </Grid>
    <Grid item xs={6}>
      <Typography sx={{ color: '#9E0D75', textAlign: 'right', marginBottom: '8px' }}>{value}</Typography>
    </Grid>
  </Grid>
);

export const SubscriptionPerformance: React.FC = () => {
    return (
      <Paper elevation={3} sx={{ padding: '16px', backgroundColor: 'white', marginTop: '8px', height: '100%', borderRadius: '10px' }}>
        {subscriptionSections.map((section) => (
          <Box key={section.title} sx={{ marginBottom: '8px' }}>
            <Typography
              sx={{
                fontSize: '18px',
                color: 'black',
                fontWeight: 'bolder',
                marginBottom: '8px'
              }}
            >
              {section.title}
            </Typography>
            {section.items.map((item, index) => (
              <SubscriptionItem key={index} label={item.label} value={item.value} />
            ))}
          </Box>
        ))}
      </Paper>
    );
  };