import React from "react";
import { Box, Grid, Typography, Chip, Avatar, Button, useMediaQuery, useTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useAuth } from "../../authprovider";
import { Phone, Email, LocationOn, Person, Receipt, StoreMallDirectory } from "@mui/icons-material";

import { Theme } from "@mui/material/styles";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    backgroundColor: "#fff",
    padding: "16px",
    borderRadius: "10px",
    boxShadow: "0 8px 24px rgba(0, 0, 0, 0.1)",
  },
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  userDetails: {
    display: "flex",
    alignItems: "center",
    gap: "16px",
  },
  avatar: {
    width: 65,
    height: 65,
    backgroundColor: "#37C3E2",
    fontSize: "32px",
  },
  chipContainer: {
    display: "flex",
    gap: "12px",
  },
  gridContainer: {
    marginTop: "8px",
  },
  icon: {
    color: "#37C3E2",
  },
  fieldLabel: {
    fontSize: "14px",
    color: "#757575",
  },
  fieldValue: {
    fontWeight: "600",
    fontSize: "18px",
    color: "#333",
    wordBreak: "break-word", // Ensure text wraps within its container
  },
}));

const getAccountDetails = (user: any) => [
  { label: "Name", value: user.profile?.name || "N/A", icon: <Person /> },
  { label: "Office Location", value: user.profile?.address || "N/A", icon: <LocationOn /> },
  { label: "Email", value: user.email || "N/A", icon: <Email /> },
  { label: "Contact Number", value: user.profile?.phoneNo || "N/A", icon: <Phone /> },
  { label: "GSTIN", value: user.profile?.gstin || "N/A", icon: <Receipt /> },
  { label: "No Of Stores", value: user.profile?.noOfStores || "N/A", icon: <StoreMallDirectory /> },
];

export const Account: React.FC = () => {
  const classes = useStyles();
  const { currentUser } = useAuth();
  const accounts = getAccountDetails(currentUser);

  const theme = useTheme();
  const isTabletPortrait = useMediaQuery(theme.breakpoints.between('sm', 'md')) && window.matchMedia("(orientation: portrait)").matches;

  const handleChipClick = () => alert("Feature coming soon");

  return (
    <Box className={classes.container}>
      {/* Header Section */}
      <Box className={classes.header}>
        <Typography sx={{ mb: 1, fontSize: "18px", fontWeight: "bolder" }}>
          Account
        </Typography>
        {/* <Box className={classes.chipContainer}>
          <Chip
            label="Edit"
            style={{ backgroundColor: "#37C3E2", color: "white", fontWeight: "bold" }}
            onClick={handleChipClick}
          />
          <Chip
            label="Suspended"
            style={{ backgroundColor: "#E30815", color: "white", fontWeight: "bold" }}
          />
        </Box> */}
      </Box>

      {/* Details Grid */}
      <Grid container spacing={2} className={classes.gridContainer}>
        {accounts.map((detail, index) => (
          <Grid item xs={12} sm={4} key={index}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "12px",
                padding: "16px 0",
                borderBottom: "1px solid #e0e0e0",
                minHeight: isTabletPortrait ? "110px" : "80px",
              }}
            >
              {detail.icon && <span className={classes.icon}>{detail.icon}</span>}
              <Box>
                <Typography className={classes.fieldValue}>
                  {detail.value}
                </Typography>
                <Typography className={classes.fieldLabel}>
                  {detail.label}
                </Typography>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};