import React from "react";
import { Dialog, IconButton, Box, Grow } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Spinner from "../../Spinner";

const isVideo = (avatar: string): boolean => {
  if (typeof avatar !== "string") return false;
  const lowerCaseURL = avatar.toLowerCase();
  const [urlPath] = lowerCaseURL.split('?');
  return (
    urlPath.endsWith(".mp4") ||
    urlPath.endsWith(".mkv") ||
    urlPath.endsWith(".avi") ||
    urlPath.endsWith(".mov") ||
    lowerCaseURL.includes("video")
  );
};

export const StoryDialog: React.FC<{
  open: boolean;
  handleClose: () => void;
  handleNext: () => void;
  handlePrevious: () => void;
  selectedStoryIndex: number;
  combinedStories: any[];
  isBuffering: boolean;
  setIsBuffering: (buffering: boolean) => void;
  videoRef: React.RefObject<HTMLVideoElement>;
  dummyStories: any[];
}> = ({
  open,
  handleClose,
  handleNext,
  handlePrevious,
  selectedStoryIndex,
  combinedStories,
  isBuffering,
  setIsBuffering,
  videoRef,
  dummyStories,
}) => {
  const lastUploadedStoryIndex = combinedStories.length - dummyStories.length - 1;

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{ style: { borderRadius: 15, position: 'relative', height: '90vh' } }}
      TransitionComponent={Grow}
      transitionDuration={500}
    >
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          zIndex: 1,
          backgroundColor: 'rgba(255, 255, 255, 0.7)',
          '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.7)',
          },
        }}
      >
        <CloseIcon />
      </IconButton>
      <IconButton
        aria-label="previous"
        onClick={handlePrevious}
        disabled={selectedStoryIndex === 0}
        sx={{
          position: 'absolute',
          left: 16,
          top: '50%',
          transform: 'translateY(-50%)',
          zIndex: 2,
          backgroundColor: (theme) =>
            selectedStoryIndex === 0
              ? '#D3D3D3 !important'
              : 'rgba(255, 255, 255, 0.7)',
          '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.7)',
          },
        }}
      >
        <ArrowBackIosIcon />
      </IconButton>
      {isBuffering && isVideo(combinedStories[selectedStoryIndex].avatar) && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'primary.main',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 3,
          }}
        >
          <Spinner color="white" size={50} />
        </Box>
      )}
      {isVideo(combinedStories[selectedStoryIndex].avatar) ? (
        <video
          ref={videoRef}
          controls
          autoPlay
          style={{ width: "100%", borderRadius: 15 }}
          src={combinedStories[selectedStoryIndex].avatar}
          onCanPlay={() => setIsBuffering(false)}
        />
      ) : (
        <img
          src={combinedStories[selectedStoryIndex].avatar}
          alt="Selected Story"
          style={{ width: "100%", height: "100%", borderRadius: 15 }}
          onLoad={() => setIsBuffering(false)}
        />
      )}
      <IconButton
        aria-label="next"
        onClick={handleNext}
        disabled={selectedStoryIndex >= lastUploadedStoryIndex}
        sx={{
          position: 'absolute',
          right: 16,
          top: '50%',
          transform: 'translateY(-50%)',
          zIndex: 2,
          backgroundColor: selectedStoryIndex >= lastUploadedStoryIndex
            ? '#D3D3D3 !important'
            : 'rgba(255, 255, 255, 0.7)',
          '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.7)',
          },
        }}
      >
        <ArrowForwardIosIcon />
      </IconButton>
    </Dialog>
  );
};