import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { TextField, Grid, Typography, Box } from "@mui/material";
import { useFormStyles } from "../../styles/text-field";
import { FormValues } from "../vendor-form/types";

export const AdditionalForm: React.FC = () => {
  const { control, formState: { errors } } = useFormContext<FormValues>(); 
  const validateNumberOfStores = (value: string) => {
    return /^\d+$/.test(value) || "Number of Stores must be a number";
  };

  return (
    <Box>
      <Box sx={{ mb: 6 }}>
        <Typography sx={{ fontSize: '18px', fontWeight: '600' }}>Additional Information</Typography>
        <Typography color="#939393" fontSize={14}>
          Please provide some additional information for dashboard customization
        </Typography>
      </Box>

      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Typography sx={{fontSize: '14px'}}>Number of Stores*</Typography>
          <Controller
            control={control}
            name="numberOfStores"
            rules={{ 
              required: "Required", 
              validate: validateNumberOfStores
            }} 
            render={({ field }) => (
              <div style={{ position: 'relative' }}>
                <TextField
                  id="number-of-stores"
                  variant="outlined"
                  fullWidth
                  {...field}
                  error={!!errors.numberOfStores}
                  sx={{ ...useFormStyles }}
                  inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                />
                {errors.numberOfStores && (
                  <Typography sx={{ ...useFormStyles["& .errorText"] }}>
                    {errors.numberOfStores.message}
                  </Typography>
                )}
              </div>
            )}
          />
        </Grid>
      </Grid>
    </Box>
  );
};