
export const otpTextFieldStyle = {
    border: '1px solid #281839',
    borderRadius: '8px',
    fontSize: '18px',
    margin: '0 8px',
    width: 'calc(100% / 6 - 10px)',
    height: '40px'
  }

  export const useFormStyles = {
    borderRadius: "4px",
    marginTop: '4px',
    position: 'relative',  // Ensure relative positioning for the container
    "& .MuiOutlinedInput-root": {
      borderRadius: "4px",
      padding: "8px",
      height: "40px",
      "& fieldset": {
        borderRadius: "4px",
        border: "1px solid",
        borderColor: "#939393",
      },
      input: {
        '&: -webkit-autofill': {
          '-webkit-box-shadow': '0 0 0 100px #fff inset',
          '-webkit-text-fill-color': 'black'
        }
      }
    },
    "& .errorText": {
      position: 'absolute',
      bottom: '-16px',
      right: '0',
      color: 'rgb(211, 47, 47)',
      fontSize: '0.75rem',
    }
  };
  