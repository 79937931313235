import React from "react";
import { Container, Grid, Paper, Typography, Box, useMediaQuery, useTheme } from "@mui/material";

interface Feature {
  title: string;
  description: string;
  icon: React.ReactNode;
}

interface KeyFeaturesProps {
  features: Feature[];
}

export const KeyFeatures: React.FC<KeyFeaturesProps> = ({ features }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Container maxWidth="lg" sx={{ p: 8 }}>
      <Typography variant="h4" sx={{ textAlign: "center", fontWeight: 600, mb: 6 }}>
        Why Shop with ShopPayGo?
      </Typography>
      <Grid container spacing={6}>
        {features.map((feature, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Paper
              elevation={3}
              sx={{
                p: 4,
                textAlign: "center",
                borderRadius: 3,
                background: "#e3f2fd",
                position: "relative",
                transition: "transform 0.3s, boxShadow 0.3s",
                "&:hover": {
                  transform: "scale(1.05)",
                  boxShadow: "0px 12px 24px rgba(0, 0, 0, 0.1)",
                },
                overflow: "hidden",
              }}
            >
              <Box
                sx={{
                  position: "absolute",
                  top: "-30px",
                  right: "-30px",
                  width: "100px",
                  height: "100px",
                  backgroundColor: "#81D4FA",
                  borderRadius: "50%",
                  zIndex: 0,
                  filter: "blur(10px)",
                }}
              />
              <Box sx={{ position: "relative", zIndex: 1 }}>
                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mb: 2 }}>
                  {feature.icon}
                </Box>
                <Typography variant="h6" sx={{ fontWeight: 600 }}>
                  {feature.title}
                </Typography>
                <Typography variant="body2" sx={{ mt: 1, color: "#5a5a5a", textAlign: "center" }}>
                  {feature.description}
                </Typography>
              </Box>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

