import React from "react";
import { BasicForm} from "./BasicForm";
import { ContactForm } from "./ContactForm";
import { AdditionalForm } from "./AdditionalForm";



interface FormContentProps {
  step: number;
}

const FormContent: React.FC<FormContentProps> = ({ step }) => {
  switch (step) {
    case 0:
      return <BasicForm />;
    case 1:
      return <ContactForm />;
    case 2:
      return <AdditionalForm />;
    default:
      return "Unknown step";
  }
};

export default FormContent;
