import React, { useState, useCallback } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Stack,
  CircularProgress,
  Grow,
} from "@mui/material";
import { useDropzone } from "react-dropzone";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import customTheme from "../../theme/customTheme";
import { useAuth } from "../../../authprovider";

const dialogHeaderStyles = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '16px 24px',
  backgroundColor: customTheme.palette.primary.main,
  color: customTheme.palette.primary.contrastText,
  borderTopLeftRadius: '20px',
  borderTopRightRadius: '20px',
};

const dialogActionsStyles = {
  justifyContent: 'space-between',
  padding: '16px',
};

const dialogCancelActionStyles = {
  backgroundColor: customTheme.palette.error.main,
  color: customTheme.palette.error.contrastText,
  '&:hover': {
    backgroundColor: customTheme.palette.error.dark,
  },
};

const dialogSubmitActionStyles = {
  backgroundColor: customTheme.palette.success.main,
  color: customTheme.palette.success.contrastText,
  '&:hover': {
    backgroundColor: customTheme.palette.success.dark,
  },
};

const dialogContentStyles = {
  padding: '24px',
  textAlign: 'center',
};

export const FileUpload: React.FC<{
  open: boolean;
  handleClose: () => void;
  handleOk: (acceptedFiles: File[]) => void;
  tabValue: number;
}> = ({ open, handleClose, handleOk, tabValue }) => {
  const { currentUser } = useAuth();
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [loading, setLoading] = useState(false);
  const [uploadError, setUploadError] = useState<string | null>(null);

  const validateFile = async (file: File): Promise<string | null> => {
    const isImage = file.type.startsWith("image/");
    const isVideo = file.type.startsWith("video/");
    const fileSizeMB = file.size / (1024 * 1024);

    const getResolution = (file: File): Promise<{ width: number; height: number }> => {
      return new Promise((resolve) => {
        if (isImage) {
          const img = new Image();
          const objectUrl = URL.createObjectURL(file);
          img.src = objectUrl;
          img.onload = () => {
            resolve({ width: img.width, height: img.height });
            URL.revokeObjectURL(objectUrl);
          };
        } else if (isVideo) {
          const video = document.createElement('video');
          const objectUrl = URL.createObjectURL(file);
          video.src = objectUrl;
          video.onloadedmetadata = () => {
            resolve({ width: video.videoWidth, height: video.videoHeight });
            URL.revokeObjectURL(objectUrl);
          };
        }
      });
    };

    const resolution = await getResolution(file);

    if (tabValue === 0) { 
      if (!isImage && !isVideo) return "Only images and videos are allowed for stories.";
      if ((isImage && fileSizeMB > 2) || (isVideo && fileSizeMB > 10)) return "File size exceeds the allowed limit for stories.";
      if (resolution.width < 1080 || resolution.width > 1620 || resolution.height < 1920 || resolution.height > 2880) return "Resolution exceeds the allowed limit for stories.";
    } else if (tabValue === 1) {
      if (!isImage) return "Only images are allowed for banners.";
      if (fileSizeMB > 1) return "File size exceeds the allowed limit for banners.";
      if (resolution.width < 1200 || resolution.width > 1800 || resolution.height < 600 || resolution.height > 900) return "Resolution exceeds the allowed limit for banners.";
    } else if (tabValue === 2) { 
      if (!isImage) return "Only images are allowed for tiles.";
      if (fileSizeMB > 0.5) return "File size exceeds the allowed limit for tiles.";
      if (resolution.width < 540 || resolution.width > 810 || resolution.height < 960 || resolution.height > 1440) return "Resolution exceeds the allowed limit for tiles.";
    }
    return null;
  };

  const onDrop = useCallback(async (acceptedFiles: File[]) => {
    if (acceptedFiles.length > 3) {
      setUploadError("You can only upload up to 3 files at a time.");
      console.log('Error: You can only upload up to 3 files at a time.');
      return;
    }

    const validFiles: File[] = [];
    for (const file of acceptedFiles) {
      const error = await validateFile(file);
      if (error) {
        setUploadError(error);
        return;
      }
      validFiles.push(file);
    }
    setSelectedFiles((prevFiles) => [...prevFiles, ...validFiles].slice(0, 3));
    setUploadError(null);
  }, [tabValue]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, multiple: true });

  const handleOkClick = async () => {
    if (selectedFiles.length > 0) {
      setLoading(true);
      await handleOk(selectedFiles);
      setLoading(false);
      handleClose();
    }
    setSelectedFiles([]);
  };

  const handleCancelClick = () => {
    setSelectedFiles([]);
    setUploadError(null); 
    handleClose();
  };

  const getRestrictionsMessage = () => {
    if (tabValue === 0) {
      return "For stories, max size for image is 2MB, video is 10MB, and resolution should be 1080x1920 to 1620x2880.";
    } else if (tabValue === 1) {
      return "For banners, only images are allowed with max size of 1MB, and resolution should be 1200x600 to 1800x900.";
    } else if (tabValue === 2) {
      return "For tiles, only images are allowed with max size of 500KB, and resolution should be 540x960 to 810x1440.";
    }
    return "";
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleCancelClick}
        PaperProps={{
          style: {
            borderRadius: 20,
            background: customTheme.palette.background.paper,
            width: "400px",
            maxWidth: "90%",
          },
        }}
        TransitionComponent={Grow}
        transitionDuration={500}
      >
        <DialogTitle sx={dialogHeaderStyles}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
            <span>Upload File</span>
            <CloudUploadIcon sx={{ fontSize: "32px" }} />
          </Stack>
        </DialogTitle>
        <Divider sx={{ borderBottomWidth: '2px', color: 'primary.main' }} />
        <DialogContent sx={dialogContentStyles}>
          <Typography variant="body2" color="textSecondary" sx={{ marginBottom: 2 }}>
            {getRestrictionsMessage()}
          </Typography>
          <Box
            {...getRootProps()}
            sx={{
              border: "2px dashed #3f51b5",
              padding: "20px",
              textAlign: "center",
              borderRadius: "8px",
              backgroundColor: isDragActive ? "#e3f2fd" : "#f5f5f5",
              transition: "background-color 0.3s ease",
              cursor: "pointer"
            }}
          >
            <input {...getInputProps()} />
            <CloudUploadIcon sx={{ fontSize: 48, color: "#3f51b5", marginBottom: 2 }} />
            {isDragActive ? (
              <Typography variant="body1" sx={{ color: "#3f51b5" }}>Drop the files here ...</Typography>
            ) : (
              <Typography variant="body1">Drag 'n' drop files here, or click to select files</Typography>
            )}
          </Box>
          {uploadError && (
            <Typography variant="body2" color="error" sx={{ marginTop: 2 }}>
              {uploadError}
            </Typography>
          )}
          {selectedFiles.length > 0 && selectedFiles.length < 4 && (
            <Box mt={2}>
              <Typography variant="h6">Selected files:</Typography>
              <List>
                {selectedFiles.map((file, index) => (
                  <ListItem key={index}>
                    <ListItemIcon>
                      {file.type.startsWith('image/') ? (
                        <img
                          src={URL.createObjectURL(file)}
                          alt={file.name}
                          style={{ width: 50, height: 50, objectFit: 'cover' }}
                        />
                      ) : (
                        file.type.startsWith('video/') ? (
                          <video
                            src={URL.createObjectURL(file)}
                            style={{ width: 50, height: 50, objectFit: 'cover' }}
                            controls
                          />
                        ) : (
                          <InsertDriveFileIcon />
                        )
                      )}
                    </ListItemIcon>
                    <ListItemText
                      primary={file.name}
                      secondary={`${(file.size / 1024).toFixed(2)} KB`}
                    />
                  </ListItem>
                ))}
              </List>
              <Divider sx={{ marginTop: 2 }} />
            </Box>
          )}
        </DialogContent>
        <DialogActions sx={dialogActionsStyles}>
          <Button onClick={handleCancelClick} size="medium" sx={dialogCancelActionStyles}>
            Cancel
          </Button>
          <Button
            onClick={handleOkClick}
            size="medium"
            sx={dialogSubmitActionStyles}
            disabled={loading}
          >
            {loading ? (
              <CircularProgress size={24} sx={{ color: 'white' }} />
            ) : (
              'Ok'
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};