import React from "react";
import { BasicForm} from "./BasicForm";
// import { APIForm } from "./APIForm";
import { ContactForm } from "./ContactForm";
import { AccountForm } from "./AccountForm";
import { AdditionalForm } from "./AdditionalForm";


interface FormContentProps {
  step: number;
}

const FormContent: React.FC<FormContentProps> = ({ step }) => {
  switch (step) {
    case 0:
      return <BasicForm />;
    case 1:
      return <ContactForm />;
    case 2:
      return <AdditionalForm />;
    case 3:
      return <AccountForm />;
    // case 4:
    //   return <APIForm />;  
    default:
      return "Unknown step";
  }
};

export default FormContent;
