import React, { useState } from 'react';
import { Paper, Typography, FormControl, Select, MenuItem, Box } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import { LineChart, Line, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { padding } from '@mui/system';
import { color } from 'framer-motion';

interface LineGraphProps {
  monthlyData: any[];
  yearlyData: any[];
  heading: string;
  dataKey: string;
}

export const LineGraph: React.FC<LineGraphProps> = ({ monthlyData, yearlyData, heading, dataKey }) => {
  const [timeFrame, setTimeFrame] = useState('month');

  const handleTimeFrameChange = (event: SelectChangeEvent<string>) => {
    setTimeFrame(event.target.value);
  };

  const data = timeFrame === 'month' ? monthlyData : yearlyData;

  return (
    <Paper elevation={3} style={{ padding: '16px', marginTop: '8px', height: '100%', borderRadius: '10px' }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom="16px">
        <Typography sx={{ fontSize: '18px', fontWeight: 'bolder' }}>{heading}</Typography>
        <FormControl variant="outlined">
          <Select
            value={timeFrame}
            onChange={handleTimeFrameChange}
            style={{ height: '35px', borderRadius: '10px' }}
          >
            <MenuItem value="month">Monthly</MenuItem>
            <MenuItem value="year">Yearly</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <ResponsiveContainer width="100%" height={400}>
        <LineChart data={data} margin={{ top: 30, right: 30, left: 20, bottom: 0 }}>
          <defs>
            <linearGradient id="colorGraph" x1="0" y1="0" x2="0" y2="1">
              <stop offset="0%" stopColor="#37CE1F" stopOpacity={1} />
              <stop offset="100%" stopColor="#1A48EA" stopOpacity={1} />
            </linearGradient>
          </defs>
          <XAxis dataKey={timeFrame === 'month' ? 'month' : 'year'} axisLine={false} tickLine={false} offset={20} padding={{ left: 30 }} />
          <YAxis 
            axisLine={false} 
            tickLine={false} 
            offset={30}
            padding={{ bottom: 30 }}
            label={{ 
              value: 'Quantity', 
              angle: -90, 
              dx: -1,
              position: 'insideLeft',
              style: { textAnchor: 'middle' }
            }} 
          />
          <Tooltip />
          <Legend />
          <Line type="monotone" dataKey={dataKey} stroke="url(#colorGraph)" strokeWidth={4} />
        </LineChart>
      </ResponsiveContainer>
    </Paper>
  );
};
