import React, { ReactNode } from "react";
import { Box } from "@mui/material";
import { SideNav } from "../sidenav";
import { Header } from "../header";
import { borderRadius } from "@mui/system";

interface DefaultLayoutProps {
  children: ReactNode;
}

export const OuterLayout: React.FC<DefaultLayoutProps> = ({
  children,
}) => {
  const innerBoxStyles = {
    bgcolor: "transparent",
    height: "100%",
    overflowY: "scroll",
    scrollbarWidth: "none",
    borderRadius: "10px",
    msOverflowStyle: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  };

  return (
    <Box
      sx={{
        display: "flex",
        minHeight: `calc(100vh - 64px)`,
        backgroundImage: `url(${require("../../Images/formback.jpg")})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        flexDirection: 'column',
      }}
    >
      <Header />
      <Box flexDirection={'row'} display={'flex'}>
      <Box sx={{ display: { xs: "none", md: "block" } }}>
        <SideNav />
      </Box>
      <Box
        component="main"
        sx={{ flexGrow: 1, display: "flex", flexDirection: "column",
        }}
      >
        <Box
          sx={{
            flexGrow: 1,
            overflowY: "auto",
            p: 1,
            height: `calc(100vh - 64px)`,
          }}
        >
          <Box sx={innerBoxStyles}>{children}</Box>
        </Box>
      </Box>
      </Box>
    </Box>
  );
};
