import React from "react";
import { Box, Avatar } from "@mui/material";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const isVideo = (avatar: string): boolean => {
  if (typeof avatar !== "string") return false;
  const lowerCaseURL = avatar.toLowerCase();
  const [urlPath] = lowerCaseURL.split('?');
  return (
    urlPath.endsWith(".mp4") ||
    urlPath.endsWith(".mkv") ||
    urlPath.endsWith(".avi") ||
    urlPath.endsWith(".mov") ||
    lowerCaseURL.includes("video")
  );
};

export const ContentFeed: React.FC<{
  combinedStories: any[];
  combinedBanners: any[];
  combinedTiles: any[];
  handleOpen: (index: number) => void;
}> = ({ combinedStories, combinedBanners, combinedTiles, handleOpen }) => {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  return (
    <Box
      className="no-scrollbar"
      sx={{
        overflowY: "auto",
        overflowX: "hidden",

        height: "calc(100% - 135px)",
      }}
    >
      {/* Stories */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
          p: 1,
          overflow: "scroll",
          overflowX: "hidden", 

        }}
      >
        {combinedStories.map((story, index) => (
          <Box
            key={index}
            sx={{
              display: "inline-block",
              background: "linear-gradient(45deg, red, orange)",
              borderRadius: "50%",
              mr: 1,
              padding: "1px",
            }}
          >
            <Avatar
              src={isVideo(story.avatar) ? require("../../../Images/videoicon.jpg") : story.avatar || undefined}
              alt={`Story Avatar ${index + 1}`}
              sx={{
                width: 48,
                height: 48,
                borderRadius: "50%",
                cursor: "pointer",
                background: story.avatar ? 'transparent' : story.background || '#ECE6F4',
              }}
              onClick={() => handleOpen(index)}
            />
          </Box>
        ))}
      </Box>

      {/* Banners */}
      <Box sx={{ paddingX: 1 }}>
        <Slider {...settings}>
          {combinedBanners.map((banner, index) => (
            <Box key={index} sx={{
              width: "100%",
              height: "150px",
              borderRadius: "10px",
              background: banner.background || "#ECE6F4",
            }}>
              {banner.avatar ? (
                <img
                  src={banner.avatar}
                  alt={`carousel-${index + 1}`}
                  style={{
                    width: "100%",
                    height: "150px",
                    borderRadius: "10px",
                  }}
                />
              ) : null}
            </Box>
          ))}
        </Slider>
      </Box>

      {/* Tiles */}
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(3, 1fr)",
          paddingX: "8px",
          gap: "5px",
        }}
      >
        {combinedTiles.map((tile, index) => (
          <Box key={index} sx={{
            width: "100%",
            height: "130px",
            borderRadius: "10px",
            background: tile.background || "#ECE6F4",
          }}>
            {tile.avatar ? (
              <img
                src={tile.avatar}
                style={{
                  width: "100%",
                  height: "100%",
                  borderRadius: "10px",
                }}
              />
            ) : null}
          </Box>
        ))}
      </Box>
    </Box>
  );
};

