import { ApolloClient, InMemoryCache, createHttpLink, ApolloProvider, ApolloLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';

const API_URL = process.env.REACT_APP_API_URL ||  'https://shoppaygo-systems.el.r.appspot.com/graphql';

if (!API_URL) {
  console.error('API_URL is missing.');
}

const httpLink = createHttpLink({
  uri: API_URL,
});

const authLink = setContext(async (_, { headers }) => {
  const token = await localStorage.getItem('accessToken');
  
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
      appName: 'spg-dashboard',
    }
  };
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    console.log('*** ERROR GraphQL START ***');
    graphQLErrors.forEach(({ message, locations, path }) => {
      console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
    });
    console.log('*** ERROR GraphQL END ***');
  }
  if (networkError) {
    console.log('*** ERROR GraphQL (networkError) START ***');
    console.log(`[Network error]: ${networkError}`);
    console.log('*** ERROR GraphQL (networkError) END ***');
  }
});

const client = new ApolloClient({
  link: ApolloLink.from([errorLink, authLink, httpLink]),
  cache: new InMemoryCache(),
});

export default client;
