import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import SmallButton from "../../styles/SmallButton";
import { useAuth } from "../../authprovider";

const useStyles = makeStyles({
  box: {
    backgroundColor: "white",
    color: "black",
    padding: "16px",
    borderRadius: "8px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  },
  gridItem: {
    textAlign: "center",
  },
  text: {
    fontSize: "14px",
    color: "#AAAAAA",
  },
  chipContainer: {
    display: 'flex',
    gap: '16px',
    marginLeft: 'auto',
  },
  heading: {
    display: 'flex',
    justifyContent: 'space-between',
  },
});

export const Subscription: React.FC = () => {
  const classes = useStyles();
  const { currentUser } = useAuth();

  const getStartDate = () => {
    const startTimeSTamp = currentUser?.profile?.createdDate;
    return new Date(Number(startTimeSTamp)).toDateString();
  }

  const handleChipClick = () => {
    alert("Feature coming soon");
  };

  const subscriptionData = [
    { value: "Free", label: "Plan", color: "#33AABA"},
    { value: getStartDate(), label: "Start Date", color: "#3EAD56"},
    { value: "--", label: "End Date", color: "#E30815"},
    { value: "₹0", label: "Cost", color: "#BB50B0"},
  ];

  return (
    <Box className={classes.box}>
      <Box className={classes.heading}>
        <Typography sx={{ mb: 1, fontSize: "18px", fontWeight: "bolder" }}>
          Subscription
        </Typography>
        {/* <Box className={classes.chipContainer}>
          <SmallButton
            onClick={handleChipClick}
            style={{ backgroundColor: "#37C3E2" }}
          >
            Extend
          </SmallButton>
          <SmallButton
            onClick={handleChipClick}
            style={{ backgroundColor: "#37CE1F" }}
          >
            Upgrade
          </SmallButton>
        </Box> */}
      </Box>
      <Grid container alignItems="center" sx={{ mb: 1, gap: 2 }}>
        {subscriptionData.map((item, index) => (
             <Grid item xs sx={{marginY:'24px'}} key={index}>
             <Box className={classes.gridItem}>
               <Typography
                 style={{
                   color: item.color,
                   fontWeight: "700",
                   fontSize: "22px",
                 }}
               >
                 {item.value}
               </Typography>
               <Typography className={classes.text}>{item.label}</Typography>
             </Box>
           </Grid>
        ))}
      </Grid>
    </Box>
  );
};